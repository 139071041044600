import styled from 'styled-components';

// Main container for the listed properties page
export const ListedPropertiesContainer = styled.div`
  padding: 2rem;
  margin: 0rem auto;
  padding-bottom: 4rem;
`;

// Section for the headings with centered text
export const ListedPropertiesHeading = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: left;
`;

// Properties grid container for cards
export const PropertiesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

// Individual property card
export const PropertyCard = styled.div`
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  overflow: hidden; /* This ensures the carousel sticks to the edges */
  padding: 0; /* Remove the default padding to avoid gaps */
  margin: 0; /* Adjust margins if needed */
  overflow: hidden;
  position: relative;

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
`;

// Property details (like price, beds, baths, etc.)
export const PropertyDetails = styled.div`
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;

  .property-price {
    font-size: 1.2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
  }

  .property-info {
    display: flex;
    justify-content: space-between; /* Ensures inline display */
    align-items: center;
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary};
  }

  p {
    margin: 0.5rem 0;
    color: ${({ theme }) => theme.colors.primary};
    line-height: 1.4;
  }
`;

// Agent details section (agent name, rating, etc.)
export const AgentDetails = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;

  .agent-photo {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 0.75rem;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    .verified-icon {
      position: absolute;
      bottom: 0;
      left: 1.8rem;
      background-color: white;
      color: black;
      border-radius: 50%;
      padding: 2px;
      width: 20px;
      height: 20px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    }
  }

  .agent-info {
    display: flex;
    align-items: center;

    span {
      margin-right: 0.75rem;
      font-size: 0.9rem;
      color: #666;
    }

    .agent-name {
      margin-left: 0.5rem; 
      font-weight: 600;
      color: ${({ theme }) => theme.colors.text};
      font-size: 1rem;
    }

    .agent-rating {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.text};
      font-size: 1rem;
    }

    .review-count {
      margin-left: -0.5rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.text};
    }

    .agent-sales {
      color: #333;
    }

    .divider {
      color: #ddd;
      margin-right: 0.75rem;
    }
  }
`;

// Container for the circular buttons BELOW the card
export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  position: absolute;
  top: calc(100% + 20px); /* Buttons appear below the card with a gap */
  left: 50%;
  transform: translateX(-50%);
`;

// General button styles
export const CircularButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 0.5px solid ${({ theme }) => theme.colors.primaryDark};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.primaryDark}; /* Icon color to be black */
  background-color: white; /* Default background color is white */
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:focus {
    outline: none;
  }

  &:hover {
    color: black; /* Ensure the icon stays black on hover */
  }
`;

// Edit button (hover effect: black)
export const EditButton = styled(CircularButton)`
  &:hover {
    background-color: black; /* Black background on hover */
    color: white; /* White icon on hover */
  }
`;

// Sold button (hover effect: green)
export const SoldButton = styled(CircularButton)`
  &:hover {
    background-color: #28a745; /* Green background on hover */
    color: white; /* White icon on hover */
  }
`;

// Delete button (hover effect: red)
export const DeleteButton = styled(CircularButton)`
  &:hover {
    background-color: #dc3545; /* Red background on hover */
    color: white; /* White icon on hover */
  }
`;

// Modal Styling
export const StyledModalForm = styled.div`
  padding: 1.5rem;
  background-color: #fff;

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: ${({ theme }) => theme.colors.primaryText};
  }

  label {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.colors.primaryText};
  }

  input, select, textarea {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);

    &:focus {
      border-color: ${({ theme }) => theme.colors.primary};
      outline: none;
    }
  }

  textarea {
    height: 100px;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }
`;

export const SaveButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkPrimary};
  }
`;

// Modal Styling (without unnecessary background)
export const SaleModalContainer = styled.div`
  padding: 1.5rem;
  border-radius: 8px;
`;

// Title for the modal
export const SaleModalTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

// Subtitle for the QR code and link section
export const SaleModalSubtitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
`;

export const SaleModalLabel = styled.label`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};
`;

// Input for the modal
export const SaleModalInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 1rem;
`;

// Select dropdown for modal
export const SaleModalSelect = styled.select`
  width: 100%;
  padding: 0.75rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 1rem;
  font-size: 1rem;
`;

// Row to contain QR code and link
export const SaleModalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

// Columns for the QR code and signup link
export const SaleModalColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  &:first-child {
    margin-right: 1.5rem;
  }
`;

// Input with a copy button
export const SaleModalInputWithButton = styled.div`
  display: flex;
  width: 100%;
`;

export const SaleModalCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  input[type='checkbox'] {
    margin-right: 0.5rem;
  }
`;

// Button group for submit and cancel buttons
export const SaleModalButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
`;

// Confirm button
export const ConfirmButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

// Cancel button
export const CancelButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primaryDark};
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #999;
  }
`;

// Bare icon button for copying the URL (no background)
export const IconButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;

  svg {
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.primaryLight};
    transition: color 0.3s ease;
  }

  &:hover svg {
    color: ${({ theme }) => theme.colors.primaryDark};
  }

  &:focus {
    outline: none;
  }
`;

