import React from 'react';
import {
  FiltersModal,
  FilterForm,
  ModalFilterGroup,
  ModalFilterSelect,
  ModalFilterInput,
  ModalFilterLabel,
  ModalFilterFields,
} from '../../styles/PropertyResultsStyles';
import { Country, City, HomeType, HomeView } from '../../services/propertyService';

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  filters: any;
  handleFilterChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  countries: Country[];
  cities: City[];
  homeTypes: HomeType[];
  homeViews: HomeView[];
}

const FilterModal: React.FC<FilterModalProps> = ({
  isOpen,
  onClose,
  filters,
  handleFilterChange,
  countries,
  cities,
  homeTypes,
  homeViews,
}) => {
  return (
    <FiltersModal isOpen={isOpen} onClick={onClose}> {/* Attach onClose to the outer FiltersModal */}
      <div className="modal-content" onClick={(e) => e.stopPropagation()}> {/* Prevent click propagation inside modal */}
        {/* Close button */}
        <button className="close-button" onClick={onClose}>×</button>

        <h2>Filters</h2>
        <FilterForm>
          {/* Country Filter */}
          <ModalFilterGroup>
            <ModalFilterLabel htmlFor="country">Country</ModalFilterLabel>
            <ModalFilterSelect name="country" value={filters.country} onChange={handleFilterChange}>
              <option value="">Select a Country</option>
              {countries.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </ModalFilterSelect>
          </ModalFilterGroup>

          {/* City Filter */}
          <ModalFilterGroup>
            <ModalFilterLabel htmlFor="city">City</ModalFilterLabel>
            <ModalFilterSelect name="city" value={filters.city} onChange={handleFilterChange}>
              <option value="">Select a City</option>
              {cities.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </ModalFilterSelect>
          </ModalFilterGroup>

          {/* Price Filter with min-max fields */}
          <ModalFilterGroup>
            <ModalFilterLabel htmlFor="min_price">Price</ModalFilterLabel>
            <ModalFilterFields>
              <ModalFilterInput
                type="number"
                name="min_price"
                value={filters.min_price}
                onChange={handleFilterChange}
                placeholder="Min Price"
              />
              <ModalFilterInput
                type="number"
                name="max_price"
                value={filters.max_price}
                onChange={handleFilterChange}
                placeholder="Max Price"
              />
            </ModalFilterFields>
          </ModalFilterGroup>

          {/* Bedrooms Filter with min-max fields */}
          <ModalFilterGroup>
            <ModalFilterLabel htmlFor="min_bedrooms">Bedrooms</ModalFilterLabel>
            <ModalFilterFields>
              <ModalFilterInput
                type="number"
                name="min_bedrooms"
                value={filters.min_bedrooms}
                onChange={handleFilterChange}
                placeholder="Min Bedrooms"
              />
              <ModalFilterInput
                type="number"
                name="max_bedrooms"
                value={filters.max_bedrooms}
                onChange={handleFilterChange}
                placeholder="Max Bedrooms"
              />
            </ModalFilterFields>
          </ModalFilterGroup>

          {/* Bathrooms Filter with min-max fields */}
          <ModalFilterGroup>
            <ModalFilterLabel htmlFor="min_bathrooms">Bathrooms</ModalFilterLabel>
            <ModalFilterFields>
              <ModalFilterInput
                type="number"
                name="min_bathrooms"
                value={filters.min_bathrooms}
                onChange={handleFilterChange}
                placeholder="Min Bathrooms"
              />
              <ModalFilterInput
                type="number"
                name="max_bathrooms"
                value={filters.max_bathrooms}
                onChange={handleFilterChange}
                placeholder="Max Bathrooms"
              />
            </ModalFilterFields>
          </ModalFilterGroup>

          {/* Home Type Filter */}
          <ModalFilterGroup>
            <ModalFilterLabel htmlFor="home_types">Home Type</ModalFilterLabel>
            <ModalFilterSelect name="home_types" value={filters.home_types} onChange={handleFilterChange}>
              <option value="">Select a Home Type</option>
              {homeTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </ModalFilterSelect>
          </ModalFilterGroup>

          {/* Home View Filter */}
          <ModalFilterGroup>
            <ModalFilterLabel htmlFor="home_views">Home View</ModalFilterLabel>
            <ModalFilterSelect name="home_views" value={filters.home_views} onChange={handleFilterChange}>
              <option value="">Select a Home View</option>
              {homeViews.map((view) => (
                <option key={view.id} value={view.id}>
                  {view.name}
                </option>
              ))}
            </ModalFilterSelect>
          </ModalFilterGroup>
        </FilterForm>
      </div>
    </FiltersModal>
  );
};

export default FilterModal;
