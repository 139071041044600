import React, { useState, useEffect } from 'react';
import { BlogPost } from '../../services/blogService';
import BlogPostCard from './BlogPostCard';
import { CarouselWrapper, SlideContainer, BlogPostWrapper, BlogSection, BlogHeading, ArrowButton, SeeAllLink } from '../../styles/BlogCarouselStyles';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

interface BlogCarouselProps {
  posts: BlogPost[];
}

const BlogCarousel: React.FC<BlogCarouselProps> = ({ posts }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visiblePosts, setVisiblePosts] = useState(4); // Default for large screens

  // Adjust number of visible posts based on screen size
  const handleResize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 576) {
      setVisiblePosts(1); // Extra small screens
    } else if (screenWidth <= 768) {
      setVisiblePosts(2); // Small screens
    } else if (screenWidth <= 992) {
      setVisiblePosts(2); // Medium screens
    } else {
      setVisiblePosts(3); // Large screens and above
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check on mount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNext = () => {
    if (currentIndex + visiblePosts < posts.length) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <BlogSection>
      <BlogHeading>
        What you need to read (<SeeAllLink to="/blogs">See All</SeeAllLink>)
      </BlogHeading>
      <CarouselWrapper>
        <ArrowButton className="left" onClick={handlePrev} disabled={currentIndex === 0}>
          <FaArrowLeft />
        </ArrowButton>

        <SlideContainer currentIndex={currentIndex} visiblePosts={visiblePosts}>
          {posts.map((post) => (
            <BlogPostWrapper visiblePosts={visiblePosts} key={post.id}>
              <BlogPostCard post={post} isHomePage />
            </BlogPostWrapper>
          ))}
        </SlideContainer>

        <ArrowButton
          className="right"
          onClick={handleNext}
          disabled={currentIndex + visiblePosts >= posts.length}
        >
          <FaArrowRight />
        </ArrowButton>
      </CarouselWrapper>
    </BlogSection>
  );
};

export default BlogCarousel;
