import React from 'react';
import {
  FooterContainer,
  FooterContent,
  FooterLink,
  ContactDetails,
  SocialMediaLinks,
  SocialIcon,
} from '../../styles/FooterStyles';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterContent>

        {/* Links Section */}
        <ContactDetails>
          <p>
            Email: <a href="mailto:moein.kazemimk2016@gmail.com">moein.kazemimk2016@gmail.com</a>
          </p>
          <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
        </ContactDetails>

        {/* Social Media Icons Section */}
        <SocialMediaLinks>
          <SocialIcon href="https://www.facebook.com">
            <FaFacebook />
          </SocialIcon>
          <SocialIcon href="https://www.twitter.com">
            <FaTwitter />
          </SocialIcon>
          <SocialIcon href="https://www.instagram.com">
            <FaInstagram />
          </SocialIcon>
        </SocialMediaLinks>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
