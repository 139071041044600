import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  color: ${({ theme }) => theme.colors.background};
  text-align: center;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primaryLight};
  text-decoration: none;
  font-size: ${({ theme }) => theme.typography.small.fontSize};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
  }
`;

export const ContactDetails = styled.div`
  p {
    font-size: ${({ theme }) => theme.typography.body.fontSize};
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colors.primaryLight};
      text-decoration: underline;
    }
  }
`;

export const SocialMediaLinks = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.m2}; /* Space between icons */
  justify-content: center;
`;

export const SocialIcon = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.typography.h3.fontSize};
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryLight};
  }
`;
