// src/components/auth/GoogleLoginInitiate.tsx
import React from 'react';
import axios from 'axios';
import CustomGoogleButton from '../common/CustomGoogleButton'; // Import the custom Google button

const GoogleLoginInitiate: React.FC = () => {

  // Function to handle Google login initiation when the button is clicked
  const handleGoogleLogin = async () => {
    try {
      // Request Google authorization URL from the backend
      const response = await axios.get('https://backend.timeaz.com/api/users/google/');
      
      if (response.status === 200 && response.data.url) {
        // Redirect the user to the Google authorization URL
        window.location.href = response.data.url;
      } else {
        console.error('Failed to get Google authorization URL');
      }
    } catch (error) {
      console.error('Error initiating Google login:', error);
    }
  };

  return (
    <CustomGoogleButton onClick={handleGoogleLogin} /> 
  );
};

export default GoogleLoginInitiate;
