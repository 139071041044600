import styled from 'styled-components';

export const ReviewContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

export const PriceSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 2px solid #eaeaea;
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: left;
    margin: 0; /* Remove any extra margins */

    ${({ theme }) => theme.media.medium`
      font-size: 2rem; /* Adjust font size on medium screens */
    `}

    ${({ theme }) => theme.media.small`
      font-size: 1.5rem; /* Further adjust font size on small screens */
    `}

    ${({ theme }) => theme.media.extraSmall`
      font-size: ${theme.typography.small.fontSize};
    `}
  }

  .left-section {
    display: flex;
    flex-direction: column; /* Stack price and project-location */
    gap: ${({ theme }) => theme.spacing.m1};

    ${({ theme }) => theme.media.extraSmall`
      gap: 0; /* Reduce gap on small screens */
    `}
  }

  .project-location {
    text-align: left;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.primaryDark}
    margin: 0;

    ${({ theme }) => theme.media.medium`
      font-size: 1rem; /* Adjust font size on medium screens */
    `}

    ${({ theme }) => theme.media.small`
      font-size: 1rem; /* Further adjust font size on small screens */
    `}

    ${({ theme }) => theme.media.extraSmall`
      font-size: ${theme.typography.small.fontSize};
    `}
  }

  .project-location h2 {
    font-weight: bold;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.primary}
    margin: 0;  

    ${({ theme }) => theme.media.medium`
      font-size: 1.2rem; /* Adjust font size on medium screens */
    `}

    ${({ theme }) => theme.media.small`
      font-size: 1rem; /* Further adjust font size on small screens */
    `}

    ${({ theme }) => theme.media.extraSmall`
      font-size: ${theme.typography.small.fontSize};
    `}
  }

  .details {
    display: flex;
    margin-top: 0.5rem;

    ${({ theme }) => theme.media.small`
      margin-top: 1.2rem; /* Further adjust font size on small screens */
    `}

    ${({ theme }) => theme.media.extraSmall`
      margin-top: 1.2rem; /* Further adjust font size on small screens */
    `}
  }

  .details span {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    gap: 0.5rem;
    margin: 0;

    ${({ theme }) => theme.media.medium`
      font-size: 1.2rem; /* Adjust font size on medium screens */
    `}

    ${({ theme }) => theme.media.small`
      font-size: 1rem; /* Further adjust font size on small screens */
    `}

    ${({ theme }) => theme.media.extraSmall`
      font-size:${theme.typography.small.fontSize};
    `}
  }

  .details span::after {
    content: '';
    display: inline-block;
    width: 2px;
    height: 1.5rem;
    background-color: ${({ theme }) => theme.colors.primaryLight};
    margin: 0 1rem; /* Add space between the divider lines */

    ${({ theme }) => theme.media.medium`
      margin: 0 0.5rem;
    `}

    ${({ theme }) => theme.media.small`
      margin: 0 0.5rem;
      height: 1.5rem;
    `}

    ${({ theme }) => theme.media.extraSmall`
      margin: 0 0.3rem;
      height: 1rem;
    `}
  }

  .details span:last-child::after {
    display: none; /* Remove the last line after the last detail */
  }

  ${({ theme }) => theme.media.extraSmall`
    flex-direction: column; /* Ensure column layout for extra-small screens */
    align-items: flex-start;
    gap: ${theme.spacing.m2};
  `}
`;

export const AboutSection = styled.div`
  display: grid;
  max-width: 700px;
  grid-template-columns: repeat(2, 1fr); /* Two equal columns */
  grid-gap: ${({ theme }) => theme.spacing.m2}; /* Use theme spacing */
  border-bottom: 2px solid ${({ theme }) => theme.colors.lineBorder}; /* Use theme color for border */
  margin-bottom: ${({ theme }) => theme.spacing.m4}; /* Use theme spacing for margin */
  padding-bottom: ${({ theme }) => theme.spacing.m4}; /* Use theme spacing for padding */

  .status {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align text to the top */
    font-size: ${({ theme }) => theme.typography.body.fontSize}; /* Default body font size */
  }

  div {
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.typography.body.fontSize}; /* Default text size from theme */
    color: ${({ theme }) => theme.colors.text}; /* Default text color from theme */

    svg {
      margin-right: ${({ theme }) => theme.spacing.p2}; /* Space between icon and text */
      font-size: ${({ theme }) => theme.typography.h3.fontSize}; /* Use heading 3 size for icon */
    }

    .status-name {
      font-size: ${({ theme }) => theme.typography.body.fontSize}; /* Default status name font size */
    }

    .status-date {
      font-size: ${({ theme }) => theme.typography.small.fontSize}; /* Smaller font size for date */
      color: ${({ theme }) => theme.colors.textSecondary}; /* Use secondary text color */
      margin-left: ${({ theme }) => theme.spacing.p1}; /* Add left margin to separate it from status name */
    }
  }

  /* Responsive styles for small and extra-small screens */
  ${({ theme }) => theme.media.small`
    grid-template-columns: 1fr; /* Single column layout for small screens */
    grid-gap: ${theme.spacing.m2}; /* Adjust gap for small screens */
    
    div {
      font-size: ${theme.typography.small.fontSize}; /* Smaller font size for small screens */
      
      svg {
        font-size: ${theme.typography.body.fontSize}; /* Adjust icon size for small screens */
      }

      .status-name {
        font-size: ${theme.typography.small.fontSize}; /* Set smaller font size for status-name */
      }

      .status-date {
        font-size: ${theme.typography.xSmall.fontSize}; /* Smaller font size for status date */
        margin-left: ${theme.spacing.p1}; /* Adjust margin for small screens */
      }
    }
  `}

  ${({ theme }) => theme.media.extraSmall`
    grid-template-columns: 1fr; /* Single column layout for extra-small screens */
    grid-gap: ${theme.spacing.m2}; /* Adjust gap for extra-small screens */
    
    div {
      font-size: ${theme.typography.xSmall.fontSize}; /* Extra small font size for extra-small screens */

      svg {
        font-size: ${theme.typography.small.fontSize}; /* Adjust icon size */
      }

      .status-name {
        font-size: ${theme.typography.xSmall.fontSize}; /* Extra small font size for status-name */
      }

      .status-date {
        font-size: ${theme.typography.xxSmall.fontSize}; /* Smallest font size for date */
        margin-left: ${theme.spacing.p1}; /* Adjust margin */
      }
    }
  `}
`;

export const AmenitiesSection = styled.div`
  max-width: 700px;
  text-align: left;
  border-bottom: 2px solid ${({ theme }) => theme.colors.lineBorder}; /* Use theme border color */
  margin-bottom: ${({ theme }) => theme.spacing.m4}; /* Use theme spacing for margin */
  padding-bottom: ${({ theme }) => theme.spacing.m4}; /* Use theme spacing for padding */
  
  h3 {
    font-size: ${({ theme }) => theme.typography.h2.fontSize}; /* Use h2 font size from theme */
    margin-bottom: ${({ theme }) => theme.spacing.m2}; /* Use theme spacing */
    color: ${({ theme }) => theme.colors.primary}; /* Use primary color from theme */
  }

  .amenities-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-template-rows: repeat(3, auto); /* Three rows initially */
    gap: ${({ theme }) => theme.spacing.m3}; /* Use theme spacing for gap */
    
    /* Expanded state for more amenities */
    &.expanded {
      grid-template-rows: none; /* Allow the grid to expand */
    }

    ${({ theme }) => theme.media.small`
      grid-template-columns: 1fr; /* Single column layout for small screens */
      gap: ${theme.spacing.m2}; /* Adjust gap for small screens */
    `}
  }

  .amenity-item {
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.typography.body.fontSize}; /* Default font size for body text */
    color: ${({ theme }) => theme.colors.text}; /* Default text color from theme */

    svg {
      margin-right: ${({ theme }) => theme.spacing.p2}; /* Space between icon and text */
      font-size: ${({ theme }) => theme.typography.h3.fontSize}; /* Use h3 size for icons */

      ${({ theme }) => theme.media.small`
        font-size: ${theme.typography.body.fontSize}; /* Use body size for icons on small screens */
      `}

      ${({ theme }) => theme.media.extraSmall`
        font-size: ${theme.typography.small.fontSize}; /* Use small size for icons on extra small screens */
      `}
    }

    ${({ theme }) => theme.media.small`
      font-size: ${theme.typography.small.fontSize}; /* Adjust font size for small screens */
    `}

    ${({ theme }) => theme.media.extraSmall`
      font-size: ${theme.typography.xSmall.fontSize}; /* Smaller font size for extra-small screens */
    `}
  }
`;


export const StyledButton = styled.button`
  margin-top: ${({ theme }) => theme.spacing.m2}; /* Use theme spacing */
  padding: ${({ theme }) => `${theme.spacing.p2} ${theme.spacing.p4}`}; /* Use theme spacing for padding */
  background-color: ${({ theme }) => theme.colors.background}; /* Use theme background color */
  color: ${({ theme }) => theme.colors.text}; /* Use theme text color */
  border: 1px solid ${({ theme }) => theme.colors.primary}; /* Use theme primary color for border */
  border-radius: ${({ theme }) => theme.borderRadius.medium}; /* Use theme border radius */
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.body.fontSize}; /* Default theme font size for button */

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary}; /* Change background color on hover */
    color: ${({ theme }) => theme.colors.background}; /* Change text color on hover */
    border: 1px solid ${({ theme }) => theme.colors.primary}; /* Keep the border color consistent */
  }

  /* Responsive styles for small screens */
  ${({ theme }) => theme.media.small`
    font-size: ${theme.typography.small.fontSize}; /* Use small font size for small screens */
    padding: ${theme.spacing.p1} ${theme.spacing.p3}; /* Adjust padding for small screens */
  `}

  /* Responsive styles for extra small screens */
  ${({ theme }) => theme.media.extraSmall`
    font-size: ${theme.typography.xSmall.fontSize}; /* Use xSmall font size for extra small screens */
    padding: ${theme.spacing.p1} ${theme.spacing.p1}; /* Adjust padding for extra small screens */
    border-radius: ${theme.borderRadius.small}; /* Use small border radius for extra small screens */
  `}
`;


export const SpecialSection = styled.div`
  max-width: 700px;
  text-align: left;
  margin-bottom: ${({ theme }) => theme.spacing.m4}; /* Use theme spacing */

  h3 {
    font-size: ${({ theme }) => theme.typography.h2.fontSize}; /* Use theme font size for headings */
    margin-bottom: ${({ theme }) => theme.spacing.m2}; /* Use theme spacing */
    color: ${({ theme }) => theme.colors.primary}; /* Use primary color from theme */
    
    ${({ theme }) => theme.media.small`
      font-size: ${theme.typography.body.fontSize}; /* Adjust font size for small screens */
    `}

    ${({ theme }) => theme.media.extraSmall`
      font-size: ${theme.typography.small.fontSize}; /* Adjust font size for extra small screens */
    `}
  }
  
  p {
    font-size: ${({ theme }) => theme.typography.body.fontSize}; /* Use theme body font size */
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.text}; /* Use theme text color */

    ${({ theme }) => theme.media.small`
      font-size: ${theme.typography.small.fontSize}; /* Adjust font size for small screens */
    `}

    ${({ theme }) => theme.media.extraSmall`
      font-size: ${theme.typography.small.fontSize}; /* Adjust font size for extra small screens */
    `}
  }
  
  button {
    margin-top: ${({ theme }) => theme.spacing.m2}; /* Use theme spacing */
    background-color: ${({ theme }) => theme.colors.primary}; /* Use primary color from theme */
    color: ${({ theme }) => theme.colors.background}; /* Use background color from theme for button text */
    padding: ${({ theme }) => theme.spacing.p2} ${({ theme }) => theme.spacing.p3}; /* Use theme spacing for padding */
    border: none;
    border-radius: ${({ theme }) => theme.borderRadius.medium}; /* Use theme border radius */
    cursor: pointer;
    font-size: ${({ theme }) => theme.typography.body.fontSize}; /* Use body font size from theme */

    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryLight}; /* Use lighter primary color on hover */
    }

    ${({ theme }) => theme.media.small`
      font-size: ${theme.typography.small.fontSize}; /* Adjust button font size for small screens */
      padding: ${theme.spacing.p1} ${theme.spacing.p2}; /* Adjust button padding for small screens */
    `}

    ${({ theme }) => theme.media.extraSmall`
      font-size: ${theme.typography.small.fontSize}; /* Adjust button font size for extra small screens */
      padding: ${theme.spacing.p1}; /* Adjust button padding for extra small screens */
    `}
  }
`;

// Button container
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem; 
  padding-top: 2rem;
`;
