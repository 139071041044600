import styled from 'styled-components';

// Main container for the property details page
export const PropertyDetailsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing.p4};
  display: flex;
  flex-direction: column;
  padding-top: 100px; 

  ${({ theme }) => theme.media.extraMedium`
    padding: ${theme.spacing.p3};
  `}

  ${({ theme }) => theme.media.medium`
    padding: ${theme.spacing.p2};
    gap: ${theme.spacing.m3};
  `}

  ${({ theme }) => theme.media.small`
    padding: 0.3rem;
  `}

  ${({ theme }) => theme.media.extraSmall`
    padding: 0.3rem; 
  `}
`;

// Wrapper for the main content and the contact form
export const MainContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr; /* 2/3 main content, 1/3 contact form */
  gap: ${({ theme }) => theme.spacing.m4};

  ${({ theme }) => theme.media.extraLarge`
    grid-template-columns: 2fr 1fr; /* Keep 2/3, 1/3 ratio on large screens */
    gap: ${theme.spacing.m3};
  `}

  ${({ theme }) => theme.media.large`
    grid-template-columns: 2fr 1fr; /* Keep 2/3, 1/3 ratio on large screens */
    gap: ${theme.spacing.m3};
  `}

  ${({ theme }) => theme.media.medium`
    grid-template-columns: 1fr; /* Stack main content and form on medium screens */
    gap: ${theme.spacing.m3};
  `}

  ${({ theme }) => theme.media.small`
    display: flex;
    flex-direction: column; /* Stack vertically on small screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    display: flex;
    flex-direction: column; /* Stack vertically on small screens */
  `}
`;

// Section for photos at the top of the page
export const PhotoSection = styled.div`
  cursor: pointer;
  grid-column: span 2; /* Take full width of the grid */
  padding-bottom: ${({ theme }) => theme.spacing.m1};

  ${({ theme }) => theme.media.medium`
    padding-bottom: ${theme.spacing.m2}; /* Adjust padding on medium screens */
  `}

  ${({ theme }) => theme.media.small`
    padding-bottom: 0; /* Reduce padding on small screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    padding-bottom: 0; /* Reduce padding on small screens */
  `}
`;

// Main content area below the photos
export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: ${({ theme }) => theme.spacing.p4}; /* Add right padding */

  ${({ theme }) => theme.media.medium`
    padding-right: ${theme.spacing.p2}; /* Reduce padding on medium screens */
  `}

  ${({ theme }) => theme.media.small`
    padding-right: 0.1rem; /* Reduce padding further on small screens */
    padding-left: 0.3rem;
  `}

  ${({ theme }) => theme.media.extraSmall`
    padding-right: 0.3rem; /* Reduce padding further on small screens */
    padding-left: 0.3rem;
  `}
`;

// Contact form container to the right of the main content
export const ContactFormContainer = styled.div`
  background-color: white;
  padding: ${({ theme }) => theme.spacing.p4};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  box-shadow: ${({ theme }) => theme.boxShadow};
  position: sticky;
  top: 20px;
  align-self: start;

  ${({ theme }) => theme.media.large`
    padding: ${theme.spacing.p3};
  `}

  ${({ theme }) => theme.media.medium`
    padding: ${theme.spacing.p2};
    position: static;
    top: auto;
  `}

  ${({ theme }) => theme.media.small`
    padding: ${theme.spacing.p2};
    box-shadow: none;
    border-radius: ${theme.borderRadius.small};
    position: static; /* Remove sticky positioning on small screens */
    top: auto;
    align-self: center; /* Center align on small screens */
  `}
`;

// Contact form styling
export const ContactForm = styled.div`
  h2 {
    margin-bottom: ${({ theme }) => theme.spacing.m4};
    font-size: ${({ theme }) => theme.typography.h2.fontSize};
    text-align: center;

    ${({ theme }) => theme.media.small`
      font-size: ${theme.typography.body.fontSize};
    `}
  }

  form {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.m2};

    label {
      font-weight: bold;
      margin-bottom: ${({ theme }) => theme.spacing.m1};
      font-size: ${({ theme }) => theme.typography.body.fontSize};

      ${({ theme }) => theme.media.small`
        font-size: ${theme.typography.small.fontSize};
      `}
    }

    input, textarea {
      padding: ${({ theme }) => theme.spacing.p2};
      border-radius: ${({ theme }) => theme.borderRadius.medium};
      border: 1px solid ${({ theme }) => theme.colors.border};
      font-size: ${({ theme }) => theme.typography.body.fontSize};

      ${({ theme }) => theme.media.small`
        padding: ${theme.spacing.p1};
        font-size: ${theme.typography.small.fontSize};
      `}
    }

    textarea {
      resize: vertical;
      min-height: 100px;

      ${({ theme }) => theme.media.small`
        min-height: 80px;
      `}
    }

    button {
      background-color: ${({ theme }) => theme.colors.primary};
      color: white;
      padding: ${({ theme }) => theme.spacing.p3};
      border: none;
      border-radius: ${({ theme }) => theme.borderRadius.medium};
      cursor: pointer;
      font-size: ${({ theme }) => theme.typography.body.fontSize};

      &:hover {
        background-color: ${({ theme }) => theme.colors.primaryDark};
      }

      ${({ theme }) => theme.media.small`
        padding: ${theme.spacing.p2};
        font-size: ${theme.typography.small.fontSize};
      `}
    }
  }
`;

export const MapSection = styled.div`
  margin-top: 2rem;
  width: 100%;
  height: 400px;
  border-radius: ${({ theme }) => theme.borderRadius.medium};

  .leaflet-container {
    border-radius: ${({ theme }) => theme.borderRadius.medium};
    width: 100%;
    height: 100%;
  }
`;