import React, { useState, useEffect } from 'react';
import {
  getAgents,
  getPublicAgentProfile,
  getAgentReviewSummary,
  AgentProfile,
  AgentReviewSummary,
} from '../../services/agentService';
import { useNavigate } from 'react-router-dom';
import {
  AgentCardContainer,
  PhotoNameContainer,
  AgentPhoto,
  AgentInfo,
  AgentListedHeading
} from '../../styles/AgentListPageStyles';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import UserTopBar from '../../components/common/UserTopBar';

interface AgentProfileWithReviews extends AgentProfile {
  overall_average_rating: number;
  review_count: number;
}

const AgentListPage: React.FC = () => {
  const [agents, setAgents] = useState<AgentProfileWithReviews[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  // Fetch all agents and their review summaries when the page loads
  useEffect(() => {
    async function fetchAgents() {
      setIsLoading(true);
      try {
        const agentsList = await getAgents();
        
        // Fetch profile and review summary for each agent
        const agentProfilesWithReviews: AgentProfileWithReviews[] = await Promise.all(
          agentsList.map(async (agent) => {
            const profileData = await getPublicAgentProfile(agent.id);
            const reviewSummary: AgentReviewSummary = await getAgentReviewSummary(agent.id);

            return {
              ...profileData,
              overall_average_rating: reviewSummary.overall_average_rating,
              review_count: reviewSummary.review_count,
            };
          })
        );
        setAgents(agentProfilesWithReviews);
      } catch (err) {
        setError('Failed to load agents.');
      } finally {
        setIsLoading(false);
      }
    }

    fetchAgents();
  }, []);

  const handleAgentClick = (agentId: number) => {
    navigate(`/agent-profile/${agentId}`);
  };

  const formatTimeOnPlatform = (years: number, months: number) => {
    if (years === 0 && months === 0) {
      return 'New on TimeAZ';
    } else if (years > 0 && months > 0) {
      return `${years} year${years > 1 ? 's' : ''} and ${months} month${months > 1 ? 's' : ''} on TimeAZ`;
    } else if (years > 0) {
      return `${years} year${years > 1 ? 's' : ''} on TimeAZ`;
    } else if (months > 0) {
      return `${months} month${months > 1 ? 's' : ''} on TimeAZ`;
    }
    return '';
  };  

  return (
    <>
    <UserTopBar/>
    <div className="agent-list-page">
      {error && <p className="error">{error}</p>}
      {isLoading && <LoadingSpinner />}
      <AgentListedHeading>The list of agents</AgentListedHeading>
      {/* Agent List */}
      <div className="agent-list">
        {agents.map((agent) => (
          <AgentCardContainer key={agent.id} onClick={() => handleAgentClick(agent.id)}>
            <PhotoNameContainer>
              <AgentPhoto src={agent.photo} alt={agent.preferred_name} />
              <h2>{agent.preferred_name}</h2>
            </PhotoNameContainer>
            <AgentInfo>
              <div className="rating-review">
                <span className="rating">{agent.overall_average_rating.toFixed(1)}</span>
                <span className="divider">|</span>
                <span className="review-count">{agent.review_count} reviews</span>
              </div>
              <p className="time-on-platform">
                {formatTimeOnPlatform(agent.years_on_platform || 0, agent.months_on_platform || 0)}
              </p>
            </AgentInfo>
          </AgentCardContainer>
        ))}
      </div>
    </div>
    </>
  );
};

export default AgentListPage;
