import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { TopbarWrapper, Logo, NavMenu, NavItem, LogoutButton } from '../../styles/TopbarStyles';
import { agentLogout } from '../../services/agentService';

const Topbar: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await agentLogout();
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const logoImageUrl = "https://timeaz.blob.core.windows.net/media/LOGO_TIMEAZ.svg";  

  return (
    <TopbarWrapper>
      {/* Update Logo link to redirect to TimeAZ homepage */}
      <Logo to="/">
        <img src={logoImageUrl} alt="Logo" />
      </Logo>
      <NavMenu>
        <NavItem>
          <NavLink to="/agent-dashboard">Your Dashboard</NavLink>
        </NavItem>
        {/* NavItem for navigating to agent list page */}
        <NavItem>
          <NavLink to="/agents">Agents</NavLink>
        </NavItem>

        {/* NavItem for navigating to blogs page */}
        <NavItem>
          <NavLink to="/blogs">Blogs</NavLink>
        </NavItem>

        {/* Check if the user is authenticated before showing the Logout button */}
        {localStorage.getItem('access_token') ? (
          <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
        ) : (
          <NavItem>
            <NavLink to="/login">Log in</NavLink>
          </NavItem>
        )}
      </NavMenu>
    </TopbarWrapper>
  );
};

export default Topbar;
