import React, { useState, useEffect } from 'react';
import { getPersonalInfo, AgentPersonalInfo } from '../../services/agentService';
import { DashboardContainer, Heading, DashboardGrid, DashboardCard, DashboardButton, Badge } from '../../styles/AgentDashboardStyles'; // Import the new DashboardButton
import { FaUser, FaHome, FaPlus, FaListAlt, FaCheckCircle, FaEnvelope } from 'react-icons/fa';
import Topbar from '../../components/common/Topbar';
import PersonalInfoModal from '../../components/agent/PersonalInfoModal';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import { getUnreadMessageCount } from '../../services/agentService';

const AgentDashboardPage: React.FC = () => {
  const [agentInfo, setAgentInfo] = useState<AgentPersonalInfo | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showPersonalInfoModal, setShowPersonalInfoModal] = useState(false); // Control modal visibility
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const fetchUnreadCount = async () => {
      try {
        const { unread_messages } = await getUnreadMessageCount(); // Fetch unread messages count
        setUnreadCount(unread_messages);
      } catch (error) {
        console.error('Error fetching unread message count:', error);
      }
    };

    fetchUnreadCount();
  }, []);

  useEffect(() => {
    // Fetch agent's info when the component mounts
    const fetchAgentInfo = async () => {
      setIsLoading(true);
      try {
        const data = await getPersonalInfo(); // Fetching the agent's info using the personal info API
        setAgentInfo(data);
      } catch (error) {
        console.error('Error fetching agent info:', error);
      } finally {
        setIsLoading(false);  // End loading when fetching is done
      }
    };
    fetchAgentInfo();
  }, []);

  const openPersonalInfoModal = () => {
    setShowPersonalInfoModal(true); // Open modal
  };

  const closePersonalInfoModal = () => {
    setShowPersonalInfoModal(false); // Close modal
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <Topbar /> {/* Consistent Topbar included */}
      <DashboardContainer>
        <Heading>Account</Heading>
        {agentInfo ? (
          <p>
            <strong>{agentInfo.preferred_name},</strong> {agentInfo.email}
          </p>
        ) : (
          <p>Loading...</p>
        )}

        <DashboardGrid>
          {/* Personal Info Card using DashboardButton */}
          <DashboardButton onClick={openPersonalInfoModal}>
            <FaUser />
            <h3>Personal Info</h3>
            <p>Provide personal details</p>
          </DashboardButton>
          {/* Other Cards */}
          <DashboardCard to="/agent/profile">
            <FaHome />
            <h3>Profile</h3>
            <p>Showcase yourself to your buyers</p>
          </DashboardCard>
          <DashboardCard to="/agent/add-property">
            <FaPlus />
            <h3>Add a new property</h3>
            <p>Showcase a new property</p>
          </DashboardCard>
          <DashboardCard to="/agent/sold-properties">
            <FaCheckCircle />
            <h3>Sold properties</h3>
            <p>See your sold properties</p>
          </DashboardCard>
          <DashboardCard to="/agent/listed-properties">
            <FaListAlt />
            <h3>Listed properties</h3>
            <p>See your properties for sale</p>
          </DashboardCard>
          <DashboardCard to="/agent/messages">
            <FaEnvelope />
            <h3>Messages</h3>
            <p>Check your inbox for messages</p>
            {unreadCount > 0 && <Badge>{unreadCount}</Badge>} {/* Show badge if there are unread messages */}
        </DashboardCard>
        </DashboardGrid>
      </DashboardContainer>

      {/* Conditionally render the Personal Info Modal */}
      {showPersonalInfoModal && (
        <PersonalInfoModal onClose={closePersonalInfoModal} />
      )}
    </>
  );
};

export default AgentDashboardPage;
