// BlogPostPage.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BlogPost, getBlogPost } from '../../services/blogService';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import UserTopBar from '../../components/common/UserTopBar';
import { AgentProfile, getPublicAgentProfile } from '../../services/agentService';
import { 
  BlogPostContainer, 
  FeaturedImage, 
  PostContent, 
  PostMeta, 
  PostTitle, 
  TagList, 
  BackToBlogLink 
} from '../../styles/BlogPostPageStyles';

const BlogPostPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState(true);
  const [authorName, setAuthorName] = useState<string>('Unknown Author');

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const data = await getBlogPost(slug!);
        setPost(data);
      } catch (error) {
        console.error('Error fetching blog post:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchPost();
  }, [slug]);

  useEffect(() => {
    const fetchAuthorName = async (authorId: number) => {
      try {
        const agentProfile: AgentProfile = await getPublicAgentProfile(authorId);
        setAuthorName(agentProfile.preferred_name);
      } catch (error) {
        console.error('Error fetching agent profile:', error);
      }
    };

    if (post?.author) {
      fetchAuthorName(post.author);
    }
  }, [post]);

  if (loading) return <LoadingSpinner />;
  if (!post) return <p>Post not found.</p>;

  const formattedDate = new Date(post.created_at).toLocaleDateString();

  return (
    <>
      <UserTopBar />
      <BlogPostContainer>
        <FeaturedImage src={post.featured_image} alt={post.title} />
        <PostTitle>{post.title}</PostTitle>
        <PostMeta>By {authorName}</PostMeta>
        <PostMeta>Published on: {formattedDate}</PostMeta>
        <PostMeta>Reading Time: {post.reading_time} min</PostMeta>
        <PostMeta>Category: {post.category.name}</PostMeta>

        <PostContent dangerouslySetInnerHTML={{ __html: post.content }} />
        {post.tags.length > 0 && (
          <TagList>
            {post.tags.map((tag, index) => (
              <span key={index}>{tag}</span>
            ))}
          </TagList>
        )}
        <BackToBlogLink href="/blogs">Back to Blog</BackToBlogLink>
      </BlogPostContainer>
    </>
  );
};

export default BlogPostPage;
