import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: ${({ theme }) => theme.text};
    background-color: ${({ theme }) => theme.background};
    line-height: 1.6;
  }

  h1, h2, h3 {
    color: ${({ theme }) => theme.heading};
    font-weight: bold;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.link};
  }
`;
