// src/styles/PropertyCreationStepSixStyles.ts

import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

// Wrapper for the entire step
export const StepSixWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  margin-top: 8rem;
`;

// Section heading
export const StepSixHeading = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
`;

// Styled input field
export const StyledInput = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  padding: 0.75rem;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  width: 100%;
  max-width: 400px;
  text-align: center;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
  }
`;

// DatePicker Wrapper to style the React DatePicker component
export const DatePickerWrapper = styled.div`
  margin-bottom: 1.5rem;

  .react-datepicker-wrapper {
    width: 100%;
    max-width: 400px;
  }

  .react-datepicker__input-container input {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 8px;
    font-size: 1rem;
    text-align: center;

    &:focus {
      border-color: ${({ theme }) => theme.colors.primary};
      outline: none;
    }
  }
`;
