import React, { useEffect, useState } from 'react';
import { getInboxMessages, getUnreadMessageCount, markMessageAsRead } from '../../services/agentService';
import { InboxContainer, MessageCard, MessageInfo, PropertyLink, InboxHeading } from '../../styles/AgentMessagesStyles';
import { MessageInbox } from '../../services/agentService';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaEnvelopeOpen } from 'react-icons/fa';
import Topbar from '../../components/common/Topbar';
import { Breadcrumb } from '../../styles/AgentProfileStyles';

const AgentMessagesPage: React.FC = () => {
  const [messages, setMessages] = useState<MessageInbox[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const fetchMessages = async () => {
      setIsLoading(true);
      try {
        const inboxMessages = await getInboxMessages();
        setMessages(inboxMessages);

        const unreadData = await getUnreadMessageCount(); // Fetch unread message count
        setUnreadCount(unreadData.unread_messages);

      } catch (error) {
        console.error('Failed to load messages:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMessages();
  }, []);

  // Handle marking message as read
  const handleMarkAsRead = async (messageId: number) => {
    try {
      // Call the API to mark the message as read
      await markMessageAsRead(messageId);

      // Update the message list in the frontend to reflect the read status
      setMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.property === messageId ? { ...message, is_read: true } : message
        )
      );

      // Update unread count
      setUnreadCount((prevUnreadCount) => prevUnreadCount - 1);
    } catch (error) {
      console.error('Error marking message as read:', error);
    }
  };

  const sortedMessages = [...messages].sort((a, b) => {
    return a.is_read === b.is_read ? 0 : a.is_read ? 1 : -1;
  });

  return (
    <>
    <Topbar/>
    <Breadcrumb>
        <a href="/agent-dashboard">Account</a>
        <span>›</span> {/* Unicode arrow symbol */}
        <span>Messages</span>
      </Breadcrumb>
    <InboxHeading>You have {unreadCount} unread messages</InboxHeading>
    <InboxContainer>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          {/* If no messages found */}
          {messages.length === 0 ? (
            <p>No messages found.</p>
          ) : (
            sortedMessages.map((message, index) => (
              <MessageCard
                key={index}
                onClick={() => handleMarkAsRead(message.id)}
                className={message.is_read ? 'read' : 'unread'}
              >
                <MessageInfo>
                  <h3>From: {message.full_name}</h3>
                  <p>Contact: {message.contact_info}</p>
                  <p>Message: {message.message}</p>

                  {/* Add property link */}
                  <PropertyLink>
                    <Link to={`/property-details/${message.property}`}>
                      View Property
                    </Link>
                  </PropertyLink>
                </MessageInfo>

                {/* Show envelope icon based on read/unread status */}
                <div className="message-status">
                  {message.is_read ? <FaEnvelopeOpen /> : <FaEnvelope />}
                </div>
              </MessageCard>
            ))
          )}
        </>
      )}
    </InboxContainer>
    </>
  );
};

export default AgentMessagesPage;
