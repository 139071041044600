import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import {
  TopbarWrapper,
  Logo,
  NavMenu,
  NavItem,
  SignInButton,
  HamburgerMenu,
  DropdownMenu,
  DropdownItem,
  DropdownWrapper
} from '../../styles/UserTopbarStyles';
import { FaBars } from 'react-icons/fa';
import { getAccessToken, logout, getUserProfile } from '../../services/userService';
import { getAgentProfile } from '../../services/agentService'; // Import the agent profile API
import LoadingSpinner from './LoadingSpinner';

const UserTopBar: React.FC = () => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // For mobile menu
  const [showDesktopDropdown, setShowDesktopDropdown] = useState(false); // For desktop dropdown
  const [userFirstName, setUserFirstName] = useState<string | null>(null);
  const [agentPreferredName, setAgentPreferredName] = useState<string | null>(null); // For agent name
  const [isAgent, setIsAgent] = useState(false); // To differentiate between user and agent
  const [isLoading, setIsLoading] = useState<boolean>(false); // Set initial state to false
  const [showModal, setShowModal] = useState(false); // For agent dashboard and logout modal
  const desktopDropdownRef = useRef<HTMLDivElement | null>(null);
  const mobileDropdownRef = useRef<HTMLDivElement | null>(null);

  const logoImageUrl = 'https://timeaz.blob.core.windows.net/media/LOGO_TIMEAZ.svg';

  const toggleDesktopDropdown = () => {
    setShowDesktopDropdown((prev) => !prev);
  };

  const toggleMobileDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  // Close dropdowns if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        desktopDropdownRef.current &&
        !desktopDropdownRef.current.contains(event.target as Node)
      ) {
        setShowDesktopDropdown(false);
      }
      if (
        mobileDropdownRef.current &&
        !mobileDropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Fetch user data if logged in
  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true); // Start loading
      const token = getAccessToken();
      if (token) {
        try {
          const userData = await getUserProfile();
          setUserFirstName(userData.first_name);
          setIsAgent(userData.is_agent); // Check if user is an agent
          console.log(userData)

          if (userData.is_agent) {
            const agentData = await getAgentProfile(); // Fetch agent profile if the user is an agent
            setAgentPreferredName(agentData.preferred_name);
            console.log(agentData.preferred_name)
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          await logout();
          setUserFirstName(null);
        } finally {
          setIsLoading(false); // End loading
        }
      } else {
        setIsLoading(false); // End loading if no token
      }
    };

    fetchUserData();
  }, []);

  const handleLogout = async () => {
    setIsLoading(true); // Start loading
    try {
      await logout();
      setUserFirstName(null);
      setAgentPreferredName(null); // Reset agent name on logout
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      setIsLoading(false); // End loading when fetching is done
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <TopbarWrapper>
        {/* Logo */}
        <Logo to="/">
          <img src={logoImageUrl} alt="Logo" />
        </Logo>

        {/* Navigation Menu for Desktop */}
        <NavMenu>
          <NavItem>
            <NavLink to="/agents">Agents</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/blogs">Blogs</NavLink>
          </NavItem>
          {userFirstName || agentPreferredName ? (
            <NavItem ref={desktopDropdownRef}>
              <SignInButton onClick={toggleDesktopDropdown}>
                Hi {agentPreferredName || userFirstName} {/* Show agent name if available */}
              </SignInButton>
              {showDesktopDropdown && (
                <DropdownWrapper isOpen={showDesktopDropdown}>
                  <DropdownMenu>
                    {isAgent ? (
                      <>
                        <DropdownItem onClick={() => navigate('/agent-dashboard')}>
                          Dashboard
                        </DropdownItem>
                        <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                      </>
                    ) : (
                      <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                    )}
                  </DropdownMenu>
                </DropdownWrapper>
              )}
            </NavItem>
          ) : (
            <NavItem>
              <SignInButton onClick={() => navigate('/auth')}>Login</SignInButton>
            </NavItem>
          )}
        </NavMenu>

        {/* Hamburger Menu for Small Screens */}
        {/* <HamburgerMenu onClick={toggleMobileDropdown}>
          <FaBars />
        </HamburgerMenu> */}

        {/* Dropdown Menu for Small Screens */}
        {/* {isDropdownOpen && (
          <DropdownWrapper ref={mobileDropdownRef} isOpen={isDropdownOpen}>
            <DropdownMenu>
              <DropdownItem>
                <Link to="/agents">Agents</Link>
              </DropdownItem>
              <DropdownItem>
                <Link to="/blogs">Blogs</Link>
              </DropdownItem>
              {userFirstName || agentPreferredName ? (
                <>
                  <DropdownItem>
                    <span>Hi {agentPreferredName || userFirstName}</span>
                  </DropdownItem>
                  {isAgent ? (
                    <>
                      <DropdownItem onClick={() => navigate('/agent-dashboard')}>
                        Dashboard
                      </DropdownItem>
                      <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                    </>
                  ) : (
                    <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                  )}
                </>
              ) : (
                <DropdownItem>
                  <Link to='/auth'>Login</Link>
                </DropdownItem>
              )}
            </DropdownMenu>
          </DropdownWrapper>
        )} */}
      </TopbarWrapper>
    </>
  );
};

export default UserTopBar;
