import React, { useState, useEffect } from 'react';
import { PropertyDetail } from '../../services/propertyService';
import { useNavigate } from 'react-router-dom';
import PhotoCarousel from './PhotoCarousel';
import { FaArrowLeft, FaArrowRight, FaCheckCircle, FaMapMarkerAlt } from 'react-icons/fa';
import {
  CarouselWrapper,
  SlideContainer,
  PropertyWrapper,
  ArrowButton,
} from '../../styles/PropertyCarouselStyles';
import { PropertyCard, PropertyDetails, AgentDetails } from '../../styles/HomePageStyles';

interface PropertyCarouselProps {
  properties: PropertyDetail[];
  visiblePosts: number;
  cityLookup: { [key: number]: string };
  agentProfiles: { [key: number]: any };
  agentReviews: { [key: number]: any };
}

const PropertyCarousel: React.FC<PropertyCarouselProps> = ({
  properties,
  cityLookup,
  agentProfiles,
  agentReviews,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visiblePosts, setVisiblePosts] = useState(4); // Start with 4 visible properties
  const navigate = useNavigate();

  useEffect(() => {
    // Update visiblePosts based on screen size (responsive behavior)
    const updateVisiblePosts = () => {
      if (window.innerWidth <= 576) {
        setVisiblePosts(1); // Extra small screens
      } else if (window.innerWidth <= 1024) {
        setVisiblePosts(5); // Small screens
      } else if (window.innerWidth >= 1030) {
        setVisiblePosts(5); // Medium screens
      }
    };

    updateVisiblePosts(); // Set on component mount
    window.addEventListener('resize', updateVisiblePosts); // Listen to window resize

    return () => {
      window.removeEventListener('resize', updateVisiblePosts); // Clean up listener
    };
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? properties.length - visiblePosts : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === properties.length - visiblePosts ? 0 : prevIndex + 1
    );
  };

  const handlePropertyClick = (propertyId: number) => {
    navigate(`/property-details/${propertyId}`);
  };

  const formatPrice = (price: string) => {
    const number = parseFloat(price);
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
    }).format(number);
  };

  const handleAgentClick = (agentId: number, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering the parent card's click event
    navigate(`/agent-profile/${agentId}`);
  };

  return (
    <div style={{ position: 'relative' }}>
    <CarouselWrapper>
      <SlideContainer currentIndex={currentIndex} visiblePosts={visiblePosts}>
        {properties.map((property) => {
          const agentProfile = agentProfiles[property.agent_id];
          const agentReview = agentReviews[property.agent_id];

          return (
            <PropertyWrapper visiblePosts={visiblePosts} key={property.id}>
              <PropertyCard onClick={() => handlePropertyClick(property.id)}>
                <PhotoCarousel photos={property.photos} />
                <PropertyDetails>
                  <p className="property-info">
                    <strong className="property-price">{`${property.currency}${formatPrice(property.price)}`}</strong>{' '}
                    {property.bedrooms} beds | {property.bathrooms} baths | {property.area} sqm
                  </p>
                  <p><FaMapMarkerAlt /> {cityLookup[property.city]}</p>
                </PropertyDetails>
                {agentProfile && agentReview && (
                  <AgentDetails onClick={(e) => handleAgentClick(property.agent_id, e)}>
                    <div className="agent-photo">
                      <img src={agentProfile.photo} alt={agentProfile.preferred_name} />
                      <FaCheckCircle className="verified-icon" />
                    </div>
                    <div className="agent-info">
                      <span className="agent-name">{agentProfile.preferred_name}</span>
                      <span className="divider">|</span>
                      <span className="agent-rating">
                        {agentReview.overall_average_rating.toFixed(1)}
                      </span>
                      <span className="divider">|</span>
                      <span className="review-count">{agentReview.review_count} reviews</span>
                    </div>
                  </AgentDetails>
                )}
              </PropertyCard>
            </PropertyWrapper>
          );
        })}
      </SlideContainer>
    </CarouselWrapper>
    {/* Move Arrow buttons here */}
    <ArrowButton className="left" onClick={handlePrev}>
      <FaArrowLeft />
    </ArrowButton>
    <ArrowButton className="right" onClick={handleNext}>
      <FaArrowRight />
    </ArrowButton>
  </div>
  );
};

export default PropertyCarousel;
