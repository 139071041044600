import styled from 'styled-components';

// Wrapper for the form content with vertical centering for text
export const StepTwoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align content to the top */
  padding: 2rem 0;
  margin-top: 0rem;

  ${({ theme }) => theme.media.medium`
    padding: 1.5rem 0; /* Reduce padding for medium screens */
  `}

  ${({ theme }) => theme.media.small`
    padding: 1rem 0; /* Further reduce padding for small screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    padding: 0.5rem 0; /* Further reduce padding for extra-small screens */
  `}
`;

// Section for the headings with centered text
export const SectionHeading = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: center;

  ${({ theme }) => theme.media.medium`
    font-size: 1.5rem; /* Adjust font size for medium screens */
  `}

  ${({ theme }) => theme.media.small`
    font-size: 1.3rem; /* Adjust font size for small screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    font-size: 1.3rem; /* Adjust font size for extra-small screens */
  `}
`;

// Subtext for additional guidance with centered alignment under heading
export const Subtext = styled.p`
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-bottom: 2rem;
  text-align: center;
  width: 100%;
  max-width: 600px;

  ${({ theme }) => theme.media.medium`
    font-size: ${theme.typography.small.fontSize}; /* Adjust font size for medium screens */
    margin-bottom: 1.5rem; /* Adjust margin for medium screens */
  `}

  ${({ theme }) => theme.media.small`
    font-size: ${theme.typography.small.fontSize}; /* Adjust font size for small screens */
    margin-bottom: 1rem; /* Adjust margin for small screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    font-size: ${theme.typography.xSmall.fontSize}; /* Adjust font size for extra-small screens */
    margin-bottom: 0.5rem; /* Adjust margin for extra-small screens */
  `}
`;

// Property info section for labels and inputs/buttons with consistent alignment
export const PropertyInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr; /* Two columns: label and input */
  grid-gap: 1rem;
  align-items: center; /* Align items vertically */
  justify-content: right; /* Ensure text aligns correctly */
  margin-bottom: 2rem;
  margin-left: 0;
  margin-top: 2rem;
  text-align: left;

  label {
    text-align: right; /* Right-align the labels */
    padding-right: 1rem; /* Add space between label and input */
    font-size: ${({ theme }) => theme.typography.body.fontSize};
  }

  ${({ theme }) => theme.media.medium`
    grid-template-columns: 1fr; /* Stack items vertically on medium screens */
    margin-left: 0rem; /* Remove left margin */
  `}

  ${({ theme }) => theme.media.small`
    grid-template-columns: 1fr; /* Stack items vertically on small screens */
    grid-gap: ${theme.spacing.m2}; /* Adjust gap */
    text-align: center; /* Center text for small screens */
    label {
      text-align: center; /* Center labels on small screens */
      padding-right: 0;
    }
  `}

  ${({ theme }) => theme.media.extraSmall`
    grid-template-columns: 1fr; /* Stack items vertically on extra-small screens */
    text-align: center;
    label {
      text-align: center; /* Center labels */
    }
  `}
`;

// Increment-Decrement Wrapper for Bedrooms/Bathrooms
export const IncrementWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 0;

  .value-display {
    width: 2.5rem; /* Adjust width */
    text-align: center;
    font-weight: bold;
  }

  button {
    background-color: white;
    border: 1px solid ${({ theme }) => theme.colors.textSecondary};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
      color: white;
    }
  }

  ${({ theme }) => theme.media.medium`
    justify-content: center; /* Center align items for medium screens */
  `}

  ${({ theme }) => theme.media.small`
    justify-content: center; /* Center align items for small screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    justify-content: center; /* Center align items for extra-small screens */
  `}
`;

// Input fields for Area and Floor with adjusted width for alignment
export const InputField = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  width: 30%; /* Use full width */
  text-align: center;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
  }

  ${({ theme }) => theme.media.medium`
    width: 50%; /* Adjust width for extra-small screens */
    margin-left: 5rem;
  `}

  ${({ theme }) => theme.media.small`
    width: 50%; /* Adjust width for extra-small screens */
    margin-left: 5rem;
  `}

  ${({ theme }) => theme.media.extraSmall`
    width: 50%; /* Adjust width for extra-small screens */
    margin-left: 5rem;
  `}
`;

// View options (Economy, Standard, Premium) with centered buttons
export const ViewOptions = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center; /* Center align for desktop */
  align-items: center; /* Align the buttons with the label vertically */
  flex-wrap: wrap; /* Enable wrapping */

  button {
    background-color: white;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 8px;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      border-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.primary};
    }

    &.active {
      background-color: ${({ theme }) => theme.colors.primary};
      color: white;
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }

  ${({ theme }) => theme.media.medium`
    flex-direction: column; /* Stack buttons vertically for medium screens */
    gap: ${theme.spacing.m1}; /* Adjust gap */
  `}

  ${({ theme }) => theme.media.small`
    flex-direction: column; /* Stack buttons vertically for small screens */
    gap: ${theme.spacing.m1}; /* Adjust gap */
    button {
      font-size: 0.8rem;
      padding: 0.5rem 1rem;
    }
  `}

  ${({ theme }) => theme.media.extraSmall`
    flex-direction: column; /* Stack buttons vertically for extra-small screens */
    gap: ${theme.spacing.m1}; /* Further adjust gap */
    button {
      font-size: 0.75rem; /* Adjust font size */
      padding: 0.4rem 0.8rem; /* Adjust padding */
    }
  `}
`;
