import React, { useMemo, useState } from 'react';
import { 
  FaSwimmingPool, FaBuilding, FaHandsWash, FaChild, FaDumbbell, 
  FaSoap, FaUserTie, FaTree, FaHandshake, FaDoorOpen, FaLock, 
  FaBook, FaBusAlt, FaUsers, FaBath, FaEye, FaCalendarAlt, FaHome, FaRulerCombined, FaBed,
  FaMapMarkerAlt
} from 'react-icons/fa'; 
import PhotoGrid from './PhotoGrid';
import {
  ReviewContainer,
  PriceSection,
  AboutSection,
  AmenitiesSection,
  SpecialSection,
  StyledButton,
} from '../../styles/PropertyReviewStyles';
import { PropertyStatus, Photo } from '../../services/propertyService';

// Define the type for formData
interface PropertyReviewProps {
  formData: {
    property_type: string;
    photo_data: Photo[];
    price: string;
    bedrooms: number;
    bathrooms: number;
    area: number;
    project_name: string;
    city: string;
    country: string;
    status: PropertyStatus;
    delivery_date?: string;
    built_in?: string;
    view: string;
    floor: number;
    amenities: string[];
    description: string;
    currency: string;
  };
}

// Define a type for the possible amenities
type AmenityType =
  | 'swimming pool'
  | 'parking'
  | 'laundry'
  | 'kids ground play'
  | 'indoor gym'
  | 'cleaning services'
  | 'on-site management'
  | 'outdoor recreational areas'
  | 'landscape'
  | 'conference rooms'
  | 'event rooms'
  | 'security guard'
  | 'study room'
  | 'shuttle service'
  | 'clubhouse'
  | 'turkish bath';


const PropertyReview: React.FC<PropertyReviewProps> = ({ formData }) => {
  const {
    property_type,
    photo_data,
    price,
    bedrooms,
    bathrooms,
    area,
    project_name,
    city,
    status,
    delivery_date,
    built_in,
    view,
    floor,
    amenities,
    description,
    currency,
  } = formData;

  const [showAll, setShowAll] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  // Convert File objects to Object URLs for rendering in PhotoGrid
  const amenityIcons: Record<AmenityType, JSX.Element> = {
    'swimming pool': <FaSwimmingPool />,
    'parking': <FaBuilding />,
    'laundry': <FaHandsWash />,
    'kids ground play': <FaChild />,
    'indoor gym': <FaDumbbell />,
    'cleaning services': <FaSoap />,
    'on-site management': <FaUserTie />,
    'outdoor recreational areas': <FaTree />,
    'landscape': <FaTree />,
    'conference rooms': <FaHandshake />,
    'event rooms': <FaDoorOpen />,
    'security guard': <FaLock />,
    'study room': <FaBook />,
    'shuttle service': <FaBusAlt />,
    'clubhouse': <FaUsers />,
    'turkish bath': <FaBath />,
  };

  const renderAmenityIcon = (amenity: string) => {
    const lowerCaseAmenity = amenity.toLowerCase() as AmenityType;
    return amenityIcons[lowerCaseAmenity] || <FaHome />; // FaHome as fallback
  };

  const formatPrice = (price: string) => {
    const number = parseFloat(price);
    return new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 2 }).format(number);
  };

  return (
    <ReviewContainer>
      {/* Image Grid (Carousel) */}
      <PhotoGrid photos={photo_data} /> 

      <PriceSection>
        <div className="left-section">
        <h1>{`${currency}${formatPrice(price)}`}</h1>
          <div className="project-location">
            <h2>{project_name} project</h2> {/* Project Name */}
            <p><FaMapMarkerAlt /> {city}</p> {/* City */}
          </div>
        </div>
        <div className="details">
          <span><FaBed /> {bedrooms} beds</span> {/* Bedrooms */}
          <span><FaBath /> {bathrooms} baths</span> {/* Bathrooms */}
          <span><FaRulerCombined /> {area} sqm</span> {/* Area */}
        </div>
      </PriceSection>

      <AboutSection>
        {/* Property Type */}
        <div>
          <FaHome /> <span>{property_type}</span>
        </div>

        {/* Status with Date */}
        <div className="status">
          <div className="status-name">
            <FaCalendarAlt /> <span>{status.name}</span>
          </div>
          <span className="status-date">
            {status.requires_delivery_date && delivery_date && `Delivery Date: ${delivery_date}`}
            {status.requires_built_in_date && built_in && `Built-in Date: ${built_in}`}
          </span>
        </div>

        {/* View */}
        <div>
          <FaEye /> <span>{view} view</span>
        </div>

        {/* Floor */}
        <div>
          <FaBuilding /> <span>Floor {floor}</span>
        </div>
      </AboutSection>

      {/* Amenities Section */}
      <AmenitiesSection>
        <h3>What this place offers</h3>
        <div className="amenities-grid">
          {amenities.slice(0, showAll ? amenities.length : 6).map((amenity, index) => (
            <div key={index} className="amenity-item">
              {renderAmenityIcon(amenity)} <span>{amenity}</span>
            </div>
          ))}
        </div>

        {/* Show "Show All" Button if there are more than 6 amenities */}
        {amenities.length > 6 && !showAll && (
          <StyledButton onClick={() => setShowAll(true)}>
            Show all {amenities.length} amenities
          </StyledButton>
        )}

        {/* Optionally hide the amenities again */}
        {showAll && (
          <StyledButton onClick={() => setShowAll(false)}>
            Show less
          </StyledButton>
        )}
      </AmenitiesSection>

      {/* What's Special Section */}
      <SpecialSection>
        <h3>What's special</h3>
        <p>
          {showFullDescription ? description : `${description.split(' ').slice(0, 50).join(' ')}...`}
        </p>

        {/* Show "Show more" button if description exceeds threshold */}
        {description.split(' ').length > 50 && !showFullDescription && (
          <StyledButton onClick={() => setShowFullDescription(true)}>
            Show more
          </StyledButton>
        )}

        {/* Optionally hide the description again */}
        {showFullDescription && (
          <StyledButton onClick={() => setShowFullDescription(false)}>
            Show less
          </StyledButton>
        )}
      </SpecialSection>

    </ReviewContainer>
  );
};

export default PropertyReview;
