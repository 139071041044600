// Styles for the Auth Selection Page
import styled from 'styled-components';

export const AuthContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;

  h3 {
    font-size: 1.3rem;
  }

  // Container for the buttons to align them horizontally
  .button-group {
    flex-direction: row;
    display: flex;
    gap: 1rem; // Adjust gap as needed
  }

  ${({ theme }) => theme.media.small`
    h3 {
      font-size: 1rem;
    }
    .button-group {
      flex-direction: column;
      display: flex;
      gap: 1rem; // Adjust gap as needed
    }
  `}

  ${({ theme }) => theme.media.extraSmall`
    h3 {
      font-size: 1rem;
    }
    .button-group {
      flex-direction: column;
      display: flex;
      gap: 1rem; // Adjust gap as needed
    }
  `}
`;

export const HomeWallPaper = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Full height to cover entire viewport */
  z-index: -1; /* Place the image behind all other content */
  opacity: 0.35;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire section */
  }
`;

export const Button = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  box-shadow: ${({ theme }) => theme.boxShadow};
  width: 300px;
  height: 100px;
  border: 2px solid transparent;
  border-radius: 30px;
  transition: border-color 0.3s ease-in-out;
  cursor: pointer;
  text-align: center;
  font-weight: bold;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  ${({ theme }) => theme.media.small`
    width: 180px;
    height: 80px;
    padding: 0;
  `}

  ${({ theme }) => theme.media.extraSmall`
    width: 180px;
    height: 80px;
    padding: 0;
  `}
`;