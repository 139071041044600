import React from 'react';
import { ModalWrapper, RankDropdown, RankBadge, ModalContent, CloseButton, PhotoGrid, PhotoCard, DeleteButton } from '../../styles/PhotoModalStyles';
import { Photo } from '../../services/propertyService';

interface PhotoPreviewModalProps {
  photos: Photo[];
  onClose: () => void;
  onDeletePhoto: (photoIndex: number) => void;
  onRankChange: (photoId: number, newRank: number) => void;
  onUploadMore: (e: React.ChangeEvent<HTMLInputElement>) => void; // This function should handle upload and update URLs
}

const PhotoPreviewModal: React.FC<PhotoPreviewModalProps> = ({ photos, onClose, onDeletePhoto, onRankChange, onUploadMore }) => {
  return (
    <ModalWrapper>
      <ModalContent>
        <CloseButton onClick={onClose}>×</CloseButton>

        <h2>Uploaded Photos</h2>

        {/* Display photo URLs in a grid */}
        <PhotoGrid>
         {photos.map((photo) => (
            <PhotoCard key={photo.id}>
              <img src={photo.image_url} alt={`Uploaded photo`} />
              <DeleteButton onClick={() => onDeletePhoto(photo.id)}>Delete</DeleteButton>

              {/* Display Rank Badge only for ranked photos (1-5) */}
              {photo.rank > 0 && photo.rank <= 5 && (
                <RankBadge>{photo.rank}</RankBadge>
              )}
              
              <RankDropdown
                value={photo.rank}
                onChange={(e) =>
                  onRankChange(photo.id, parseInt(e.target.value, 10))
                }
              >
                <option value={0}>Unranked</option>
                {[...Array(5)].map((_, i) => (
                  <option key={i + 1} value={i + 1}>
                    Rank {i + 1}
                  </option>
                ))}
              </RankDropdown>

            </PhotoCard>
          ))}
        </PhotoGrid>

        {/* Button to upload more photos */}
        <div>
          <label htmlFor="upload-more" className="upload-more-btn">Upload More Photos</label>
          <input
            id="upload-more"
            type="file"
            multiple
            name="photos"
            onChange={onUploadMore} // Use the handleFileChange for uploading and getting new URLs
            style={{ display: 'none' }} // Hide the input field
          />
        </div>
      </ModalContent>
    </ModalWrapper>
  );
};

export default PhotoPreviewModal;
