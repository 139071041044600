import styled from 'styled-components';
import { DefaultTheme } from 'styled-components'; // Import DefaultTheme

export const LoginWrapper = styled.div`
  display: flex;
  justify-content: center; /* Center the box horizontally */
  align-items: center; /* Center the box vertically */
  height: 100vh; /* Full height of the viewport */
  min-height: 100vh; /* Ensure at least full height */
  background-color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.background}; // Use global background color from theme
  padding: ${({ theme }: { theme: DefaultTheme }) => theme.spacing.p3}; /* Padding to adjust for small screens */

  /* Responsive styles */
  ${({ theme }: { theme: DefaultTheme }) => theme.media.large`
    padding-top: 300px; /* Reduce padding on small screens */
  `}

  /* Responsive styles */
  ${({ theme }: { theme: DefaultTheme }) => theme.media.medium`
    padding-top: 0px; /* Reduce padding on small screens */
  `}

  /* Responsive styles */
  ${({ theme }: { theme: DefaultTheme }) => theme.media.small`
    padding-top: 0px; /* Reduce padding on small screens */
  `}

  ${({ theme }: { theme: DefaultTheme }) => theme.media.extraSmall`
    padding-top: 0px; /* Further reduce padding on extra small screens */
  `}
`;

// Wrapper to contain both the user and agent login columns
export const ColumnsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.m4}; // Space between the two columns
  max-width: 1200px; // Limit the overall width
  width: 100%;
  justify-content: space-between;

  ${({ theme }) => theme.media.medium`
    flex-direction: column; // Stack the columns on smaller screens
    align-items: center;
  `}

  ${({ theme }) => theme.media.small`
    flex-direction: column; // Stack the columns on smaller screens
    align-items: center;
  `}

  ${({ theme }) => theme.media.extraSmall`
    flex-direction: column; // Stack the columns on smaller screens
    align-items: center;
  `}
`;

// Styles for each individual column (user login and agent login)
export const Column = styled.div`
  flex: 1; // Let each column take up equal space
  padding: ${({ theme }) => theme.spacing.p4};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.media.medium`
    width: 100%; // Full width on smaller screens
  `}

  ${({ theme }) => theme.media.small`
    flex-direction: column; // Stack the columns on smaller screens
    align-items: center;
  `}

  ${({ theme }) => theme.media.extraSmall`
    flex-direction: column; // Stack the columns on smaller screens
    align-items: center;
  `}
`;

// Divider styling
export const Divider = styled.div`
  width: 1px;
  background-color: #ddd;
  height: 500px;
  align-self: center;

  ${({ theme }) => theme.media.medium`
    height: 0px;
  `}

  ${({ theme }) => theme.media.small`
    height: 0px;
  `}

  ${({ theme }) => theme.media.extraSmall`
    height: 0px;
  `}
`;

// Section heading style
export const SectionHeading = styled.h2`
  font-size: ${({ theme }) => theme.typography.h2.fontSize};
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: ${({ theme }) => theme.spacing.m3};
`;

export const LoginBox = styled.div`
  background-color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.background};
  padding: ${({ theme }: { theme: DefaultTheme }) => theme.spacing.p4}; // Using global padding
  border-radius: ${({ theme }: { theme: DefaultTheme }) => theme.borderRadius.large}; // Use global border radius
  box-shadow: ${({ theme }: { theme: DefaultTheme }) => theme.boxShadow}; // Use global box-shadow
  max-width: 400px; /* Set maximum width */
  width: 100%; /* Ensure full width */
  text-align: center;
  transition: ${({ theme }: { theme: DefaultTheme }) => theme.transition}; /* Smooth transitions for layout changes */

  ${({ theme }: { theme: DefaultTheme }) => theme.media.medium`
    padding: ${theme.spacing.p3}; /* Adjust padding for medium screens */
    max-width: 350px; /* Reduce width on medium screens */
  `}

  ${({ theme }: { theme: DefaultTheme }) => theme.media.small`
    padding: ${theme.spacing.p2}; /* Adjust padding for small screens */
    max-width: 300px; /* Further reduce width on small screens */
  `}

  ${({ theme }: { theme: DefaultTheme }) => theme.media.extraSmall`
    padding: ${theme.spacing.p1}; /* Minimal padding on extra small screens */
    max-width: 90%; /* Take up more width on extra small screens */
  `}
`;

export const Logo = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.m4};

  img {
    height: 100px; /* Adjust the height of the logo */
    width: auto; /* Maintain aspect ratio */
  }

  ${({ theme }) => theme.media.medium`
    img {
      height: 100px; /* Adjust logo size on medium screens */
    }
  `}

  ${({ theme }) => theme.media.small`
    img {
      height: 80px; /* Adjust logo size on small screens */
    }
  `}

  ${({ theme }) => theme.media.extraSmall`
    margin-bottom: 1rem;
    img {
      height: 60px; /* Adjust logo size on small screens */
    }
  `}
`;

export const AdditionalLinks = styled.div`
  margin-top: ${({ theme }: { theme: DefaultTheme }) => theme.spacing.m3};
  text-align: center;

  a {
    margin: 0 ${({ theme }: { theme: DefaultTheme }) => theme.spacing.m1};
    color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.primary};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin-botom: ${({ theme }: { theme: DefaultTheme }) => theme.spacing.m2};
    font-size: ${({ theme }: { theme: DefaultTheme }) => theme.typography.body.fontSize};
  }

  /* Responsive styles */
  ${({ theme }: { theme: DefaultTheme }) => theme.media.small`
    p {
      font-size: ${theme.typography.small.fontSize};
    }
  `}

  ${({ theme }: { theme: DefaultTheme }) => theme.media.extraSmall`
    p {
      font-size: ${theme.typography.small.fontSize};
    }
  `}
`;
