import styled from 'styled-components';

export const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 250px; /* Adjust height as needed */
  margin: 0; /* Remove margin to fit the carousel snugly */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 8px 8px 0 0; /* Only keep top corners rounded to match the card */
  box-shadow: none; /* Remove shadow from the carousel itself */
`;

export const SlideContainer = styled.div<{ currentIndex: number }>`
  display: flex;
  transition: transform 0.5s ease;
  transform: ${({ currentIndex }) => `translateX(-${currentIndex * 100}%)`};
  width: 100%;
  height: 100%; /* Ensure the container takes the full height */
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image fills the entire space */
    object-position: center;
    border-radius: 0; /* Remove the internal border-radius to align with the card */
  }
`;

export const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: black;
  border: none;
  border-radius: 50%;
  padding: 0.75rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

  &.left {
    left: 10px;
    z-index: 1;
  }

  &.right {
    right: 10px;
    z-index: 1;
  }
`;
