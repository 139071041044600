import styled from 'styled-components';

export const BlogPostContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 50px;
  padding-top: 100px;

  ${({ theme }) => theme.media.medium`
    padding: 40px;
    padding-top: 200px;
  `}

  ${({ theme }) => theme.media.small`
    padding: 20px;
    padding-top: 100px;
  `}

  ${({ theme }) => theme.media.extraSmall`
    padding: 10px;
    padding-top: 100px;
  `}
`;

export const FeaturedImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 20px;
  margin-bottom: 20px;

  ${({ theme }) => theme.media.medium`
    margin-bottom: 15px;
  `}

  ${({ theme }) => theme.media.small`
    border-radius: 15px;
    margin-bottom: 10px;
  `}
`;

export const PostTitle = styled.h1`
  font-size: 1.8rem;
  margin-bottom: 10px;

  ${({ theme }) => theme.media.medium`
    font-size: 1.5rem;
    margin-bottom: 8px;
  `}

  ${({ theme }) => theme.media.small`
    font-size: 1.1rem;
    margin-bottom: 6px;
  `}

  ${({ theme }) => theme.media.extraSmall`
    font-size: 1.1rem;
    margin-bottom: 5px;
  `}
`;

export const PostMeta = styled.div`
  color: #6c757d;
  margin-bottom: 20px;

  span {
    margin-right: 10px;
  }

  ${({ theme }) => theme.media.medium`
    margin-bottom: 15px;
  `}

  ${({ theme }) => theme.media.small`
    margin-bottom: 10px;
    span {
      margin-right: 8px;
    }
  `}

  ${({ theme }) => theme.media.extraSmall`
    margin-bottom: 8px;
    span {
      margin-right: 5px;
    }
  `}
`;

export const PostContent = styled.div`
  font-size: 1rem;
  line-height: 1.6;

  ${({ theme }) => theme.media.medium`
    font-size: 0.95rem;
    line-height: 1.5;
  `}

  ${({ theme }) => theme.media.small`
    font-size: 0.9rem;
    line-height: 1.4;
  `}

  ${({ theme }) => theme.media.extraSmall`
    font-size: 0.85rem;
    line-height: 1.3;
  `}
`;

export const TagList = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;

  ${({ theme }) => theme.media.small`
    gap: 8px;
  `}

  ${({ theme }) => theme.media.extraSmall`
    gap: 5px;
  `}

  span {
    background-color: #f1f1f1;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9rem;

    ${({ theme }) => theme.media.small`
      padding: 4px 8px;
      font-size: 0.85rem;
    `}

    ${({ theme }) => theme.media.extraSmall`
      padding: 3px 7px;
      font-size: 0.8rem;
    `}
  }
`;

export const BackToBlogLink = styled.a`
  display: block;
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  ${({ theme }) => theme.media.medium`
    margin-top: 15px;
  `}

  ${({ theme }) => theme.media.small`
    margin-top: 12px;
  `}

  ${({ theme }) => theme.media.extraSmall`
    margin-top: 10px;
  `}
`;
