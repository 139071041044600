import React, { useState } from 'react';
import { signup } from '../../services/userService';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input } from '../../styles/common'; // Reuse common styles
import { SignupWrapper, SignupBox, Logo } from '../../styles/SignupPageStyles';

const SignupPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const logoImageUrl = "https://timeaz.blob.core.windows.net/media/LOGO_TIMEAZ.svg";

  const handleSignup = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await signup({ username, password, email, first_name: firstName, last_name: lastName, phone });
      console.log('Signup response:', response);
      navigate('/login'); // Redirect to login after successful signup
    } catch (err) {
      setError('Error during signup. Please check your details.');
    }
  };

  return (
    <SignupWrapper>
      <SignupBox>
        <Logo>
          <img src={logoImageUrl} alt="Logo" />
        </Logo>
        <Form onSubmit={handleSignup}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <Input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <Input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="first_name">First Name</label>
            <Input
              type="text"
              id="first_name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="last_name">Last Name</label>
            <Input
              type="text"
              id="last_name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <Input
              type="text"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <Input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className="error">{error}</p>}
          <Button type="submit">Sign Up</Button>
        </Form>
      </SignupBox>
    </SignupWrapper>
  );
};

export default SignupPage;
