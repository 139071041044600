// styles/BlogListPageStyles.ts
import styled from 'styled-components';

export const BlogListContainer = styled.div`
  padding-top: 100px; /* Adjust this value to match the UserTopBar's height */
  padding-left: ${({ theme }) => theme.spacing.m3};
  padding-right: ${({ theme }) => theme.spacing.m3};
  padding-bottom: ${({ theme }) => theme.spacing.m3};
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Default: 4 columns */
  gap: ${({ theme }) => theme.spacing.m3}; /* Space between grid items */
  justify-items: center;

  ${({ theme }) => theme.media.large`
    grid-template-columns: repeat(5, 1fr); /* 2 columns on large screens */
  `}

  ${({ theme }) => theme.media.extraMedium`
    grid-template-columns: repeat(3, 1fr); /* 2 columns on large screens */
  `}

  ${({ theme }) => theme.media.medium`
    grid-template-columns: repeat(3, 1fr); /* 2 columns on large screens */
  `}

  ${({ theme }) => theme.media.small`
    grid-template-columns: repeat(1, 1fr); /* 2 columns on large screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    grid-template-columns: repeat(1, 1fr); /* 2 columns on large screens */
  `}
`;
