import styled from 'styled-components';

// Modal Wrapper
export const ModalWrapper = styled.div`
  background: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  width: 600px;
  padding: ${({ theme }) => theme.spacing.p4};
  box-shadow: ${({ theme }) => theme.boxShadow};
  margin: ${({ theme }) => theme.spacing.m4} auto;
  overflow-y: auto; /* Enable scrolling within the modal */

  /* Responsive styles for medium and smaller screens */
  ${({ theme }) => theme.media.medium`
    width: 80%; /* Adjust width for medium screens */
    padding: ${theme.spacing.p3}; /* Decrease padding */
    max-height: 85vh; /* Slightly increase available height */
  `}

  ${({ theme }) => theme.media.small`
    width: 90%; /* Increase width for small screens */
    padding: ${theme.spacing.p2}; /* Further decrease padding */
    max-height: 80vh; /* Max height for small screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    width: 95%; /* Maximum width for extra small screens */
    padding: ${theme.spacing.p2};
    margin: ${theme.spacing.m2} auto; /* Decrease margin */
    max-height: 85vh; /* Decrease height further for very small screens */
  `}
`;

// Modal Header
export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.m3};

  h2 {
    font-size: ${({ theme }) => theme.typography.h1.fontSize};
    font-weight: ${({ theme }) => theme.typography.h1.fontWeight};
    color: ${({ theme }) => theme.colors.text};

    ${({ theme }) => theme.media.medium`
      font-size: ${theme.typography.h2.fontSize}; /* Decrease font size */
    `}

    ${({ theme }) => theme.media.small`
      font-size: ${theme.typography.small.fontSize}; /* Further decrease font size */
    `}

    ${({ theme }) => theme.media.extraSmall`
      font-size: ${theme.typography.small.fontSize}; /* Use body size for extra small screens */
    `}
  }
`;

// Modal Content
export const ModalContent = styled.div`
  background: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  padding: ${({ theme }) => theme.spacing.p4};
  width: 600px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  z-index: 10000;
  overflow-y: auto; /* Enable vertical scrolling within content */

  ${({ theme }) => theme.media.medium`
    width: 80%; /* Adjust width for medium screens */
    padding: ${theme.spacing.p3}; /* Decrease padding */
    max-height: 65vh; /* Max height for medium screens */
  `}

  ${({ theme }) => theme.media.small`
    width: 90%; /* Increase width for small screens */
    padding: ${theme.spacing.p2}; /* Further decrease padding */
    max-height: 95vh; /* Max height for small screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    width: 95%; /* Maximum width for extra small screens */
    padding: ${theme.spacing.p2};
    max-height: 95vh; /* Decrease height further */
  `}
`;

// Info Row
export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing.m3};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lineBorder};
  margin-bottom: ${({ theme }) => theme.spacing.m3};

  ${({ theme }) => theme.media.small`
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the left */
    gap: ${theme.spacing.m1}; /* Add gap between stacked items */
  `}

  ${({ theme }) => theme.media.extraSmall`
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.spacing.m1};
  `}
`;

// Info Label
export const InfoLabel = styled.span`
  font-weight: bold;
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  color: ${({ theme }) => theme.colors.text};
  width: 30%;

  ${({ theme }) => theme.media.medium`
    font-size: ${theme.typography.small.fontSize}; /* Decrease font size for medium screens */
  `}

  ${({ theme }) => theme.media.small`
    width: 100%; /* Full width on small screens */
    font-size: ${theme.typography.xSmall.fontSize}; /* Decrease font size further */
  `}
`;

// Info Value
export const InfoValue = styled.span`
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  color: ${({ theme }) => theme.colors.text};
  flex: 1;
  margin-left: ${({ theme }) => theme.spacing.m2};
  width: 100%;

  input {
    font-size: ${({ theme }) => theme.typography.body.fontSize};
    color: ${({ theme }) => theme.colors.text};
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: ${({ theme }) => theme.borderRadius.medium};
    padding: ${({ theme }) => theme.spacing.p2};
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background};
    box-shadow: ${({ theme }) => theme.boxShadow} inset;

    &:disabled {
      background-color: ${({ theme }) => theme.colors.secondaryLight};
      color: ${({ theme }) => theme.colors.textSecondary};
    }

    ${({ theme }) => theme.media.small`
      padding: ${theme.spacing.p1}; /* Decrease padding for small screens */
      font-size: ${theme.typography.small.fontSize}; /* Decrease font size */
    `}

    ${({ theme }) => theme.media.extraSmall`
      padding: ${theme.spacing.p1};
      font-size: ${theme.typography.xSmall.fontSize}; /* Use extra small font size */
    `}
  }
`;

// Edit Button
export const EditButton = styled.button`
  font-size: ${({ theme }) => theme.typography.small.fontSize};
  color: ${({ theme }) => theme.colors.primary};
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  ${({ theme }) => theme.media.extraSmall`
    font-size: ${theme.typography.xSmall.fontSize}; /* Decrease font size */
  `}
`;

// Save Button
export const SaveButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  border: none;
  padding: ${({ theme }) => theme.spacing.p2};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.body.fontSize};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }

  ${({ theme }) => theme.media.small`
    padding: ${theme.spacing.p1};
    font-size: ${theme.typography.small.fontSize}; /* Decrease font size */
  `}
`;

// Cancel Button
export const CancelButton = styled.button`
  background-color: ${({ theme }) => theme.colors.secondary};
  color: white;
  border: none;
  padding: ${({ theme }) => theme.spacing.p2};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.body.fontSize};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryDark};
  }

  ${({ theme }) => theme.media.small`
    padding: ${theme.spacing.p1};
    font-size: ${theme.typography.small.fontSize}; /* Decrease font size */
  `}
`;

// Modal Footer
export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: ${({ theme }) => theme.spacing.m3};

  button {
    margin-left: ${({ theme }) => theme.spacing.m2};
  }

  ${({ theme }) => theme.media.small`
    padding-top: ${theme.spacing.m2}; /* Decrease padding */
    button {
      margin-left: ${theme.spacing.m1}; /* Decrease margin */
    }
  `}
`;

// Modal Overlay - the backdrop
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

// Close Button
export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${({ theme }) => theme.media.small`
    font-size: 1.2rem; /* Decrease font size for small screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    font-size: 1rem; /* Use smaller font size for extra small screens */
  `}
`;
