import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const TopbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.p3};
  background-color: ${({ theme }) => theme.colors.background};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

// Updated Logo component to use an image instead of text
export const Logo = styled(Link)`
  margin-left: 2rem;
  
  img {
    height: 50px; /* Adjust the height of the logo */
    width: auto; /* Maintain aspect ratio */
  }

  &:hover {
    img {
      opacity: 0.8; /* Slight opacity change on hover */
    }
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
`;

export const NavItem = styled.div`
  margin-left: ${({ theme }) => theme.spacing.m3};

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.typography.body.fontSize};
    position: relative;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }

    &::after {
      content: '';
      position: absolute;
      width: 0%;
      height: 2px;
      left: 0;
      bottom: -2px; /* Position underline slightly below the text */
      background-color: ${({ theme }) => theme.colors.primary};
      transition: width 0.3s ease-in-out; /* Smooth transition effect */
    }

    &:hover::after {
      width: 100%; /* Underline expands on hover */
    }
  }
`;

export const LogoutButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  color: ${({ theme }) => theme.colors.text};
  margin-left: ${({ theme }) => theme.spacing.m3};
  transition: color 0.3s ease-in-out; /* Smooth transition for color */

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

// Add styles specifically for hover effects on the navigation menu items
export const NavItemHoverEffect = styled.div`
  a {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.primary};
      left: 50%;
      bottom: -4px;
      transition: all 0.3s ease;
    }

    &:hover:after {
      width: 100%;
      left: 0;
    }
  }
`;