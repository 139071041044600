import React from 'react';
import { agentLogout } from '../../services/agentService';
import { useNavigate } from 'react-router-dom';
import { ButtonStyled } from '../../styles/AgentDashboardStyles'; // Use a styled button for logout

const AgentLogoutButton: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await agentLogout();
      navigate('/'); // Redirect to login after successful logout
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <ButtonStyled onClick={handleLogout}>Logout</ButtonStyled> // Using styled button instead of NavLink
  );
};

export default AgentLogoutButton;
