import styled from 'styled-components';

// Modal wrapper that covers the entire screen
export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

// Content of the modal
export const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  max-width: 100%; /* Now the modal takes the full viewport width */
  width: 600px; /* Increase the fixed width */
  max-height: 80vh;
  overflow-y: auto;
  position: relative;

  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }

  .upload-more-btn {
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    margin-top: 2rem;
    font-size: 1rem;
  }

  .upload-more-btn:hover {
    background-color: white;
    color: ${({ theme }) => theme.colors.primary};
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;

// Close button at the top right of the modal
export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 2rem;
  color: black;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

// Grid layout for displaying photos
export const PhotoGrid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  margin-top: 1rem;
`;

// Individual photo card
export const PhotoCard = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  height: 150px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
`;

// Delete button for each photo
export const DeleteButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 0.85rem;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: darkred;
  }
`;


// Badge showing photo rank
export const RankBadge = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: 0.3rem 0.6rem;
  border-radius: 5px;
  font-size: 0.85rem;
  font-weight: bold;
`;

// Dropdown for changing the rank
export const RankDropdown = styled.select`
  position: absolute;
  bottom: 5px;
  left: 5px;
  padding: 0.4rem;
  font-size: 0.9rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;