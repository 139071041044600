import { css, Interpolation, DefaultTheme } from 'styled-components';

const Media = {
  // Extra small devices (mobile phones with max-width of 400px)
  extraSmall: (...args: Interpolation<DefaultTheme>[]) => css`
    @media (max-width: 420px) {
      ${css`${args}`};
    }
  `,
  small: (...args: Interpolation<DefaultTheme>[]) => css`
    @media (max-width: 600px) {
      ${css`${args}`};
    }
  `,
  medium: (...args: Interpolation<DefaultTheme>[]) => css`
    @media (max-width: 800px) {
      ${css`${args}`};
    }
  `,
  extraMedium: (...args: Interpolation<DefaultTheme>[]) => css`
    @media (max-width: 1025px) {
      ${css`${args}`};
    }
  `,
  large: (...args: Interpolation<DefaultTheme>[]) => css`
    @media (max-width: 1300px) {
      ${css`${args}`};
    }
  `,
  extraLarge: (...args: Interpolation<DefaultTheme>[]) => css`
    @media (max-width: 1920px) {
      ${css`${args}`};
    }
  `,
};

// Updated theme object with additional properties
export const theme = {
  colors: {
    primary: '#0A0708',
    primaryDark: '#5b5b5b',
    primaryLight: '#B1B1B1',
    secondary: '#444444',
    secondaryLight: '#F2F1F0',
    secondaryDark: '#747474',
    text: '#0A0708',
    textSecondary: '#666',
    background: '#fff',
    border: '#B1B1B1',
    lineBorder: '#eaeaea',
    danger: '#b62020',
  },
  spacing: {
    m0: '0',
    m1: '4px',
    m2: '8px',
    m3: '16px',
    m4: '32px',
    m5: '48px',
    p0: '1px',
    p1: '4px',
    p2: '8px',
    p3: '16px',
    p4: '32px',
    p5: '48px',
  },
  typography: {
    h1: {
      fontSize: '2rem',
      fontWeight: 'bold',
      color: '#0A0708',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: '#0A0708',
    },
    h3: {
      fontSize: '1.2rem',
      fontWeight: 'normal',
      color: '#0A0708',
    },
    body: {
      fontSize: '1rem',
      lineHeight: '1.5',
      color: '#0A0708',
    },
    link: {
      color: '#3498db',
      textDecoration: 'none',
    },
    small: {
      fontSize: '0.875rem', // Updated small size
      fontWeight: 'normal',
    },
    xSmall: {
      fontSize: '0.75rem', // New extra small size
      fontWeight: 'normal',
    },
    xxSmall: {
      fontSize: '0.625rem', // New double extra small size
      fontWeight: 'normal',
    },
  },
  borderRadius: {
    small: '4px',
    medium: '8px',
    large: '30px',
  },
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease-in-out',
  media: Media, // Add media queries to the theme
};
