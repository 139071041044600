import api from './api';

export interface BlogPost {
    id: number;
    title: string;
    slug: string;
    content: string;
    category: {
      id: number;
      name: string;
      slug: string;
    };
    tags: string[];
    featured_image: string;
    published: boolean;
    created_at: string;
    updated_at: string;
    reading_time: number;
    author: number;
  }

export interface BlogCategory {
    id: number;
    name: string;
    slug: string;
}
  

export const getBlogPosts = async (): Promise<BlogPost[]> => {
  const response = await api.get('/blog/posts/');
  return response.data.results;
};

export const getBlogPost = async (slug: string): Promise<BlogPost> => {
  const response = await api.get(`/blog/posts/slug/${slug}/`);
  return response.data;
};

export const getCategories = async (): Promise<BlogCategory[]> => {
  const response = await api.get('/blog/categories/');
  return response.data;
};
