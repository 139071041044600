import styled from 'styled-components';

// Main container for the listed properties page
export const SoldPropertiesContainer = styled.div`
  padding: 2rem;
  margin: 0rem auto;
  padding-bottom: 4rem;
`;

// Section for the headings with centered text
export const SoldPropertiesHeading = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: left;
`;

// Properties grid container for cards
export const PropertiesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

// Individual property card
export const PropertyCard = styled.div`
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  overflow: hidden; /* This ensures the carousel sticks to the edges */
  padding: 0; /* Remove the default padding to avoid gaps */
  margin: 0; /* Adjust margins if needed */
  overflow: hidden;
  position: relative;

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
`;

// Property details (like price, beds, baths, etc.)
export const PropertyDetails = styled.div`
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;

  .property-info {
    display: flex;
    justify-content: space-between; /* Ensures inline display */
    align-items: center;
    margin-top: 0.5rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary};
  }

  p {
    margin: 0.5rem 0;
    color: ${({ theme }) => theme.colors.primary};
    line-height: 1.4;
  }
`;

