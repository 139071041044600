// src/styles/CustomDropdownStyles.ts
import styled from 'styled-components';

export const DropdownWrapper = styled.div`
  position: relative;
  border-radius: 50px;
  display: inline-block;
  width: 100%;
  margin-bottom: 0rem;
  max-width: 250px; /* Adjust width of the dropdown */
`;

export const DropdownButton = styled.button<{ isOpen: boolean }>`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 50px;
  padding: 0.75rem 1.25rem;
  width: 100%;
  text-align: left;
  font-size: 1rem; /* Adjusted font size */
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  ${({ isOpen, theme }) =>
    isOpen &&
    `
    border-color: ${theme.colors.primary};
  `}
`;

export const DropdownMenu = styled.ul`
  position: absolute;
  border-radius: 25px;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.border};
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  /* Hide scrollbar for WebKit browsers */
  &::-webkit-scrollbar {
    width: 0; /* Set width to 0 to hide the scrollbar */
    height: 0;
  }

  /* Hide scrollbar for Internet Explorer, Edge */
  -ms-overflow-style: none; /* IE and Edge */
  
  /* Hide scrollbar for Firefox */
  scrollbar-width: none; /* Firefox */
`;


export const DropdownItem = styled.li`
  padding: 0.75rem 1.25rem;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem; /* Adjusted font size */
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  align-items: center;
  gap: 0.75rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryLight};
    color: black;
  }

  .location-icon {
    font-size: 1.2rem; /* Adjusted icon size */
    color: ${({ theme }) => theme.colors.primaryLight};
  }
`;

export const DropdownFlexContainer = styled.div`
  display: flex;
  flex-direction: row; /* Keep dropdowns in a row */
  justify-content: center;
  align-items: center;
  gap: 1rem; /* Add some space between the dropdowns */
  margin-bottom: 2rem; /* Add some margin at the bottom */
  margin-top: 2rem;
  width: 100%; /* Ensure container takes full width */
  max-width: 800px; /* Set a maximum width for the container */
  box-sizing: border-box;
`;
