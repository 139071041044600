import React from 'react';
import styled from 'styled-components';

// Styled components for the privacy policy page
const PrivacyPolicyContainer = styled.div`
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  box-shadow: ${({ theme }) => theme.boxShadow};
  line-height: 1.6;

  h1, h2, h3, h4 {
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 1rem;
  }

  p, li {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.typography.body.fontSize};
    margin-bottom: 0.5rem;
  }

  ul {
    padding-left: 20px;
    list-style-type: disc;
  }

  a {
    color: ${({ theme }) => theme.colors.link};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ${({ theme }) => theme.media.small`
    padding: ${theme.spacing.m2};
  `}

  ${({ theme }) => theme.media.extraSmall`
    padding: ${theme.spacing.m1};
  `}
`;

const PrivacyPolicyPage: React.FC = () => {
  return (
    <PrivacyPolicyContainer>
      <h1>Privacy Policy</h1>
      <p>Last updated: October 02, 2024</p>
      <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
      <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the <a href="https://www.privacypolicies.com/privacy-policy-generator/" target="_blank" rel="noopener noreferrer">Privacy Policy Generator</a>.</p>
      {/* Add the rest of the privacy policy content here */}
      <h2>Interpretation and Definitions</h2>
      <h3>Interpretation</h3>
      <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
      <h3>Definitions</h3>
      <p>For the purposes of this Privacy Policy:</p>
      <ul>
        <li><p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p></li>
        <li><p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p></li>
        <li><p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to TimeAZ.</p></li>
        <li><p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p></li>
        <li><p><strong>Country</strong> refers to: British Columbia, Canada</p></li>
        {/* Continue adding your privacy policy content here */}
      </ul>
      <h2>Collecting and Using Your Personal Data</h2>
      {/* Continue adding all sections from your privacy policy */}
      <p>If you have any questions about this Privacy Policy, You can contact us:</p>
      <ul>
        <li><p>By email: moein.kazemimk2016@gmail.com</p></li>
        <li><p>By visiting this page on our website: <a href="https://timeaz.com/" rel="noopener noreferrer" target="_blank">https://timeaz.com/contact</a></p></li>
      </ul>
    </PrivacyPolicyContainer>
  );
};

export default PrivacyPolicyPage;
