// src/styles/PropertyCreationStepThreeStyles.ts
import styled from 'styled-components';
import { MapContainer } from 'react-leaflet';
import { Button } from 'rsuite';

// Styled container for step three
export const StepThreeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the top */
  padding: 0rem 0; /* Adjusted padding */
  margin-top: 0; /* Remove the additional top margin */
`;

// Section Heading
export const StepThreeSectionHeading = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem; /* Adjusted margin */
  margin-top: 1rem; /* Reduced top margin */
`;

// Styled container for the map
export const MapWrapper = styled.div`
  display: flex;
  justify-content: center; /* Center the map horizontally */
  align-items: center;
  width: 50%; /* Take up full width of the parent */
  margin-top: 0rem; /* Adjust margin as necessary */
`;

// Styled map container for width control
export const StyledMapContainer = styled(MapContainer)`
  width: 80%; /* Set map width to 80% */
  height: 200px; /* Set height of the map */
  border: 1px solid ${({ theme }) => theme.colors.border}; /* Optional border for visibility */
  border-radius: 10px; /* Optional border-radius */
  z-index: 1;
`;

export const BlackButton = styled(Button)`
  background-color: black !important;
  border-color: black !important;
  color: white !important;

  &:hover,
  &:focus,
  &:active {
    background-color: #333 !important;
    border-color: #333 !important;
  }
`;
