import React, { useState, useMemo } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import {
  CarouselWrapper,
  ImageContainer,
  ArrowButton,
  SlideContainer,
} from '../../styles/PhotoCarouselStyles';
import { Photo } from '../../services/propertyService';

interface PhotoCarouselProps {
  photos: Photo[]; // Array of photos with rank
}

const PhotoCarousel: React.FC<PhotoCarouselProps> = ({ photos }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Use useMemo to sort photos efficiently
  const sortedPhotos = useMemo(() => {
    const rankedPhotos = photos.filter((photo) => photo.rank > 0); // Get only ranked photos
    const unrankedPhotos = photos.filter((photo) => photo.rank === 0); // Get unranked photos
    // Sort ranked photos by rank, and append unranked ones at the end
    return [...rankedPhotos.sort((a, b) => a.rank - b.rank), ...unrankedPhotos];
  }, [photos]);

  const handlePrevClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? sortedPhotos.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) =>
      prevIndex === sortedPhotos.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <CarouselWrapper>
      {sortedPhotos.length > 0 && (
        <>
          <ArrowButton className="left" onClick={handlePrevClick}>
            <FaArrowLeft />
          </ArrowButton>

          <SlideContainer currentIndex={currentIndex}>
            {sortedPhotos.map((photo) => (
              <ImageContainer key={photo.id}>
                <img
                  src={photo.image_url}
                  alt={`Photo with rank ${photo.rank || 'unranked'}`}
                />
              </ImageContainer>
            ))}
          </SlideContainer>

          <ArrowButton className="right" onClick={handleNextClick}>
            <FaArrowRight />
          </ArrowButton>
        </>
      )}
    </CarouselWrapper>
  );
};

export default PhotoCarousel;
