import styled from 'styled-components';

// Wrapper for the description step content
export const StepSevenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem 0;
`;

// Heading for Step 7
export const StepSevenHeading = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 5rem;
  text-align: center;
`;

// Description TextArea Styling
export const DescriptionTextArea = styled.textarea`
  width: 50%;
  max-width: 500px;
  height: 200px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  padding: 1rem;
  font-size: 1rem;
  font-family: inherit;
  resize: none; /* Prevent resizing */
  outline: none;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.background};

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.textSecondary};
  }
`;
