import React, { useEffect, useState }  from 'react';
import { BlogPost } from '../../services/blogService';
import { Link } from 'react-router-dom';
import {
  CardWrapper,
  Thumbnail,
  Title,
  Author,
  BlogInfo,
  PublishedDate,
  ReadingTime
} from '../../styles/BlogPostCardStyles';
import { getPublicAgentProfile, AgentProfile } from '../../services/agentService';

export interface BlogPostCardProps {
  post: BlogPost;
  isHomePage?: boolean;
}

const BlogPostCard: React.FC<BlogPostCardProps> = ({ post }) => {
    const [authorName, setAuthorName] = useState<string | null>(null);
  
    useEffect(() => {
      const fetchAuthorName = async () => {
        try {
          const agentProfile: AgentProfile = await getPublicAgentProfile(post.author);
          setAuthorName(agentProfile.preferred_name);
        } catch (error) {
          console.error('Error fetching agent profile:', error);
          setAuthorName('Unknown Author');
        }
      };
  
      fetchAuthorName();
    }, [post.author]);
  
    const formattedDate = new Date(post.created_at).toLocaleDateString();

    return (
      <CardWrapper>
        <Link to={`/blogs/${post.slug}`}>
          <Thumbnail src={post.featured_image} alt={post.title} />
          <Title>{post.title}</Title>
            <BlogInfo>
                <Author>By {authorName}</Author>
                <PublishedDate>{formattedDate}</PublishedDate>
                <ReadingTime>{post.reading_time} min read</ReadingTime>
            </BlogInfo>
        </Link>
      </CardWrapper>
    );
  };
  
  export default BlogPostCard;
