import React, { useState, useEffect } from 'react';
import { agentSignup, getCompanies, Company } from '../../services/agentService';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Select } from '../../styles/common';
import { SignupBox, SignupWrapper, SectionTitle, TwoColumnRow, FileUploadWrapper, BackArrow, Logo } from '../../styles/AgentSignupPageStyles';
import LoadingSpinner from '../../components/common/LoadingSpinner';

const AgentSignupPage: React.FC = () => {
  const [legalName, setLegalName] = useState('');
  const [preferredName, setPreferredName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [company, setCompany] = useState<number | null>(null);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [spokenLanguage, setSpokenLanguage] = useState('');
  const [password, setPassword] = useState('');
  const [photo, setPhoto] = useState<File | null>(null);
  const [photoId, setPhotoId] = useState<File | null>(null);
  const [error, setError] = useState('');
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const logoImageUrl = "https://timeaz.blob.core.windows.net/media/LOGO_TIMEAZ.svg";  

  useEffect(() => {
    const fetchCompanies = async () => {
      setIsLoading(true);
      try {
        const companyList = await getCompanies();
        setCompanies(companyList);
      } catch (error) {
        console.error('Error fetching companies:', error);
        setError('Error fetching companies');
      } finally {
        setIsLoading(false); // End loading when companies are fetched
      }
    };
    fetchCompanies();
  }, []);

  const handleNextStep = () => setStep(step + 1);
  const handleGoBack = () => setStep(step - 1);

  const handleSignup = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (company === null || !photo || !photoId) {
        setError('Please fill in all required fields');
        setIsLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append('legal_name', legalName);
      formData.append('preferred_name', preferredName);
      formData.append('email', email);
      formData.append('phone_number', phoneNumber);
      formData.append('company', company.toString());
      formData.append('spoken_language', spokenLanguage);
      formData.append('password', password);
      formData.append('photo', photo);
      formData.append('photo_id', photoId);

      await agentSignup(formData);
      setIsLoading(true);
      navigate('/agent-login');
    } catch (err) {
      setError('Error during signup. Please check your details.');
    } finally {
      setIsLoading(false);  // End loading when fetching is done
    }
  };

  return (
    <>
    {isLoading && <LoadingSpinner />}
    <SignupWrapper>
      <SignupBox>
        <Logo>
          <img src={logoImageUrl} alt="Logo" />
        </Logo>
        {step === 2 && <BackArrow onClick={handleGoBack} />}
        {step === 1 && (
          <>
            <SectionTitle>Personal Information</SectionTitle>
            <Form>
              <TwoColumnRow>
                <div className="form-group">
                  <label htmlFor="legal_name">Legal Name</label>
                  <Input
                    type="text"
                    id="legal_name"
                    value={legalName}
                    onChange={(e) => setLegalName(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="preferred_name">Preferred Name</label>
                  <Input
                    type="text"
                    id="preferred_name"
                    value={preferredName}
                    onChange={(e) => setPreferredName(e.target.value)}
                    required
                  />
                </div>
              </TwoColumnRow>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone_number">Phone Number</label>
                <Input
                  type="text"
                  id="phone_number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>

              <Button onClick={handleNextStep}>Next</Button>
            </Form>
          </>
        )}

        {step === 2 && (
          <>
            <SectionTitle>Account Information</SectionTitle>
            <Form onSubmit={handleSignup}>
              <div className="form-group">
                <label htmlFor="company">Company</label>
                <Select
                  id="company"
                  value={company || ''}
                  onChange={(e) => setCompany(parseInt(e.target.value))}
                  required
                >
                  <option value="" disabled>Select your company</option>
                  {companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="form-group">
                <label htmlFor="spoken_language">Spoken Language</label>
                <Input
                  type="text"
                  id="spoken_language"
                  value={spokenLanguage}
                  onChange={(e) => setSpokenLanguage(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>

              <FileUploadWrapper>
                <div className="form-group">
                  <label htmlFor="photo">Upload Photo</label>
                  <Input
                    type="file"
                    id="photo"
                    onChange={(e) => setPhoto(e.target.files ? e.target.files[0] : null)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="photo_id">Upload Photo ID</label>
                  <Input
                    type="file"
                    id="photo_id"
                    onChange={(e) => setPhotoId(e.target.files ? e.target.files[0] : null)}
                    required
                  />
                </div>
              </FileUploadWrapper>

              {error && <p className="error">{error}</p>}
              <Button type="submit">Sign Up</Button>
            </Form>
          </>
        )}
      </SignupBox>
    </SignupWrapper>
    </>
  );
};

export default AgentSignupPage;
