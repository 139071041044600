import styled from 'styled-components';

export const ProfileWrapper = styled.div`
  display: grid;
  padding-top: 70px; 
  grid-template-columns: 1fr 2fr; /* Two columns: 1/3rd for profile and confirmed info, 2/3rd for about + review */
  grid-column-gap: ${({ theme }) => theme.spacing.m5};
  grid-row-gap: ${({ theme }) => theme.spacing.m3};
  margin: ${({ theme }) => theme.spacing.m4};
  align-items: start; /* Aligns all child elements to the top */

  ${({ theme }) => theme.media.medium`
    grid-template-columns: 1fr; /* Single column layout on medium screens */
    margin: ${theme.spacing.m3};
    grid-row-gap: ${theme.spacing.m3};
  `}

  ${({ theme }) => theme.media.small`
    display: flex;
    flex-direction: column; /* Convert to a single-column layout */
    margin: ${theme.spacing.m2};
    grid-column-gap: 0; /* Remove column gaps */
    grid-row-gap: ${theme.spacing.m2};
  `}

  ${({ theme }) => theme.media.extraSmall`
    display: flex;
    flex-direction: column; /* Convert to a single-column layout */
    margin: ${theme.spacing.m1};
    grid-column-gap: 0; /* Remove column gaps */
    grid-row-gap: ${theme.spacing.m1}; /* Further reduce row gap */
  `}
`;

// Container for the write review section
export const WriteReviewContainer = styled.div`
  position: sticky;
  top: 1rem; /* Adjust this value based on your needs */
  align-self: start; /* Aligns the container to the start of the column */
  padding: ${({ theme }) => theme.spacing.p4};
  margin-bottom: ${({ theme }) => theme.spacing.m4};
  max-width: 90%;
  z-index: 10; /* Ensures it stays above other content */

  h2 {
    font-size: ${({ theme }) => theme.typography.h2.fontSize};
    margin-bottom: ${({ theme }) => theme.spacing.m2};
    color: ${({ theme }) => theme.colors.text};
  }

  ${({ theme }) => theme.media.small`
    max-width: 100%;
    top: 0; /* Adjust the top position */
    z-index: 5; /* Lower z-index for mobile to avoid overlaps */
  `}

  ${({ theme }) => theme.media.extraSmall`
    max-width: 100%;
    top: 0; /* Adjust the top position */
    z-index: 5; /* Lower z-index for mobile to avoid overlaps */
  `}
`;

// Review form styles
export const ReviewForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align the form contents */
  width: 100%;

  label {
    font-size: ${({ theme }) => theme.typography.body.fontSize};
    margin-bottom: ${({ theme }) => theme.spacing.m1};
    align-self: flex-start; /* Align labels to the left */
  }

  textarea {
    width: 100%;
    padding: ${({ theme }) => theme.spacing.p2};
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    margin-bottom: ${({ theme }) => theme.spacing.m3};
    font-size: ${({ theme }) => theme.typography.body.fontSize};
    resize: vertical;

    &:focus {
      border-color: ${({ theme }) => theme.colors.primary};
      outline: none;
    }
  }
`;

// Wrapper for review categories and ratings
export const ReviewCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column; /* Stack categories vertically */
  gap: ${({ theme }) => theme.spacing.m3};
  margin-bottom: ${({ theme }) => theme.spacing.m4};
  width: 100%; /* Full width to ensure consistent layout */

  .rating-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%; /* Full width for each item */

    .category-label {
      font-size: ${({ theme }) => theme.typography.body.fontSize};
      margin-bottom: ${({ theme }) => theme.spacing.m1};
      color: ${({ theme }) => theme.colors.text};
      width: 100%; /* Take full width */
    }

    .rating-buttons {
      display: flex;
      gap: 0.5rem; /* Space between number buttons */
      margin-top: ${({ theme }) => theme.spacing.m1};
      width: 100%;
    }
  }

  ${({ theme }) => theme.media.small`
    max-width: 100%; /* Ensure it takes full screen width */
    padding: 0 0.5rem; /* Adjust padding */
    align-items: center; /* Center align on small screens */
    
    .rating-item {
      align-items: center; /* Center align each rating item */
      .category-label {
        text-align: center; /* Center align labels */
      }
      .rating-buttons {
        justify-content: center; /* Center align buttons */
      }
    }
  `}

  ${({ theme }) => theme.media.extraSmall`
    max-width: 100%; /* Ensure it takes full screen width */
    align-items: center; /* Center align on small screens */
    
    .rating-item {
      align-items: center; /* Center align each rating item */
      .category-label {
        text-align: center; /* Center align labels */
      }
      .rating-buttons {
        justify-content: center; /* Center align buttons */
      }
    }
  `}
`;

// Styled button for the rating number (1-10)
export const RatingButton = styled.button<{ selected?: boolean }>`
  width: 35px;
  height: 35px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  background-color: ${({ selected, theme }) => (selected ? theme.colors.primary : theme.colors.background)};
  color: ${({ selected, theme }) => (selected ? 'white' : theme.colors.text)};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
  }

  &:focus {
    outline: none;
  }

  /* Responsive adjustments for small screens */
  ${({ theme }) => theme.media.small`
    width: 30px;
    height: 30px;
    font-size: ${theme.typography.small.fontSize};
  `}

  ${({ theme }) => theme.media.extraSmall`
    width: 25px;
    height: 25px;
    font-size: ${theme.typography.small.fontSize};
  `}
`;

// Submit button styles
export const SubmitButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: ${({ theme }) => theme.spacing.p2} ${({ theme }) => theme.spacing.p4};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

// Error message styling
export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.danger};
  font-size: ${({ theme }) => theme.typography.small.fontSize};
  margin-bottom: ${({ theme }) => theme.spacing.m2};
`;
