import React, { useState, useEffect } from 'react';
import { login } from '../../services/userService'; // Import both login functions
import { agentLogin } from '../../services/agentService';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Form, Input, LinkStyled } from '../../styles/common';
import { LoginWrapper, LoginBox, Logo, ColumnsWrapper, Column, SectionHeading, Divider } from '../../styles/LoginPageStyles';
import GoogleLoginInitiate from '../../components/common/GoogleLoginInitiate';
import UserTopBar from '../../components/common/UserTopBar';

interface LocationState {
  from?: string;
}

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [userError, setUserError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const logoImageUrl = 'https://timeaz.blob.core.windows.net/media/LOGO_TIMEAZ.svg';

  // Check if the user is coming through the agent review URL
  const searchParams = new URLSearchParams(location.search);
  const agentId = searchParams.get('agent_id');

  const state = location.state as LocationState;

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      const redirectTo = state?.from || '/';
      navigate(redirectTo, { replace: true });
    }
  }, [navigate, state]);

  // User login handler
  const handleUserLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await login({ username, password });
      // After successful login, check if the user is supposed to be redirected to the agent profile
      if (agentId) {
        navigate(`/agent-profile/${agentId}`);
      } else {
        navigate('/');
      }
    } catch (err) {
      setUserError('Invalid login credentials. Please try again.');
    }
  };

  return (
    <>
    <UserTopBar />
    <LoginWrapper>
      <LoginBox>
        <Logo>
          <img src={logoImageUrl} alt="Logo" />
        </Logo>
        <Form onSubmit={handleUserLogin}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <Input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <Input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {userError && <p className="error">{userError}</p>}
          <Button type="submit">Login</Button>
        </Form>

        <GoogleLoginInitiate />

        <div style={{ marginTop: '20px' }}>
          <LinkStyled to="/signup">Sign up</LinkStyled>
          <LinkStyled to="/forgot-password">Forgot Password?</LinkStyled>
        </div>
      </LoginBox>
    </LoginWrapper>
    </>
  );
};

export default LoginPage;
