import styled from 'styled-components';

export const CarouselWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%; /* Ensures the carousel wrapper takes full width */
`;

export const SlideContainer = styled.div<{ currentIndex: number; visiblePosts: number }>`
  display: flex;
  transition: transform 0.6s ease-in-out;
  transform: ${({ currentIndex, visiblePosts }) => `translateX(-${(currentIndex / visiblePosts) * 100}%)`};
  width: ${({ visiblePosts }) => `${(visiblePosts * 100)}%`}; /* Ensure full width for visible posts */
  align-items: stretch; /* Ensure all blog posts stretch to the same height */
`;

export const PropertyWrapper = styled.div<{ visiblePosts: number }>`
  flex: 0 0 calc(100% / ${({ visiblePosts }) => visiblePosts}); /* Each post takes equal width */
  padding: ${({ theme }) => theme.spacing.p2}; /* Add spacing inside each post */
  padding-bottom: ${({ theme }) => theme.spacing.p4};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 100%; /* Ensure height is automatic, but posts are aligned */
`;

export const ArrowButton = styled.button`
  background-color: ${({ theme }) => theme.colors.background};
  color: black;
  border-radius: 50%;
  padding: 0.75rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  z-index: 1000;
  position: absolute; /* Positioned relative to CarouselWrapper */
  bottom: -30px; /* Position near the bottom */
  left: 50%; /* Center horizontally relative to CarouselWrapper */
  transform: translateX(-50%); /* Center it horizontally by default */
  display: flex;
  align-items: center;
  justify-content: center;

  &.left {
    transform: translateX(calc(-50% - 25px)); /* Shift to the left by 50px */
  }

  &.right {
    transform: translateX(calc(-50% + 25px)); /* Shift to the right by 50px */
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

