// src/components/common/CustomDropdown.tsx
import React, { useState, useEffect, useRef } from 'react';
import { FaChevronDown, FaMapMarkerAlt } from 'react-icons/fa';
import {
  DropdownWrapper,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
} from '../../styles/CustomDropdownStyles';

interface Option {
  id: number;
  name: string;
}

interface CustomDropdownProps {
  placeholder: string;
  name: string;
  options: Option[];
  value: number | null | undefined;
  onChange: (event: { target: { name: string; value: number } }) => void;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  placeholder,
  name,
  options,
  value,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const selectedOption = options.find((option) => option.id === value);

  const handleOptionClick = (id: number) => {
    onChange({ target: { name, value: id } });
    setIsOpen(false);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownWrapper ref={dropdownRef}>
      <DropdownButton
        onClick={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        {selectedOption ? selectedOption.name : placeholder}
        <FaChevronDown />
      </DropdownButton>
      {isOpen && (
        <DropdownMenu role="listbox">
          {options.map((option) => (
            <DropdownItem
              key={option.id}
              onClick={() => handleOptionClick(option.id)}
              role="option"
              aria-selected={selectedOption?.id === option.id}
            >
              <FaMapMarkerAlt className="location-icon" />
              {option.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownWrapper>
  );
};

export default CustomDropdown;
