import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface BlogPostWrapperProps {
  visiblePosts: number;
}

export const CarouselWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%; /* Ensures the carousel wrapper takes full width */
`;

export const SlideContainer = styled.div<{ currentIndex: number; visiblePosts: number }>`
  display: flex;
  transition: transform 0.6s ease-in-out;
  transform: ${({ currentIndex, visiblePosts }) => `translateX(-${(currentIndex / visiblePosts) * 100}%)`};
  width: ${({ visiblePosts }) => `${(visiblePosts * 100)}%`}; /* Ensure full width for visible posts */
  align-items: stretch; /* Ensure all blog posts stretch to the same height */
`;

export const BlogPostWrapper = styled.div<BlogPostWrapperProps>`
  flex: 0 0 calc(100% / ${({ visiblePosts }) => visiblePosts}); /* Each post takes equal width */
  padding: ${({ theme }) => theme.spacing.p3}; /* Add spacing inside each post */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 100%; /* Ensure height is automatic, but posts are aligned */
`;

export const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.4);
  color: black;
  border: none;
  border-radius: 50%;
  padding: 0.75rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgb(255, 255, 255);
  }

  &.left {
    left: 0px;
    z-index: 1000;
  }

  &.right {
    right: 0px;
    z-index: 1000;
  }
`;

export const BlogHeading = styled.h2`
  font-size: ${({ theme }) => theme.typography.h2.fontSize};
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  margin-bottom: ${({ theme }) => theme.spacing.m2};
  
  ${({ theme }) => theme.media.small`
    font-size: 1rem;
  `}
`;

export const BlogSection = styled.section`
  width: 100%;
  margin: ${({ theme }) => theme.spacing.m5} 0;
  margin-top: ${({ theme }) => theme.spacing.m5};
  position: relative;
`;

export const SeeAllLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  font-size: ${({ theme }) => theme.typography.h3.fontSize};
  
  ${({ theme }) => theme.media.small`
    font-size: 0.8rem;
  `}
`;
