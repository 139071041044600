// src/components/auth/GoogleLoginCallback.tsx

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../common/LoadingSpinner';
import axios from 'axios';
import { storeTokens } from '../../services/userService';

const GoogleLoginCallback: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleGoogleCallback = async () => {
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get('code');

      if (code) {
        try {
          // Send the authorization code to the backend to exchange for tokens
          const response = await axios.get(`https://backend.timeaz.com/api/users/google/callback/?code=${code}`);

          if (response.status === 200 && response.data) {
            // Store the JWT tokens
            const { access, refresh } = response.data;
            storeTokens(access, refresh);

            // Redirect the user to the homepage or desired page
            navigate('/');
          } else {
            console.error('Failed to obtain tokens from backend');
          }
        } catch (error) {
          console.error('Error during Google callback handling:', error);
        }
      } else {
        console.error('No authorization code found in URL');
      }
    };

    handleGoogleCallback();
  }, [location, navigate]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <LoadingSpinner />
    </div>
  );
};

export default GoogleLoginCallback;
