// src/services/agentService.ts
import api from './api';
export interface Company {
  id: number;
  name: string;
}

export interface AgentLoginData {
  email: string;
  password: string;
}

export interface AgentSignupData {
  legal_name: string;
  preferred_name: string;
  email: string;
  phone_number: string;
  company: number;
  spoken_language: string;
  password: string;
  photo: File;
  photo_id: File;
}

export interface TokenResponse {
  access: string;
  refresh: string;
}

// Define the type for AgentPersonalInfo
export interface AgentPersonalInfo {
  legal_name: string;
  preferred_name: string;
  email: string;
  phone_number: string;
  company_name: string;
  company_address: string;
}

export interface AgentProfile {
  id: number;
  preferred_name: string;
  photo: string; // URL of the agent's photo
  years_on_platform: number;
  months_on_platform: number;
  spoken_language: string;
  about: string;
}

export interface Agent {
  id: number;
  legal_name: string;
  preferred_name: string;
  phone_number: string;
  company: number;
  photo: string;
  spoken_language: string;
  about: string;
}

export interface TopAgent {
  id: number;
  overallScore: number | null;
  review_count: number | null;
  preferred_name: string;
  photo: string;
  rank: number;
}

export interface ReviewCategory {
  id: number;
  name: string;
}

export interface ReviewRating {
  category: ReviewCategory;
  score: number;
}

export interface Review {
  id: number;
  user_id: number;
  username: string;
  text_review: string;
  created_at: string;
}

export interface AgentReviewSummary {
  agent: number;
  overall_average_rating: number;
  average_ratings: {
    [category: string]: number;
  };
  review_count: number;
  reviews: Review[];
}

export interface ReviewData {
  agent: number;
  text_review: string;
  ratings: {
    category: { id: number; name: string };
    score: number;
  }[];
}

export interface MessageInbox {
  id: number;
  full_name: string;
  contact_info: string;
  property: number;
  message: string;
  is_read: boolean;
}

export const getTopAgents = async (): Promise<TopAgent[]> => {
  const response = await api.get('/agents/top-agents/');
  return response.data.map((item: any) => ({
    id: item.agent.id,
    preferred_name: item.agent.preferred_name,
    photo: item.agent.photo,
    rank: item.rank,
  }));
};

export const getAgents = async (): Promise<Agent[]> => {
  const response = await api.get('/agents/list/');
  return response.data;
};

export const getAgentReviewSummary = async (agentId: number): Promise<AgentReviewSummary> => {
  const response = await api.get(`/reviews/agent-review-summary/${agentId}/`);
  return response.data;
};

export const getReviewCategories = async (): Promise<ReviewCategory[]> => {
  const response = await api.get('/reviews/review-categories/');
  return response.data;
};

export const submitReview = async (reviewData: ReviewData) => {
  const token = localStorage.getItem('access_token');
  const response = await api.post(
    '/reviews/reviews/',
    reviewData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// Fetch the list of companies
export const getCompanies = async (): Promise<Company[]> => {
  const response = await api.get('/agents/companies/');
  return response.data;
};

// Login function to obtain access and refresh tokens for agents
export const agentLogin = async (data: AgentLoginData): Promise<TokenResponse> => {
  const response = await api.post('/agents/login/', data);
  const tokens: TokenResponse = response.data;

  if (!tokens || !tokens.access || !tokens.refresh) {
    throw new Error('Invalid login response');
  }

  // Store the tokens in localStorage
  localStorage.setItem('access_token', tokens.access);
  localStorage.setItem('refresh_token', tokens.refresh);

  return tokens;
};

// Logout function for agents
export const agentLogout = async (): Promise<void> => {
  const accessToken = localStorage.getItem('access_token');
  const refreshToken = localStorage.getItem('refresh_token');

  if (!accessToken || !refreshToken) {
    throw new Error('No tokens available for logout');
  }

  try {
    await api.post(
      '/agents/logout/',
      { refresh: refreshToken },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    // Clear tokens from localStorage after a successful logout
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  } catch (error) {
    console.error('Logout error:', error);
    throw error;
  }
};

// Signup function for agents
export const agentSignup = async (formData: FormData): Promise<void> => {
  await api.post('/agents/signup/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Necessary for file uploads
    },
  });
};

// Function to get the agent's personal information
export const getPersonalInfo = async (): Promise<AgentPersonalInfo> => {
  const token = localStorage.getItem('access_token');
  const response = await api.get('/agents/personal-info/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Function to update the agent's personal information
export const updatePersonalInfo = async (data: Partial<AgentPersonalInfo>): Promise<void> => {
  const token = localStorage.getItem('access_token');
  await api.put('/agents/personal-info/', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Function to partially update the agent's personal information
export const partialUpdatePersonalInfo = async (data: Partial<AgentPersonalInfo>): Promise<void> => {
  const token = localStorage.getItem('access_token');
  await api.patch('/agents/personal-info/', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Function to get the agent's profile
export const getAgentProfile = async (): Promise<AgentProfile> => {
  const token = localStorage.getItem('access_token');
  const response = await api.get('/agents/profile/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Function to get the agent's profile
export const getPublicAgentProfile = async (agentId : number): Promise<AgentProfile> => {
  const response = await api.get(`/agents/public-profile/${agentId}/`);
  return response.data;
};

// Function to update the agent's profile (full update)
export const updateAgentProfile = async (data: FormData): Promise<void> => {
  const token = localStorage.getItem('access_token');
  await api.patch('/agents/profile/', data, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data', // Required for file uploads
    },
  });
};

// Function to partially update the agent's profile
export const partialUpdateAgentProfile = async (data: Partial<AgentProfile>): Promise<void> => {
  const token = localStorage.getItem('access_token');
  await api.patch('/agents/profile/', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const isAuthenticated = (): boolean => {
  const accessToken = localStorage.getItem('access_token');
  return !!accessToken;
};

export const isAgentAuthenticated = (): boolean => {
  const accessToken = localStorage.getItem('access_token');
  return !!accessToken; // You could add more checks here if necessary
};

export const getNewAccessToken = async (): Promise<void> => {
  const refreshToken = localStorage.getItem('refresh_token');
  if (!refreshToken) {
    throw new Error('No refresh token available');
  }

  try {
    const response = await api.post('/auth/refresh/', { refresh: refreshToken });
    const { access } = response.data;

    localStorage.setItem('access_token', access);
  } catch (error) {
    console.error('Error refreshing token:', error);
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    throw new Error('Unable to refresh token');
  }
};

export const getInboxMessages = async (): Promise<MessageInbox[]> => {
  const token = localStorage.getItem('access_token');
  const response = await api.get('/messaging/inbox/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Fetch unread message count
export const getUnreadMessageCount = async (): Promise<{ unread_messages: number }> => {
  const token = localStorage.getItem('access_token');
  const response = await api.get('/messaging/unread-count/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Mark a message as read
export const markMessageAsRead = async (messageId: number): Promise<void> => {
  const token = localStorage.getItem('access_token');
  await api.patch(`/messaging/${messageId}/mark-read/`, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};