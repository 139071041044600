import styled from 'styled-components';

export const PhotoUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  height: 300px;
  margin: 0 auto;
  position: relative;
  background-color: ${({ theme }) => theme.colors.backgroundLight};

  .upload-icon {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  .add-photo-btn {
    background: none;
    border: 1px solid ${({ theme }) => theme.colors.border};
    padding: 0.5rem 1rem;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
      color: white;
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }

  input[type='file'] {
    display: none;
  }
`;

export const StepFiveHeading = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
`;

// Container for the photo upload section
export const StepFiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  height: 100%;
`;

// Styled Preview Photos button
export const PreviewPhotosButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 2rem;

  &:hover {
    background-color: white;
    color: ${({ theme }) => theme.colors.primary};
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.textSecondary};
    cursor: not-allowed;
  }
`;

// New styled components for the full-screen overlay and progress bar

// Full-screen overlay with blurred background
export const UploadProgressOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px); /* Blurs the background */
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of other elements */
`;

// Container for the progress bar and label
export const ProgressBarContainer = styled.div`
  width: 80%;
  max-width: 400px;
  padding: 2rem;
  background-color: white;
  border-radius: 12px;
  text-align: center;
`;

// Label above the progress bar
export const ProgressBarLabel = styled.p`
  margin-bottom: 1rem;
  font-size: 1.2rem;
`;

// Adjusted StyledProgressBar to accept props
export const StyledProgressBar = styled.div<{ progress: number }>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  overflow: hidden;

  .progress {
    height: 20px;
    background-color: ${({ theme }) => theme.colors.primary};
    width: ${({ progress }) => progress}%;
    transition: width 0.3s ease;
  }
`;

