// src/styles/SignupPageStyles.ts
import styled from 'styled-components';
import { DefaultTheme } from 'styled-components';

export const SignupWrapper = styled.div`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Full height of the viewport */
  min-height: 100vh; /* Ensure at least full height */
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => theme.spacing.p3}; /* Padding to adjust for small screens */

  /* Responsive styles */
  ${({ theme }) => theme.media.small`
    padding: ${theme.spacing.p2}; /* Reduce padding on small screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    padding: ${theme.spacing.p0}; /* Further reduce padding on extra small screens */
  `}
`;

export const SignupBox = styled.div`
  background-color: white;
  padding: ${({ theme }) => theme.spacing.p4};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  box-shadow: ${({ theme }) => theme.boxShadow};
  max-width: 400px; /* Set maximum width */
  width: 100%; /* Ensure full width */
  text-align: center;
  margin: auto; /* Center the box within the wrapper */
  transition: ${({ theme }) => theme.transition}; /* Smooth transitions for layout changes */

  /* Responsive styles */
  ${({ theme }) => theme.media.medium`
    padding: ${theme.spacing.p3};
    max-width: 350px; /* Adjust width for medium screens */
  `}

  ${({ theme }) => theme.media.small`
    padding: ${theme.spacing.p2};
    max-width: 90%; /* Adjust width for small screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    padding: ${theme.spacing.p1};
    max-width: 90%; /* Adjust width for extra small screens */
  `}
`;

export const Logo = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.m4};

  img {
    height: 100px; /* Adjust the height of the logo */
    width: auto; /* Maintain aspect ratio */
  }

  ${({ theme }) => theme.media.medium`
    img {
      height: 100px; /* Adjust logo size on medium screens */
    }
  `}

  ${({ theme }) => theme.media.small`
    img {
      height: 80px; /* Adjust logo size on small screens */
    }
  `}

  ${({ theme }) => theme.media.extraSmall`
    margin-bottom: 1rem;
    img {
      height: 60px; /* Adjust logo size on small screens */
    }
  `}
`;