import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { isAgentAuthenticated, isAuthenticated, getNewAccessToken } from '../../services/agentService';

interface AuthHOCProps {
  WrappedComponent: React.ComponentType;
  role: 'user' | 'agent';
}

const AuthHOC: React.FC<AuthHOCProps> = ({ WrappedComponent, role }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAuthentication = async () => {
      let authenticated = false;

      if (role === 'user') {
        authenticated = isAuthenticated();
      } else if (role === 'agent') {
        authenticated = isAgentAuthenticated();
      }

      // If user is not authenticated, try refreshing the token
      if (!authenticated) {
        try {
          await getNewAccessToken();
          authenticated = true;
        } catch (error) {
          authenticated = false;
        }
      }

      // If authenticated, show the component; if not, redirect to login
      if (authenticated) {
        setLoading(false);
      } else {
        localStorage.setItem('redirectAfterLogin', location.pathname + location.search);
        navigate(role === 'user' ? '/user-login' : '/agent-login');
      }
    };

    checkAuthentication();
  }, [navigate, role, location]);

  if (loading) {
    return <div>Loading...</div>; // Show a loading state while checking authentication
  }

  return <WrappedComponent />;
};

export default AuthHOC;
