// Styled Components
import styled from 'styled-components';

interface CardWrapperProps {
  isHomePage?: boolean;
}

export const CardWrapper = styled.div<CardWrapperProps>`
  max-width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  padding: ${({ theme }) => theme.spacing.p2};
  margin: ${({ theme }) => theme.spacing.m2};
  background-color: ${({ theme, isHomePage }) =>
    isHomePage ? theme.colors.background : '#F5F5F5'};
  transition: transform 0.3s ease;
  cursor: pointer;

  ${({ isHomePage, theme }) =>
    isHomePage
      ? `
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0);  /* Remove shadow for home page */
      `
      : `
        box-shadow: ${theme.boxShadow}; /* Default shadow */
      `}

  &:hover {
    transform: scale(1.03);
  }

  ${({ theme }) => theme.media.large`
    height: 400px;
  `}
`;

export const Thumbnail = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

export const Title = styled.h3`
  margin: ${({ theme }) => theme.spacing.p2} 0;
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  color: ${({ theme }) => theme.colors.text};
`;

export const BlogInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content to the left */
  gap: ${({ theme }) => theme.spacing.m1}; /* Add vertical space between items */
  margin-top: ${({ theme }) => theme.spacing.m1};
`;

export const Author = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-bottom: ${({ theme }) => theme.spacing.m1};
`;

export const PublishedDate = styled.p`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.typography.small.fontSize};
  margin: 0;
`;

export const ReadingTime = styled.p`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.typography.small.fontSize};
  margin-top: ${({ theme }) => theme.spacing.m1};
`;