// src/styles/AgentListPageStyles.ts
import styled from 'styled-components';

// Agent card container style
export const AgentCardContainer = styled.div`
  display: grid;
  margin-left: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 2fr;
  max-width: 20rem;
  gap: 20px;
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }

  ${({ theme }) => theme.media.small`
    align-item: center;
  `}

  ${({ theme }) => theme.media.extraSmall`
    align-item: center;
  `}
`;

// Section for the headings with centered text
export const AgentListedHeading = styled.h2`
  padding-top: 70px; 
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: left;
  margin-left: 2rem;
  margin-top: 2rem;
`;

// Agent photo and name container
export const PhotoNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;

  h2 {
    font-size: 1.2rem; /* Adjust the font size here */
    font-weight: bold;
    margin: 0;
    text-align: center;
  }
`;

// Agent photo style
export const AgentPhoto = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

// Agent info style
export const AgentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .rating-review {
    display: flex;
    align-items: center; /* Align items in the center horizontally */
    justify-content: flex-start; /* Align content to the left */
    gap: 0rem;

    .rating {
      font-size: 0.9rem;
      background-color: ${({ theme }) => theme.colors.primary};
      color: #fff;
      padding: 0.3rem 0.5rem;
      border-radius: 20px;
      font-weight: bold;
    }

    .divider {
      margin: 0 0.5rem;
    }

    .review-count {
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.text};
    }
  }

  .time-on-platform {
    margin-top: 1rem; /* Add space between review section and time-on-platform */
    font-size: 0.9rem;
    color: ${({ theme }) => theme.colors.textSecondary};
  }
`;


