import styled from 'styled-components';
import CurrencyInput from 'react-currency-input-field';

// Wrapper for the price step
export const StepEightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem 0;
`;

// Heading for the price step
export const StepEightHeading = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 7rem;
  text-align: center;
`;

// Container for the price input and currency dropdown
export const PriceInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 500px;
`;

// Styled currency input
export const StyledCurrencyInput = styled(CurrencyInput)`
  width: 60%;
  padding: 0.75rem 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.background};
  outline: none;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

// Styled dropdown for currency selection
export const CurrencyDropdown = styled.select`
  width: 40%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;
