import styled from 'styled-components';

// Step container (for all steps)
export const StepContainer = styled.div`
  margin: 2rem;
  text-align: center; /* Center the heading and any inline content */
  
  ${({ theme }) => theme.media.small`
    margin: 1rem; /* Reduce margin for small screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    margin: 0.5rem; /* Further reduce margin for extra-small screens */
  `}
`;

// Section Heading
export const StepOneSectionHeading = styled.div`
  font-size: ${({ theme }) => theme.typography.h1.fontSize};
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.spacing.m5};
  margin-top: ${({ theme }) => theme.spacing.m0};

  ${({ theme }) => theme.media.small`
    font-size: 1.3rem;
  `}

  ${({ theme }) => theme.media.extraSmall`
    font-size: 1.3rem;
  `}
`;

// Breadcrumb for navigation
export const Breadcrumb = styled.nav`
  margin: 1rem 2rem 0rem;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.text};

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    margin-right: 0.5rem; /* Adds space after Account */

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    color: ${({ theme }) => theme.colors.text};
    margin: 0 0.5rem; /* Adds space on both sides of the arrow */
  }

  ${({ theme }) => theme.media.small`
    margin: 1rem 1rem; /* Adjust margins for small screens */
    font-size: ${theme.typography.small.fontSize}; /* Adjust font size for small screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    margin: 1rem 1rem; /* Further adjust margins for extra-small screens */
    font-size: ${theme.typography.xSmall.fontSize}; /* Adjust font size for extra-small screens */
  `}
`;

// Wrapper for the form content with spacing between text and topbar
export const StepOneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  margin-top: 10rem; /* Adjust space between topbar and text */

  ${({ theme }) => theme.media.medium`
    margin-top: 8rem; /* Adjust for medium screens */
    padding: 1.5rem 0;
  `}

  ${({ theme }) => theme.media.small`
    margin-top: 6rem; /* Adjust for small screens */
    padding: 1rem 0;
  `}

  ${({ theme }) => theme.media.extraSmall`
    margin-top: 4rem; /* Further adjust for extra-small screens */
    padding: 0.5rem 0;
  `}
`;

// HomeTypeGrid with horizontal alignment
export const HomeTypeGrid = styled.div`
  display: flex;
  justify-content: center; /* Center the home types */
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 1.5rem;

  ${({ theme }) => theme.media.medium`
    gap: ${theme.spacing.m2}; /* Adjust gap for medium screens */
    margin-top: ${theme.spacing.m2}; /* Adjust margin for medium screens */
  `}

  ${({ theme }) => theme.media.small`
    align-items: center;
    flex-direction: column; /* Stack items vertically for small screens */
    gap: ${theme.spacing.m2}; /* Adjust gap */
    margin-top: ${theme.spacing.m1}; /* Reduce margin */
  `}

  ${({ theme }) => theme.media.extraSmall`
    align-items: center;
    flex-direction: column; /* Stack items vertically for extra-small screens */
    gap: ${theme.spacing.m1}; /* Further reduce gap */
    margin-top: ${theme.spacing.m1}; /* Further reduce margin */
  `}
`;

// HomeTypeOption with hover effects
export const HomeTypeOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  width: 180px;
  height: 150px;
  border: 2px solid transparent;
  border-radius: 12px;
  transition: border-color 0.3s ease-in-out;
  cursor: pointer;
  text-align: center;
  font-weight: bold;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  .icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  ${({ theme }) => theme.media.medium`
    width: 160px;
    height: 130px;
    padding: ${theme.spacing.p2};
    .icon {
      font-size: 2rem; /* Reduce icon size */
    }
  `}

  ${({ theme }) => theme.media.small`
    width: 140px;
    height: 110px;
    padding: 0;
    .icon {
      font-size: 1.5rem; /* Further reduce icon size */
    }
  `}

  ${({ theme }) => theme.media.extraSmall`
    width: 120px;
    height: 100px;
    padding: 0;
    .icon {
      font-size: 1.5rem; /* Reduce icon size for extra-small screens */
    }
  `}
`;

// Fixed Button container for all steps
export const FixedButtonContainer = styled.div<{ isFirstStep?: boolean }>`
  position: fixed;
  bottom: 2rem; /* Adjust this value to move buttons higher or lower */
  left: 0;
  right: 0;
  display: flex;
  justify-content: ${({ isFirstStep }) => (isFirstStep ? 'flex-end' : 'space-between')}; /* Adjust alignment */
  padding: 0 2rem;
  z-index: 100; /* Ensures the buttons stay on top of other content */
  max-width: 1200px; /* Adjust to match the layout width */
  margin: 0 auto; /* Center the container horizontally */

  ${({ theme }) => theme.media.medium`
    padding: 1rem ${theme.spacing.m3}; /* Adjust padding for medium screens */
  `}

  ${({ theme }) => theme.media.small`
    padding: 1rem ${theme.spacing.m2}; /* Adjust padding for small screens */
    bottom: 1rem; /* Raise buttons for small screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    padding: 1rem ${theme.spacing.m1}; /* Further adjust padding */
    bottom: 0.5rem; /* Further raise buttons */
  `}
`;

// Styled button for Back/Next with hover effects
export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: ${({ theme }) => theme.colors.primary};
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.textSecondary};
    cursor: not-allowed;
  }

  ${({ theme }) => theme.media.medium`
    font-size: ${theme.typography.body.fontSize};
    padding: ${theme.spacing.p2} ${theme.spacing.p3}; /* Adjust padding for medium screens */
  `}

  ${({ theme }) => theme.media.small`
    font-size: 0.9rem; /* Adjust font size for extra-small screens */
    padding: 0.5rem 1rem; /* Further adjust padding */
  `}

  ${({ theme }) => theme.media.extraSmall`
    font-size: 0.7rem; /* Adjust font size for extra-small screens */
    padding: 0.5rem 1rem; /* Further adjust padding */
  `}
`;

export const NextButton = styled(StyledButton)``;
export const BackButton = styled.button`
  background-color: white;
  color: black;
  padding: 0.75rem 1.5rem;
  border: 2px solid black;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: black;
    color: white;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.textSecondary};
    cursor: not-allowed;
  }

  ${({ theme }) => theme.media.medium`
    font-size: ${theme.typography.body.fontSize};
    padding: ${theme.spacing.p2} ${theme.spacing.p3}; /* Adjust padding for medium screens */
  `}

  ${({ theme }) => theme.media.small`
    font-size: 0.9rem; /* Adjust font size for extra-small screens */
    padding: 0.5rem 1rem; /* Further adjust padding */
  `}

  ${({ theme }) => theme.media.extraSmall`
    font-size: 0.7rem; /* Adjust font size for extra-small screens */
    padding: 0.5rem 1rem; /* Further adjust padding */
  `}
`;
