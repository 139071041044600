import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getCountries,
  getCities,
  getRankedProperties,
  getAgentPublicProfile,
  Country,
  City,
  PropertyDetail,
  AgentProfile,
} from '../../services/propertyService';
import UserTopBar from '../../components/common/UserTopBar';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import { getAgentReviewSummary, AgentReviewSummary } from '../../services/agentService';
import CustomDropdown from '../../components/common/CustomDropdown';
import { FaSearch } from 'react-icons/fa';
import {
  HomePageContainer,
  SearchSection,
  SearchButton,
  RecommendedHeading,
  HomeWallPaper,
} from '../../styles/HomePageStyles';
import { getBlogPosts, BlogPost } from '../../services/blogService';
import BlogCarousel from '../../components/common/BlogCarousel';
import Footer from '../../components/common/Footer';
import PropertyCarousel from '../../components/property/PropertyCarousel';

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const [countries, setCountries] = useState<Country[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<number | null>(null);
  const [selectedCity, setSelectedCity] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [rankedProperties, setRankedProperties] = useState<PropertyDetail[]>([]);
  const [cityLookup, setCityLookup] = useState<{ [key: number]: string }>({});
  const [agentProfiles, setAgentProfiles] = useState<{ [key: number]: AgentProfile }>({});
  const [agentReviews, setAgentReviews] = useState<{ [key: number]: AgentReviewSummary }>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [recentPosts, setRecentPosts] = useState<BlogPost[]>([]);
  
  // Fetch countries on mount
  useEffect(() => {
    async function fetchCountries() {
      setIsLoading(true);
      try {
        const countriesList = await getCountries();
        setCountries(countriesList);
      } catch (err) {
        setError('Failed to load countries.');
      } finally {
        setIsLoading(false);  // End loading when fetching is done
      }
    }
    fetchCountries();
  }, []);

  useEffect(() => {
    async function fetchCities() {
      try {
        setIsLoading(true);
        const citiesList = await getCities();
  
        // Create a city lookup map (id -> name)
        const cityMap: { [key: number]: string } = {};
        citiesList.forEach(city => {
          cityMap[city.id] = city.name;
        });
        setCityLookup(cityMap); 
  
        setCities(citiesList);
      } catch (err) {
        setError('Failed to load cities.');
      } finally {
        setIsLoading(false);  // End loading when fetching is done
      }
    }
  
    fetchCities();
  }, []);  

  // Fetch ranked properties and agent details
  useEffect(() => {
    const fetchRankedProperties = async () => {
      try {
        setIsLoading(true);
        const data = await getRankedProperties();
        const topProperties = data.slice(0, 10);
        console.log(topProperties)
        setRankedProperties(topProperties);

        // Collect unique agent IDs
        const agentIds = Array.from(new Set(topProperties.map((property) => property.agent_id)));

        // Fetch agent profiles and reviews
        const agentProfilesData: { [key: number]: AgentProfile } = {};
        const agentReviewsData: { [key: number]: AgentReviewSummary } = {};

        await Promise.all(
          agentIds.map(async (agentId) => {
            const [profileData, reviewData] = await Promise.all([
              getAgentPublicProfile(agentId),
              getAgentReviewSummary(agentId),
            ]);

            agentProfilesData[agentId] = profileData;
            agentReviewsData[agentId] = reviewData;
          })
        );

        setAgentProfiles(agentProfilesData);
        setAgentReviews(agentReviewsData);
      } catch (err) {
        setError('Failed to load ranked properties or agent profiles.');
      } finally {
        setIsLoading(false);  // End loading when fetching is done
      }
    };

    fetchRankedProperties();
  }, []);

  // Fetch recent blog posts on mount
  useEffect(() => {
    const fetchRecentPosts = async () => {
      try {
        const posts = await getBlogPosts();
        setRecentPosts(posts.slice(0, 10)); // Take the latest 10 posts
      } catch (error) {
        console.error('Failed to load blog posts:', error);
      }
    };
    fetchRecentPosts();
  }, []);


  const handleCountryChange = (event: { target: { value: number } }) => {
    setSelectedCountry(event.target.value);
    setSelectedCity(null); // Reset city when country changes
  };

  const handleCityChange = (event: { target: { value: number } }) => {
    setSelectedCity(event.target.value);
  };

  const handleSearch = () => {
    const queryParams = new URLSearchParams();
    if (selectedCountry) queryParams.append('country', selectedCountry.toString());
    if (selectedCity) queryParams.append('city', selectedCity.toString()); // Only append city if it's selected

    navigate(`/property-results?${queryParams.toString()}`);
  };

  return (
    <>
    <UserTopBar />
    {isLoading && <LoadingSpinner />}

    <HomeWallPaper>
      <img src={"https://timeaz.blob.core.windows.net/media/traf-sL7w1eUfHJQ-unsplash copy.jpg"} alt="HomePageImage" />
    </HomeWallPaper>

    <SearchSection>
      <p>Your Timely <strong>Solution</strong> for Real Estate</p>
      {error && <p className="error">{error}</p>}

      {/* Custom Dropdowns for Country and City */}
      <div className="filter-section">
        <CustomDropdown
          placeholder="Select Country"
          name=""
          options={countries}
          value={selectedCountry}
          onChange={handleCountryChange}
        />

        <CustomDropdown
          placeholder="Select City (optional)"
          name=""
          options={cities}
          value={selectedCity}
          onChange={handleCityChange}
        />
        <SearchButton
          onClick={handleSearch}
          disabled={!selectedCountry}
          aria-label="Search"
        >
          <FaSearch />
        </SearchButton>
      </div>
    </SearchSection>

    <HomePageContainer>
      <RecommendedHeading>Recommended for you</RecommendedHeading>
      <PropertyCarousel
        properties={rankedProperties}
        visiblePosts={4}  // Set the number of visible properties
        cityLookup={cityLookup}
        agentProfiles={agentProfiles}
        agentReviews={agentReviews}
      />

      <BlogCarousel posts={recentPosts} />
      <Footer />
    </HomePageContainer>
    </>
  );
};

export default HomePage;
