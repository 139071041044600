// src/styles/SignupPageStyles.ts
import styled from 'styled-components';
import { FaArrowLeft } from 'react-icons/fa';

export const SignupWrapper = styled.div`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Full height */
  min-height: 100vh; /* Ensure it takes at least full height */
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => theme.spacing.p3}; /* Padding for small screens */

  /* Responsive adjustments */
  ${({ theme }) => theme.media.small`
    padding: ${theme.spacing.p2}; /* Reduce padding on small screens */
  `}
  ${({ theme }) => theme.media.extraSmall`
    padding: ${theme.spacing.p2}; /* Reduce padding on small screens */
  `}
`;

export const SignupBox = styled.div`
  position: relative;
  background-color: white;
  padding: ${({ theme }) => theme.spacing.p4};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  box-shadow: ${({ theme }) => theme.boxShadow};
  max-width: 500px;
  width: 100%;
  text-align: center;
  margin: auto; /* Ensure the box is centered */
  transition: ${({ theme }) => theme.transition}; /* Smooth transitions for changes */

  /* Responsive styles */
  ${({ theme }) => theme.media.medium`
    padding: ${theme.spacing.p3};
    max-width: 400px;
  `}

  ${({ theme }) => theme.media.small`
    padding: ${theme.spacing.p2};
    max-width: 90%; /* Take 90% of screen width */
  `}

  ${({ theme }) => theme.media.extraSmall`
    padding: ${theme.spacing.p2};
    max-width: 90%; /* Take 90% of screen width */
  `}
`;

export const SectionTitle = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacing.m3};
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.typography.h2.fontSize};

  ${({ theme }) => theme.media.small`
    font-size: ${theme.typography.h3.fontSize}; /* Adjust font size on small screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    font-size: ${theme.typography.h3.fontSize}; /* Adjust font size on small screens */
  `}
`;

export const TwoColumnRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.m3}; /* Gap between the columns */

  ${({ theme }) => theme.media.small`
    flex-direction: column; /* Stack columns vertically on small screens */
    gap: ${theme.spacing.m2}; /* Adjust gap for small screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    flex-direction: column; /* Stack columns vertically on small screens */
    gap: ${theme.spacing.m2}; /* Adjust gap for small screens */
  `}
`;

export const FileUploadWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.m3};

  ${({ theme }) => theme.media.small`
    margin-bottom: ${theme.spacing.m2}; /* Reduce margin for small screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    margin-bottom: ${theme.spacing.m2}; /* Reduce margin for small screens */
  `}
`;

export const BackArrow = styled(FaArrowLeft)`
  position: absolute;
  top: ${({ theme }) => theme.spacing.m3};
  left: ${({ theme }) => theme.spacing.m3};
  cursor: pointer;
  font-size: 1.5rem; /* Use rem for better scalability */
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
  }

  ${({ theme }) => theme.media.small`
    font-size: 1.2rem; /* Reduce arrow size on small screens */
    top: ${theme.spacing.m2}; /* Adjust position on small screens */
    left: ${theme.spacing.m2};
  `}

  ${({ theme }) => theme.media.extraSmall`
    font-size: 1.2rem; /* Reduce arrow size on small screens */
    top: ${theme.spacing.m2}; /* Adjust position on small screens */
    left: ${theme.spacing.m2};
  `}
`;

export const Logo = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.m4};

  img {
    height: 100px; /* Adjust the height of the logo */
    width: auto; /* Maintain aspect ratio */
  }

  ${({ theme }) => theme.media.medium`
    img {
      height: 100px; /* Adjust logo size on medium screens */
    }
  `}

  ${({ theme }) => theme.media.small`
    img {
      height: 80px; /* Adjust logo size on small screens */
    }
  `}

  ${({ theme }) => theme.media.extraSmall`
    margin-bottom: 1rem;
    img {
      height: 60px; /* Adjust logo size on small screens */
    }
  `}
`;
