// src/pages/agent/AddPropertyPage.tsx

import React from 'react';
import PropertyCreationForm from '../../components/property/PropertyCreationForm';

const AddPropertyTab: React.FC = () => {
  return (
    <div className="add-property-tab">
      <PropertyCreationForm />
    </div>
  );
};

export default AddPropertyTab;
