import styled from 'styled-components';

export const InboxContainer = styled.div`
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
`;

// Section for the headings with centered text
export const InboxHeading = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: left;
  margin-left: 2rem;
`;

export const MessageCard = styled.div`
  border-bottom: 1px solid #eaeaea;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* Style for unread messages */
  &.unread {
    font-weight: bold;
  }

  /* Style for read messages */
  &.read {
    color: #888888;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const MessageInfo = styled.div`
  h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0.5rem 0;
  }
`;

export const PropertyLink = styled.div`
  margin-top: 0.5rem;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
    &:hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }
`;

export const MessageStatus = styled.div`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.primary};
`;
