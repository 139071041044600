import styled from 'styled-components';

// Wrapper for the entire photo grid
export const GridWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.m2}; /* Use theme spacing */
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  position: relative; /* This will allow positioning the button over the grid */

  ${({ theme }) => theme.media.small`
    flex-direction: column; /* Stack large photo vertically */
  `}

  ${({ theme }) => theme.media.extraSmall`
    flex-direction: column; /* Stack large photo vertically */
  `}
`;

// Large photo container (left side)
export const LargePhotoContainer = styled.div`
  width: 60%; /* Default width for larger screens */
  height: 400px;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${({ theme }) => theme.borderRadius.medium};
  }

  ${({ theme }) => theme.media.small`
    width: 100%; /* Full width for the large photo on small screens */
    height: auto; /* Auto height to fit the image */
  `}

  ${({ theme }) => theme.media.extraSmall`
    width: 100%; /* Full width for the large photo on small screens */
    height: auto; /* Auto height to fit the image */
  `}
`;

// Small photo grid (right side)
export const SmallPhotoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing.m1};
  width: 40%;
  height: 400px;
  position: relative; /* Allows the button to be overlaid */

  ${({ theme }) => theme.media.small`
    display: none; /* Remove the small photo grid on small screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    display: none; /* Remove the small photo grid on small screens */
  `}
`;

// Each small photo container
export const SmallPhotoContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.medium};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${({ theme }) => theme.borderRadius.medium};
  }
`;

// Button to view all photos
export const ViewAllButton = styled.button`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  padding: ${({ theme }) => `${theme.spacing.p1} ${theme.spacing.p2}`};
  font-size: ${({ theme }) => theme.typography.small.fontSize};
  font-weight: bold;
  cursor: pointer;
  border: none;
  background-color: white;
  color: ${({ theme }) => theme.colors.text};
  border-radius: ${({ theme }) => theme.borderRadius.medium};

  &:hover {
    opacity: 0.8;
    background-color: white;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

// Modal Wrapper
export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

// Modal Content for all photos (Vertical Stacking)
export const ModalContent = styled.div`
  position: relative;
  width: 80%; /* Larger width to display bigger photos */
  max-height: 90%;
  overflow-y: auto;
  background-color: white;
  padding: ${({ theme }) => theme.spacing.p4};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  display: flex;
  flex-direction: column; /* Stack photos vertically */
  gap: ${({ theme }) => theme.spacing.m3}; /* Margin between photos */

  img {
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.medium};
    object-fit: contain; /* Make sure the photo fits within the modal width */
  }

  ${({ theme }) => theme.media.extraMedium`
    width: 95%; /* Adjust modal width for small screens */
    padding: 0;
    gap: 0;
    border-radius: 0;
    background: none;
    img {
      width: 100%;
      border-radius: 0;
      object-fit: contain; /* Make sure the photo fits within the modal width */
    }
  `}

  ${({ theme }) => theme.media.medium`
    width: 95%; /* Adjust modal width for small screens */
    padding: 0;
    gap: 0;
    border-radius: 0;
    background: none;
    img {
      width: 100%;
      border-radius: 0;
      object-fit: contain; /* Make sure the photo fits within the modal width */
    }
  `}

  ${({ theme }) => theme.media.small`
    width: 95%; /* Adjust modal width for small screens */
    padding: 0;
    gap: 0;
    border-radius: 0;
    background: none;
    img {
      width: 100%;
      border-radius: 0;
      object-fit: contain; /* Make sure the photo fits within the modal width */
    }
  `}

  ${({ theme }) => theme.media.extraSmall`
    width: 95%; /* Adjust modal width for small screens */
    padding: 0;
    gap: 0;
    border-radius: 0;
    background: none;
    img {
      width: 100%;
      border-radius: 0;
      object-fit: contain; /* Make sure the photo fits within the modal width */
    }
  `}
`;

// Close button for the modal
export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
  }
`;
