import styled from 'styled-components';

export const ProfileWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr; /* Two columns: 1/3rd for profile and confirmed info, 2/3rd for about + review */
  grid-column-gap: ${({ theme }) => theme.spacing.m5};
  grid-row-gap: ${({ theme }) => theme.spacing.m3};
  margin: ${({ theme }) => theme.spacing.m4};
  align-items: start; /* Aligns all child elements to the top */

  ${({ theme }) => theme.media.medium`
    grid-template-columns: 1fr; /* Single column layout on medium screens */
    margin: ${theme.spacing.m3};
    grid-row-gap: ${theme.spacing.m3};
  `}

  ${({ theme }) => theme.media.small`
    display: flex;
    flex-direction: column; /* Convert to a single-column layout */
    margin: ${theme.spacing.m2};
    grid-column-gap: 0; /* Remove column gaps */
    grid-row-gap: ${theme.spacing.m2};
  `}

  ${({ theme }) => theme.media.extraSmall`
    display: flex;
    flex-direction: column; /* Convert to a single-column layout */
    margin: ${theme.spacing.m1};
    grid-column-gap: 0; /* Remove column gaps */
    grid-row-gap: ${theme.spacing.m1}; /* Further reduce row gap */
  `}
`;

// Breadcrumb for navigation
export const Breadcrumb = styled.nav`
  margin: ${({ theme }) => `${theme.spacing.m5} ${theme.spacing.m5} ${theme.spacing.m5}`};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  color: ${({ theme }) => theme.colors.text};

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    margin-right: ${({ theme }) => theme.spacing.m1}; 

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    color: ${({ theme }) => theme.colors.text};
    margin: 0 ${({ theme }) => theme.spacing.m1};
  }

  ${({ theme }) => theme.media.small`
    margin: ${theme.spacing.m2};
    font-size: ${theme.typography.small.fontSize};
  `}

  ${({ theme }) => theme.media.extraSmall`
    margin: ${theme.spacing.m1};
    font-size: ${theme.typography.xSmall.fontSize};
  `}
`;

// Profile Card
export const ProfileCard = styled.div`
  grid-row: 1 / 2; /* Take the first row */
  grid-column: 1 / 2; /* Take the first column */
  background: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  padding: ${({ theme }) => theme.spacing.p4};
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: space-between; /* Distribute space between left and right sections */
  max-width: 90%; /* Adjust width to fit the design */
  box-shadow: ${({ theme }) => theme.boxShadow};
  position: relative;

  .left-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.m2};
    position: relative; /* Set relative positioning for the icon to align with the photo */
  }

  .agent-photo {
    width: 130px; /* Adjust the size */
    height: 130px;
    border-radius: 50%;
    object-fit: cover;
    position: relative; /* Make the photo the reference for the icon positioning */
  }

    .edit-icon {
    position: absolute;
    bottom: 40px; /* Slightly below the photo */
    left: 50%; /* Horizontally center relative to the photo */
    transform: translateX(-50%); /* Ensure it's centered */
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    width: 30px; /* Control the size of the circle */
    height: 30px; /* Control the size of the circle */
    border-radius: 50%; /* Makes the background a circle */
    display: flex; /* Enable flexbox for centering */
    justify-content: center; /* Horizontally center the icon */
    align-items: center; /* Vertically center the icon */
    font-size: ${({ theme }) => theme.typography.small.fontSize};
    cursor: pointer;
    opacity: 1;
    }

  .agent-info {
    h2 {
      font-size: ${({ theme }) => theme.typography.h2.fontSize};
      margin-bottom: ${({ theme }) => theme.spacing.m1};
      font-weight: 600;
    }

    .agent-status {
      font-size: ${({ theme }) => theme.typography.small.fontSize};
      font-weight: bold;
      color: ${({ theme }) => theme.colors.textSecondary};
    }
  }

  .right-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing.m3};

    .rating-review {
      display: flex;
      align-items: center; /* Align items in the center horizontally */
      justify-content: flex-start; /* Align content to the left */
      gap: ${({ theme }) => theme.spacing.m2};
      .time-on-platform {
        font-size: ${({ theme }) => theme.typography.small.fontSize};
        color: ${({ theme }) => theme.colors.textSecondary};
      }

      .rating {
        font-size: ${({ theme }) => theme.typography.small.fontSize};
        background-color: ${({ theme }) => theme.colors.primary};
        color: #fff;
        padding: 0.3rem 0.5rem;
        border-radius: 20px;
        font-weight: bold;
      }

      .review-count {
        font-size: ${({ theme }) => theme.typography.small.fontSize};
        color: ${({ theme }) => theme.colors.text};
      }
    }
  }

  /* Hidden File Input */
  input[type="file"] {
    display: none;
  }

  ${({ theme }) => theme.media.extraMedium`
    flex-direction: column; /* Stack elements vertically */
    max-width: 100%; /* Use full width */
    .right-section {
      justify-content: center; /* Center align */
      gap: ${theme.spacing.m1}; /* Decrease gap */
    }
  `}

  ${({ theme }) => theme.media.medium`
    flex-direction: column; /* Stack elements vertically */
    max-width: 100%; /* Use full width */
    .right-section {
      justify-content: center; /* Center align */
      gap: ${theme.spacing.m1}; /* Decrease gap */
    }
  `}

  ${({ theme }) => theme.media.small`
    flex-direction: column; /* Stack all elements vertically */
    max-width: 100%; /* Use full width */
    font-size: ${theme.typography.small.fontSize}; /* Use smaller font size */
    .left-section, .right-section {
      width: 100%; /* Expand to full width */
      align-items: center; /* Center align content */
    }
    .right-section {
      margin-top: ${theme.spacing.m2}; /* Add spacing between sections */
    }
  `}

  ${({ theme }) => theme.media.extraSmall`
    flex-direction: column; /* Stack all elements vertically */
    max-width: 100%; /* Use full width */
    font-size: ${theme.typography.small.fontSize}; /* Use smaller font size */
    .left-section, .right-section {
      width: 100%; /* Expand to full width */
      align-items: center; /* Center align content */
    }
    .right-section {
      margin-top: ${theme.spacing.m2}; /* Add spacing between sections */
    }
  `}
`;

// Confirmed Information Card
export const ConfirmedInfoCard = styled.div`
  grid-row: 2 / 3; /* Second row below the profile card */
  grid-column: 1 / 2; /* Still in the first column */
  background: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  padding: ${({ theme }) => theme.spacing.p3};
  margin-top: 2rem;
  max-width: 90%; /* Adjust width to fit the design */
  box-shadow: ${({ theme }) => theme.boxShadow};

  h3 {
    font-size: ${({ theme }) => theme.typography.h3.fontSize};
    margin-bottom: ${({ theme }) => theme.spacing.m2};
  }

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      font-size: ${({ theme }) => theme.typography.body.fontSize};
      margin-bottom: ${({ theme }) => theme.spacing.m1};

      &:before {
        content: '✔';
        margin-right: ${({ theme }) => theme.spacing.m1};
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  ${({ theme }) => theme.media.extraMedium`
    margin-top: ${theme.spacing.m2};
    max-width: 100%;
    ul li {
      font-size: ${theme.typography.small.fontSize};
    }
  `}

  ${({ theme }) => theme.media.medium`
    margin-top: ${theme.spacing.m2};
    max-width: 100%;
    ul li {
      font-size: ${theme.typography.small.fontSize};
    }
  `}

  ${({ theme }) => theme.media.small`
    margin-top: ${theme.spacing.m2};
    max-width: 100%;
    ul li {
      font-size: ${theme.typography.small.fontSize};
    }
  `}

  ${({ theme }) => theme.media.extraSmall`
    margin-top: ${theme.spacing.m2};
    max-width: 100%;
    ul li {
      font-size: ${theme.typography.small.fontSize};
    }
  `}
`;

// About Section
export const AboutSection = styled.div`
  grid-row: 1 / 2; /* First row */
  grid-column: 2 / 3; /* Second column */
  padding: 0rem;

  h3 {
    font-size: ${({ theme }) => theme.typography.h2.fontSize};
    margin-bottom: ${({ theme }) => theme.spacing.m2};
  }

  p {
    font-size: ${({ theme }) => theme.typography.body.fontSize};
    color: ${({ theme }) => theme.colors.text};
  }

  .edit-button {
    display: inline-block;
    margin-top: ${({ theme }) => theme.spacing.m2};
    margin-bottom: ${({ theme }) => theme.spacing.m3};
    padding: ${({ theme }) => theme.spacing.p2};
    background-color: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: ${({ theme }) => theme.borderRadius.medium};
    color: ${({ theme }) => theme.colors.text};
    cursor: pointer;
    font-size: ${({ theme }) => theme.typography.body.fontSize};

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
      color: white;
    }
  }

  input, textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 5px;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.background};
    margin-bottom: 1rem;

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }

  textarea {
    min-height: 100px;
    resize: vertical;  /* Allow the textarea to resize vertically */
  }

  .action-buttons {
    display: flex;
    justify-content: flex-start; /* Align buttons to the left */
    gap: 1rem; /* Adds space between the Save and Cancel buttons */
    margin-top: 1rem;
  }

  .save-button {
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  .cancel-button {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors.secondaryDark};
    }
  }

  .language-icon {
    margin-right: 0.5rem;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.primary};
  }

  /* Ensuring the About Section aligns with the top of the ProfileCard */
  align-self: start;

  ${({ theme }) => theme.media.small`
    padding: ${theme.spacing.p2};
    h3, p, .edit-button {
      font-size: ${theme.typography.small.fontSize};
    }
  `}

  ${({ theme }) => theme.media.extraSmall`
    padding: ${theme.spacing.p2};
    h3, p, .edit-button {
      font-size: ${theme.typography.small.fontSize};
    }
  `}
`;

// Review Wrapper
export const ReviewWrapper = styled.div`
  grid-row: 2 / 3; /* Second row below the About Section */
  grid-column: 2 / 3; /* Stays in the second column */
  padding: 0rem;

  h3 {
    font-size: ${({ theme }) => theme.typography.h2.fontSize};
    margin-bottom: ${({ theme }) => theme.spacing.m2};
  }

  .category-ratings {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns for ratings */
    gap: ${({ theme }) => theme.spacing.m2};
    margin-bottom: 1rem;

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        font-size: ${({ theme }) => theme.typography.body.fontSize};
        margin-bottom: 0.5rem;

        span {
          font-weight: bold;
        }
      }
    }
  }

    /* Use flexbox to align reviews side by side */
  .reviews-list {
    display: flex;
    gap: ${({ theme }) => theme.spacing.m2};
    flex-wrap: wrap; /* Allows wrapping when there are more than 2 reviews */
  }

  /* Add a faint horizontal line to visually split About and Reviews */
  border-top: 1px solid ${({ theme }) => theme.colors.border}; /* Faint line */
  padding-top: 2rem; /* Space above the reviews */

  ${({ theme }) => theme.media.small`
    padding: ${theme.spacing.p2};
    .category-ratings ul li, h3 {
      font-size: ${theme.typography.small.fontSize};
    }
  `}

  ${({ theme }) => theme.media.extraSmall`
    padding: ${theme.spacing.p2};
    .category-ratings ul li, h3 {
      font-size: ${theme.typography.small.fontSize};
    }
  `}
`;

// Style for the Review Card Wrapper
export const ReviewCardGrid = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap; /* Wrap if the screen size is too small */
  justify-content: flex-start; /* Align review cards to the left */
`;

// Adjusted Review Card for Modal
export const ReviewCard = styled.div<{ inModal?: boolean }>`
  background: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;

  /* Use full width for modal, and 45% width for grid layout */
  width: ${({ inModal }) => (inModal ? '100%' : '45%')};

  p {
    margin: 0;
    font-size: 0.85rem;
  }

  h5 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
`;

// Modal Overlay - the backdrop
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);  // Semi-transparent background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

// Modal Content for displaying reviews
export const ModalContent = styled.div`
  background: ${({ theme }) => theme.colors.background};
  border-radius: 10px;
  padding: 2rem;
  width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  position: relative;

  .review-cards-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem; /* Add space between review cards */
  }
`;

// Close Button for modal
export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text};
  position: absolute;
  top: 1rem;
  right: 1rem;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

// ProgressBar wrapper for the score visualization
export const ProgressBar = styled.div`
  display: flex;
  flex-direction: column; /* Arrange items vertically */
  align-items: center; /* Center align the content */
  margin-bottom: 1.5rem;

  .bar-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.5rem; /* Space between text and bar */
    align-items: center;

    .category-label {
      font-weight: 500;
      font-size: 0.9rem;
      flex: 1;
      text-align: left;
    }

    .score {
      font-weight: 600;
      font-size: 0.9rem;
      margin-left: 1rem;
      text-align: right;
      color: ${({ theme }) => theme.colors.text};
    }
  }

  .bar {
    position: relative;
    width: 100%;
    height: 6px;
    background-color: ${({ theme }) => theme.colors.primaryLight}; // Light color for background
    border-radius: 3px;
    overflow: hidden;

    .fill {
      background-color: ${({ theme }) => theme.colors.primary}; // Dark color for the filled bar
      height: 100%;
      transition: width 0.3s ease;
    }
  }
`;


export const CategoryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 1.5rem; /* Space between rows and columns */
  margin-bottom: 2rem; /* Space below the categories */
`;

// Show All Reviews Link
export const ShowAllReviewsLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  margin-top: 1.5rem;
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
    text-decoration: underline;
  }
`;
