// src/components/PropertyCreationForm.tsx

import React, { useState, useEffect } from 'react';
import { getRefreshToken } from '../../services/userService';
import { useNavigate, useLocation  } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from '../common/LoadingSpinner';
import { LatLngExpression } from 'leaflet';
import { TileLayer, Marker, useMapEvents, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { getHomeTypes, getCities, getAmenities, getHomeViews, getPropertyStatuses, 
  getCountries, City, Country, Amenity, HomeView, PropertyStatus, submitProperty, uploadPhotos, Photo } from '../../services/propertyService';
import { StepContainer, StepOneWrapper, StepOneSectionHeading, HomeTypeGrid, HomeTypeOption, FixedButtonContainer, BackButton, NextButton, Breadcrumb } from '../../styles/PropertyCreationStepOneStyles';
import { StepTwoWrapper, PropertyInfo, IncrementWrapper, InputField, ViewOptions, SectionHeading, Subtext } from '../../styles/PropertyCreationStepTwoStyles';
import { FaHome, FaBuilding, FaWarehouse, FaCity, FaCamera } from 'react-icons/fa';
import Topbar from '../common/Topbar';
import CustomDropdown from '../common/CustomDropdown';
import NormalDropdown from '../common/NormalDropdown';
import 'rsuite/dist/rsuite.min.css';
import { Message, useToaster } from 'rsuite';
import { DropdownFlexContainer } from '../../styles/CustomDropdownStyles';
import { StepFiveWrapper, PhotoUploadWrapper, StepFiveHeading, PreviewPhotosButton,
  UploadProgressOverlay,
  ProgressBarContainer,
  ProgressBarLabel,
  StyledProgressBar,
 } from '../../styles/PropertyCreationStepFiveStyles';
import PhotoPreviewModal from './PhotoPreviewModal';
import { StepSixWrapper, StepSixHeading, StyledInput, DatePickerWrapper } from '../../styles/PropertyCreationStepSixStyles';
import DatePicker from 'react-datepicker';
import { StepEightWrapper, StepEightHeading, PriceInputContainer, CurrencyDropdown, StyledCurrencyInput } from '../../styles/PropertyCreationStepEightStyles';
import { StepSevenWrapper, StepSevenHeading, DescriptionTextArea } from '../../styles/PropertyCreationStepSevenStyles';
import PropertyReview from './PropertyReview';
import { ButtonContainer } from '../../styles/PropertyReviewStyles';
import { AmenitiesGrid, StepFourWrapper, AmenityOption, StepFourSectionHeading } from '../../styles/PropertyCreationStepFourStyles';
import { StepThreeWrapper, StepThreeSectionHeading, MapWrapper, StyledMapContainer, BlackButton } from '../../styles/PropertyCreationStepThreeStyles';
import { CreateProperty } from '../../services/propertyService';
import 'leaflet/dist/leaflet.css';
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import 'leaflet.awesome-markers';
import 'font-awesome/css/font-awesome.min.css';
import { Modal, Button } from 'rsuite';

interface HomeTypeWithIcon {
  id: number;
  name: string;
  icon: JSX.Element; // Adding a type for the icon
}

// Create the awesome marker icon
const awesomeIcon = L.AwesomeMarkers.icon({
  icon: 'home',          // The icon name (e.g., 'home')
  prefix: 'fa',          // The font prefix ('fa' for FontAwesome)
  markerColor: 'red',   // Marker color
  iconColor: 'white',    // Icon color inside the marker
});


// Set the default marker icon
const defaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconAnchor: [12, 41], // Adjust this anchor to center the icon correctly
});

L.Marker.prototype.options.icon = defaultIcon;

const PropertyMap: React.FC<{ onLocationSelect: (lat: number, lng: number) => void, initialLat: number, initialLng: number }> = ({ onLocationSelect, initialLat, initialLng }) => {
  const [position, setPosition] = useState<[number, number]>([initialLat, initialLng]);

  useMapEvents({
    click: (event) => {
      const { lat, lng } = event.latlng;
      setPosition([lat, lng]);
      onLocationSelect(lat, lng); // Pass the selected location to the parent component
    }
  });

  return (
    <Marker position={position} icon={awesomeIcon}>
      <Tooltip>Your selected location</Tooltip>
    </Marker>
  );
};

const localStorageKey = 'propertyCreationData'; // Local storage key for form data

const PropertyCreationForm: React.FC = () => {
  const [step, setStep] = useState(1);
  const [latitude, setLatitude] = useState<number>(35.2480);  // Initial latitude (can be set to a default location)
  const [longitude, setLongitude] = useState<number>(33.6577); // Initial longitude
  const center: LatLngExpression = [latitude, longitude];
  const [isLoading, setIsLoading] = useState(false); // State for tracking loading
  const [submissionError, setSubmissionError] = useState(''); // Track errors
  const [submissionSuccess, setSubmissionSuccess] = useState(false);  
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const toaster = useToaster();
  const navigate = useNavigate();
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const currencies = [
    { code: 'USD', symbol: '$', name: 'US Dollar' },
    { code: 'EUR', symbol: '€', name: 'Euro' },
    { code: 'GBP', symbol: '£', name: 'British Pound' },
    // Add more currencies as needed
  ];
  const [formData, setFormData] = useState<CreateProperty>(() => {
    const savedFormData = localStorage.getItem(localStorageKey);
    return savedFormData ? JSON.parse(savedFormData).formData : {
      property_type: 1,
      bedrooms: 1,
      bathrooms: 1,
      area: 1,
      floor: 1,
      view: 1,
      city: 1,
      country: 1,
      amenities: [] as number[],
      photo_data: [],
      project_name: '',
      status: 0,
      availability: 1, // preset to For sale
      delivery_date: '',
      built_in: '',
      description: '',
      price: '',
      currency: '£',
      latitude: latitude,
      longitude: longitude,
    };
  }); // Load form data from localStorage
  const [homeTypes, setHomeTypes] = useState<HomeTypeWithIcon[]>([]);

  const location = useLocation();

  // Save the form data and step to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify({ formData, step }));
    console.log(formData)
  }, [formData, step]);

  useEffect(() => {
    // Check if redirected from login and restore form data and step
    if (location.state?.from === '/agent-login') {
      const savedFormData = localStorage.getItem(localStorageKey);
      if (savedFormData) {
        const { formData, step } = JSON.parse(savedFormData);
        setFormData(formData);
        // Navigate to the step that was saved
        setStep(step);
        navigate(`/agent/add-property?step=${step}`, { replace: true });
      }
    }
  }, [location]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const urlStep = queryParams.get('step');
    if (urlStep) {
      setStep(parseInt(urlStep));
    }
  }, []);

  useEffect(() => {
    const fetchHomeTypes = async () => {
      const types = await getHomeTypes(); // Fetch from API
      const homeTypesWithIcons = types.map((type: any) => ({
        ...type,
        icon: getIconForHomeType(type.name), // Add icon based on home type name
      }));
      setHomeTypes(homeTypesWithIcons); // Set state with icons
    };

    fetchHomeTypes();
  }, []);
  
  // Function to assign icons based on home type name
  const getIconForHomeType = (homeTypeName: string) => {
    switch (homeTypeName.toLowerCase()) {
      case 'villa':
        return <FaHome className="icon" />;
      case 'apartment':
        return <FaBuilding className="icon" />;
      case 'complex':
        return <FaWarehouse className="icon" />;
      case 'penthouse':
        return <FaCity className="icon" />;
      default:
        return <FaHome className="icon" />; // Default icon
    }
  };

  const [cities, setCities] = useState<City[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [amenities, setAmenities] = useState<Amenity[]>([]);
  const [homeViews, setHomeViews] = useState<HomeView[]>([]);
  const [propertyStatuses, setPropertyStatuses] = useState<PropertyStatus[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<PropertyStatus | null>(null);

  useEffect(() => {
    async function fetchData() {
      setCities(await getCities());
      setCountries(await getCountries());
      setAmenities(await getAmenities());
      setHomeViews(await getHomeViews());
      setPropertyStatuses(await getPropertyStatuses());
    }
    fetchData();
  }, []);

  const handleNextStep = () => {
    // Show the confirmation modal instead of immediately proceeding
    if (step === 3) {
      setShowConfirmModal(true);
    } else {
      const nextStep = step + 1;
      setStep(nextStep);
      navigate(`/agent/add-property?step=${nextStep}`, { replace: true });
    }
  };
  
  const handleConfirmLocation = () => {
    setShowConfirmModal(false);
    const nextStep = step + 1;
    setStep(nextStep);
    navigate(`/agent/add-property?step=${nextStep}`, { replace: true });
  };
  
  const handleCancelConfirmation = () => {
    setShowConfirmModal(false);
  };
  
  const handlePrevStep = () => {
    const prevStep = step - 1;
    setStep(prevStep);
    navigate(`/agent/add-property?step=${prevStep}`, { replace: true });
  };
  

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | { target: { name: string; value: number } }
  ) => {
    const { name, value } = e.target;
  
    // If the field is "status", convert it to a number
    setFormData({ 
      ...formData, 
      [name]: name === 'status' ? Number(value) : value 
    });
  
    if (name === 'status') {
      const status = propertyStatuses.find((s) => s.id === Number(value));
      setSelectedStatus(status || null);
    }
  };
  

  const handleAmenitiesClick = (amenityId: number) => {
    // Toggle the amenity id in the formData state
    setFormData((prevData) => ({
      ...prevData,
      amenities: prevData.amenities.includes(amenityId)
        ? prevData.amenities.filter((id) => id !== amenityId) // Remove the amenity if it's already selected
        : [...prevData.amenities, amenityId], // Add the amenity if it's not already selected
    }));
  };  

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      setIsUploading(true);
      try {
        const uploadedUrls = await uploadPhotos(files, (progress) => setUploadProgress(progress));
        const newPhotoData = uploadedUrls.map((url, index) => ({
          id: Date.now() + index,  // Temporary ID
          image_url: url,
          rank: formData.photo_data.length + index + 1,  // Auto-assign ranks
        }));
        setFormData((prev) => ({ ...prev, photo_data: [...prev.photo_data, ...newPhotoData] }));
        toaster.push(<Message type="success" showIcon>Photos uploaded successfully!</Message>);
      } catch (error) {
        toaster.push(<Message type="error" showIcon>Failed to upload photos.</Message>);
      } finally {
        setIsUploading(false);
        setUploadProgress(0);
      }
    }
  };
  
  const handleDeletePhoto = (photoId: number) => {
    setFormData((prevData) => ({
      ...prevData,
      photo_data: prevData.photo_data.filter((photo) => photo.id !== photoId), // Remove by ID
    }));
  };   
    
  // Handle rank changes ensuring unique ranks
  const handleRankChange = (photoId: number, newRank: number) => {
    setFormData((prevData) => {
      // Reset the rank of any photo that currently holds the new rank
      const updatedPhotos = prevData.photo_data.map((photo) =>
        photo.rank === newRank ? { ...photo, rank: 0 } : photo
      );

      // Assign the new rank to the selected photo
      return {
        ...prevData,
        photo_data: updatedPhotos.map((photo) =>
          photo.id === photoId ? { ...photo, rank: newRank } : photo
        ),
      };
    });
  };

  const showMessage = (type: 'success' | 'error', message: string) => {
    toaster.push(
      <Message showIcon type={type}>
        {message}
      </Message>,
      { placement: 'topCenter' }
    );
  };

  const handleLocationSelect = (lat: number, lng: number) => {
    // Limit the number of decimal places to 6
    const roundedLat = parseFloat(lat.toFixed(6));
    const roundedLng = parseFloat(lng.toFixed(6));
    console.log(roundedLat)
    console.log(roundedLng)
    setFormData((prevData) => ({
      ...prevData,
      latitude: roundedLat, // Use rounded latitude
      longitude: roundedLng, // Use rounded longitude
    }));
  };  

  const handleSubmit = async () => {
    // Check if the agent is authenticated before submitting
    const token = getRefreshToken();
    if (!token) {
      // If not authenticated, store form data and redirect to login page
      localStorage.setItem(localStorageKey, JSON.stringify({ formData, step }));
      navigate('/agent-login', { state: { from: '/agent/add-property', step } });
      showMessage('error', 'Session expired. Please log in again.');
      return;
    }
    setIsLoading(true); // Start loading
    try {
      setSubmissionError('');
      
      // Construct the submission data object with only necessary fields
      const submissionData = {
        property_type: formData.property_type,
        project_name: formData.project_name,
        description: formData.description,
        price: formData.price,
        currency: formData.currency,
        bedrooms: formData.bedrooms,
        bathrooms: formData.bathrooms,
        area: formData.area,
        floor: formData.floor,
        view: formData.view,
        city: formData.city,
        country: formData.country,
        status: formData.status,
        availability: formData.availability,
        latitude: formData.latitude,
        longitude: formData.longitude,
        // Set the value to undefined if it's not provided, as 'undefined' is a valid TypeScript type for optional fields
        delivery_date: formData.delivery_date || undefined, 
        built_in: formData.built_in || undefined,
        amenities: formData.amenities,
        photo_data: formData.photo_data,
      };
  
      const response = await submitProperty(submissionData); // Call the submitProperty API with the updated submissionData object
  
      console.log('Property Submission Response:', response);

      setSubmissionSuccess(true);
      setTimeout(() => {
        setIsLoading(false); // Stop loading
        // Clear form data after successful submission
        localStorage.removeItem(localStorageKey);
        navigate('/agent-dashboard'); // Redirect to dashboard
        showMessage('success', 'Your property is published! Thanks for your trust in TimeAZ.');
      }, 20000);
    } catch (error) {
      console.error('Error submitting property:', error);
      setSubmissionError('Failed to submit the property.');
      setIsLoading(false); // Stop loading on error
      showMessage('error', 'Failed to submit the property. Please try again.');
    }
  };  

  return (
    <>
      {isLoading && !isUploading && <LoadingSpinner />}
      {/* Topbar and Breadcrumb */}
      <Topbar />
      <Breadcrumb>
        <a href="/agent-dashboard">Account</a>
        <span>›</span> {/* Unicode arrow symbol */}
        <span>Add a new property</span>
      </Breadcrumb>
      {/* Render Step Content */}
      <StepContainer>
        {step === 1 && (
          <StepOneWrapper>
            <div className="step-1">
              <StepOneSectionHeading>Which of these best describes your property?</StepOneSectionHeading>
              <HomeTypeGrid>
                {homeTypes.map((type) => (
                  <HomeTypeOption
                    key={type.id}
                    onClick={() => setFormData({ ...formData, property_type: type.id })}
                    style={{
                      borderColor: formData.property_type === type.id ? '#0A0708' : '',
                    }}
                  >
                    {type.icon}
                    <span>{type.name}</span>
                  </HomeTypeOption>
                ))}
              </HomeTypeGrid>
              <FixedButtonContainer isFirstStep>
                <NextButton onClick={handleNextStep}>Next</NextButton>
              </FixedButtonContainer>
            </div>
          </StepOneWrapper>
        )}

        {step === 2 && (
          <StepTwoWrapper>
            <SectionHeading>Share some basics about your property</SectionHeading>
            <Subtext>You'll add more details later</Subtext>

            <PropertyInfo>
              <label>Bedrooms:</label>
              <IncrementWrapper className="increment-wrapper">
                <button onClick={() => setFormData({ ...formData, bedrooms: Math.max(1, formData.bedrooms - 1) })}>-</button>
                <div className="value-display">{formData.bedrooms}</div>
                <button onClick={() => setFormData({ ...formData, bedrooms: formData.bedrooms + 1 })}>+</button>
              </IncrementWrapper>

              <label>Bathrooms:</label>
              <IncrementWrapper className="increment-wrapper">
                <button onClick={() => setFormData({ ...formData, bathrooms: Math.max(1, formData.bathrooms - 1) })}>-</button>
                <div className="value-display">{formData.bathrooms}</div>
                <button onClick={() => setFormData({ ...formData, bathrooms: formData.bathrooms + 1 })}>+</button>
              </IncrementWrapper>

              <label>Area:</label>
              <InputField
                type="number"
                name="area"
                value={formData.area}
                onChange={handleChange}
                placeholder="e.g., 65"
              />

              <label>Floor:</label>
              <InputField
                type="number"
                name="floor"
                value={formData.floor}
                onChange={handleChange}
                placeholder="e.g., 5"
              />
              <label>View:</label>
              <ViewOptions>
                {homeViews.map((view: any) => (
                  <button
                    key={view.id}
                    className={formData.view === view.id ? 'active' : ''}
                    onClick={() => setFormData({ ...formData, view: view.id })}
                  >
                    {view.name}
                  </button>
                ))}
              </ViewOptions>
            </PropertyInfo>

            {/* Use ButtonContainer for consistent layout */}
            <FixedButtonContainer>
              <BackButton onClick={handlePrevStep}>
                Back
              </BackButton>
              <NextButton onClick={handleNextStep}>
                Next
              </NextButton>
            </FixedButtonContainer>
          </StepTwoWrapper>
        )}

        {step === 3 && (
          <StepThreeWrapper>
            <StepThreeSectionHeading>Select your property location</StepThreeSectionHeading>
            <DropdownFlexContainer>
              <CustomDropdown
                placeholder="Your property country"
                name="country"
                options={countries}
                value={formData.country}
                onChange={handleChange}
              />
              <CustomDropdown
                placeholder="Your property city"
                name="city"
                options={cities}
                value={formData.city}
                onChange={handleChange}
              />
            </DropdownFlexContainer>

            <MapWrapper>
              <StyledMapContainer center={center} zoom={8.5} style={{ height: '400px', width: '100%' }}>
                <TileLayer
                  url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibW9laW5rIiwiYSI6ImNtMXp1ZXQ0NDBieGYybHB3dWQ5NnUzd2EifQ.fI3xO0TzDrUjcOgGwkuVCQ"
                />
                <PropertyMap onLocationSelect={handleLocationSelect} initialLat={latitude} initialLng={longitude} />
              </StyledMapContainer>
            </MapWrapper>

            <FixedButtonContainer>
              <BackButton onClick={handlePrevStep}>Back</BackButton>
              <NextButton onClick={handleNextStep}>Next</NextButton>
            </FixedButtonContainer>
          </StepThreeWrapper>
        )}

        <Modal open={showConfirmModal} onClose={handleCancelConfirmation}>
          <Modal.Header>
            <Modal.Title>Confirm Property Location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Please confirm that the pin on the map correctly represents your property's location.
              Once the property is created, you cannot change the location.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <BlackButton onClick={handleConfirmLocation} appearance="primary">
              Confirm and Proceed
            </BlackButton>
            <Button onClick={handleCancelConfirmation} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {step === 4 && (
          <StepFourWrapper>
            <StepFourSectionHeading>Tell your clients what your property has to offer</StepFourSectionHeading>
            <AmenitiesGrid>
              {amenities.map((amenity) => (
                <AmenityOption
                  key={amenity.id}
                  className={formData.amenities.includes(amenity.id) ? 'selected' : ''}
                  onClick={() => handleAmenitiesClick(amenity.id)} // Use the new handleAmenitiesClick
                >
                  {amenity.name}
                </AmenityOption>
              ))}
            </AmenitiesGrid>

            <FixedButtonContainer>
              <BackButton onClick={handlePrevStep}>Back</BackButton>
              <NextButton onClick={handleNextStep}>Next</NextButton>
            </FixedButtonContainer>
          </StepFourWrapper>
        )}

        {step === 5 && (
          <StepFiveWrapper>
            <StepFiveHeading>Add some photos of your property</StepFiveHeading>
            <Subtext>At least five photos are required</Subtext>
            <PhotoUploadWrapper>
              <label htmlFor="file-upload" className="upload-icon">
                <FaCamera />
              </label>
              <input
                type="file"
                id="file-upload"
                name="photos"
                multiple
                onChange={handleFileChange}
              />
              <label htmlFor="file-upload" className="add-photo-btn">
                Add photo
              </label>
            </PhotoUploadWrapper>

            {/* Display the upload progress overlay when uploading */}
            {isUploading && (
              <UploadProgressOverlay>
                <ProgressBarContainer>
                  <ProgressBarLabel>Uploading Photos...</ProgressBarLabel>
                  <StyledProgressBar progress={uploadProgress}>
                    <div className="progress"></div>
                  </StyledProgressBar>
                  <ProgressBarLabel>{uploadProgress}%</ProgressBarLabel>
                </ProgressBarContainer>
              </UploadProgressOverlay>
            )}

            {/* Button to open the modal for previewing photos */}
            {formData.photo_data.length > 0 && (
              <PreviewPhotosButton onClick={() => setShowPhotoModal(true)}>
                Arrange Photos
              </PreviewPhotosButton>
            )}

            <FixedButtonContainer>
              <BackButton onClick={handlePrevStep}>Back</BackButton>
              <NextButton onClick={handleNextStep} disabled={formData.photo_data.length < 5}>
                Next
              </NextButton>
            </FixedButtonContainer>
          {/* Modal for previewing and managing photos */}
          {showPhotoModal && (
            <PhotoPreviewModal
              photos={formData.photo_data}
              onClose={() => setShowPhotoModal(false)}
              onDeletePhoto={handleDeletePhoto}
              onRankChange={handleRankChange}
              onUploadMore={handleFileChange}
            />
          )}
          </StepFiveWrapper>
        )}

        {step === 6 && (
          <StepSixWrapper>
            <StepSixHeading>Project Name & Status</StepSixHeading>
            
            {/* Project Name Input */}
            <StyledInput
              type="text"
              name="project_name"
              value={formData.project_name}
              onChange={handleChange}
              placeholder="Project Name"
            />

            {/* Status Selection using CustomDropdown */}
            <NormalDropdown
              label="Project Status"
              name="status"
              options={propertyStatuses.map(status => ({ id: status.id, name: status.name }))}
              value={formData.status}
              onChange={handleChange}
            />

            {/* Conditional Rendering for Delivery Date */}
            {selectedStatus?.requires_delivery_date && (
              <>
                <label>Delivery Date:</label>
                <DatePickerWrapper>
                  <DatePicker
                    selected={formData.delivery_date ? new Date(formData.delivery_date) : null}
                    onChange={(date) => setFormData({
                      ...formData,
                      delivery_date: date ? date.toISOString().substr(0, 7) : ''
                    })}
                    dateFormat="yyyy-MM"
                    showMonthYearPicker
                    wrapperClassName="react-datepicker__input-container"
                  />
                </DatePickerWrapper>
              </>
            )}

            {/* Conditional Rendering for Built-in Date */}
            {selectedStatus?.requires_built_in_date && (
              <>
                <label>Built-in Date:</label>
                <DatePickerWrapper>
                  <DatePicker
                    selected={formData.built_in ? new Date(formData.built_in) : null}
                    onChange={(date) => setFormData({
                      ...formData,
                      built_in: date ? date.toISOString().substr(0, 7) : ''
                    })}
                    dateFormat="yyyy-MM"
                    showMonthYearPicker
                    wrapperClassName="react-datepicker__input-container"
                  />
                </DatePickerWrapper>
              </>
            )}

            {/* Button Container */}
            <FixedButtonContainer>
              <BackButton onClick={handlePrevStep}>Back</BackButton>
              <NextButton onClick={handleNextStep}>Next</NextButton>
            </FixedButtonContainer>
          </StepSixWrapper>
        )}


        {step === 7 && (
          <StepSevenWrapper>
            <StepSevenHeading>Creat your property description</StepSevenHeading>
            
            {/* Description Input Field */}
            <DescriptionTextArea
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Write a detailed description about the property"
            />

            {/* Button Container */}
            <FixedButtonContainer>
              <BackButton onClick={handlePrevStep}>Back</BackButton>
              <NextButton onClick={handleNextStep}>Next</NextButton>
            </FixedButtonContainer>
          </StepSevenWrapper>
        )}

        {step === 8 && (
          <StepEightWrapper>
            <StepEightHeading>Set Price</StepEightHeading>

            {/* Price Input Field */}
            <PriceInputContainer>
              <StyledCurrencyInput
                id="price-input"
                name="price"
                placeholder="Enter price"
                defaultValue={formData.price}
                decimalsLimit={2}
                onValueChange={(value) => {
                  if (value) {
                    setFormData({ ...formData, price: value });
                  }
                }}
                prefix={formData.currency} // Attach the selected currency as prefix
              />

              {/* Currency Dropdown */}
              <CurrencyDropdown
                name="currency"
                value={formData.currency}
                onChange={handleChange}
              >
                {currencies.map((currency) => (
                  <option key={currency.code} value={currency.symbol}>
                    {currency.name} ({currency.symbol})
                  </option>
                ))}
              </CurrencyDropdown>
            </PriceInputContainer>

            {/* Button Container */}
            <FixedButtonContainer>
              <BackButton onClick={handlePrevStep}>Back</BackButton>
              <NextButton onClick={handleNextStep}>Next</NextButton>
            </FixedButtonContainer>
          </StepEightWrapper>
        )}

        {step === 9 && (
          <>
            <PropertyReview
              formData={{
                ...formData,
                city: cities.find(city => city.id === formData.city)?.name || '',
                country: countries.find(country => country.id === formData.country)?.name || '',
                status: propertyStatuses.find(status => status.id === formData.status) || {
                  id: 0,
                  name: 'Unknown',
                  requires_delivery_date: false,
                  requires_built_in_date: false,
                },
                view: homeViews.find(view => view.id === formData.view)?.name || '',
                property_type: homeTypes.find(type => type.id === formData.property_type)?.name || '',
                amenities: formData.amenities.map(amenityId =>
                  amenities.find(amenity => amenity.id === amenityId)?.name || ''
                ),
              }}
            />

            {/* Button Container for Back and Publish */}
            <ButtonContainer>
              <BackButton onClick={handlePrevStep}>Back</BackButton>
              <NextButton onClick={handleSubmit}>Publish Property</NextButton>
            </ButtonContainer>
          </>
        )}
      </StepContainer>
    </>
  );
};

export default PropertyCreationForm;
