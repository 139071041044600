import styled from 'styled-components';

// Grid to display amenities
export const AmenitiesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Adjust the number of columns */
  column-gap: 0.5rem; /* Adjust this value to control the space between columns */
  row-gap: 0.5rem; /* Adjust the space between rows */
  justify-items: center; /* Center grid items horizontally */
  margin: 0rem 0;
  max-width: 1200px; /* Maximum width to control layout */
  padding: 1rem;
`;

// Individual Amenity Option styling
export const AmenityOption = styled.div`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  padding: 1rem 1.5rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;
  width: 250px; /* Adjust the width for the amenity box */

  &.selected {
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
  }
`;

// Wrapper for centering the grid
export const StepFourWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;


// Section Heading
export const StepFourSectionHeading = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 3rem;
`;
