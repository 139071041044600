import styled from 'styled-components';
import { DefaultTheme } from 'styled-components';

// Container for the login page
export const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.background};
  padding: ${({ theme }: { theme: DefaultTheme }) => theme.spacing.p3}; /* Add padding for better spacing */

  ${({ theme }: { theme: DefaultTheme }) => theme.media.large`
    padding-top: 100px;
  `}

  ${({ theme }: { theme: DefaultTheme }) => theme.media.small`
    padding: ${theme.spacing.p2}; /* Adjust padding for small screens */
  `}
`;

// Styled box for the login form
export const LoginBox = styled.div`
  background-color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.background};
  padding: ${({ theme }: { theme: DefaultTheme }) => theme.spacing.p4}; /* Use theme spacing */
  border-radius: ${({ theme }: { theme: DefaultTheme }) => theme.borderRadius.medium}; /* Use theme border radius */
  box-shadow: ${({ theme }: { theme: DefaultTheme }) => theme.boxShadow}; /* Use theme box shadow */
  max-width: 400px;
  width: 100%;
  text-align: center;

  ${({ theme }: { theme: DefaultTheme }) => theme.media.medium`
    padding: ${theme.spacing.p3}; /* Adjust padding for medium screens */
    max-width: 350px; /* Reduce the maximum width for medium screens */
  `}

  ${({ theme }: { theme: DefaultTheme }) => theme.media.small`
    padding: ${theme.spacing.p2}; /* Further adjust padding for small screens */
    max-width: 90%; /* Expand the box width for small screens */
  `}
`;

export const Logo = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.m4};

  img {
    height: 100px; /* Adjust the height of the logo */
    width: auto; /* Maintain aspect ratio */
  }

  ${({ theme }) => theme.media.medium`
    img {
      height: 100px; /* Adjust logo size on medium screens */
    }
  `}

  ${({ theme }) => theme.media.small`
    img {
      height: 80px; /* Adjust logo size on small screens */
    }
  `}

  ${({ theme }) => theme.media.extraSmall`
    margin-bottom: 1rem;
    img {
      height: 60px; /* Adjust logo size on small screens */
    }
  `}
`;

// Input styling for the login form
export const LoginInput = styled.input`
  width: 100%;
  padding: ${({ theme }: { theme: DefaultTheme }) => theme.spacing.p2}; /* Use theme padding */
  margin-bottom: ${({ theme }: { theme: DefaultTheme }) => theme.spacing.m3}; /* Use theme spacing */
  border: 1px solid ${({ theme }: { theme: DefaultTheme }) => theme.colors.border}; /* Use theme border color */
  border-radius: ${({ theme }: { theme: DefaultTheme }) => theme.borderRadius.small}; /* Use theme border radius */
  font-size: ${({ theme }: { theme: DefaultTheme }) => theme.typography.body.fontSize}; /* Use theme font size */
  color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.text}; /* Use theme text color */

  ${({ theme }: { theme: DefaultTheme }) => theme.media.small`
    padding: ${theme.spacing.p1}; /* Adjust padding for small screens */
    font-size: ${theme.typography.small.fontSize}; /* Adjust font size for small screens */
  `}
`;

// Button styling for the login form
export const LoginButton = styled.button`
  width: 100%;
  padding: ${({ theme }: { theme: DefaultTheme }) => theme.spacing.p3}; /* Use theme padding */
  background-color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.primary}; /* Use theme primary color */
  color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.background}; /* Use theme background color */
  border: none;
  border-radius: ${({ theme }: { theme: DefaultTheme }) => theme.borderRadius.medium}; /* Use theme border radius */
  font-size: ${({ theme }: { theme: DefaultTheme }) => theme.typography.body.fontSize}; /* Use theme font size */
  cursor: pointer;
  transition: ${({ theme }: { theme: DefaultTheme }) => theme.transition}; /* Use theme transition */

  &:hover {
    background-color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.primaryLight}; /* Lighten background on hover */
  }

  ${({ theme }: { theme: DefaultTheme }) => theme.media.small`
    padding: ${theme.spacing.p2}; /* Adjust padding for small screens */
    font-size: ${theme.typography.small.fontSize}; /* Adjust font size for small screens */
  `}

  ${({ theme }: { theme: DefaultTheme }) => theme.media.extraSmall`
    padding: ${theme.spacing.p1}; /* Adjust padding for extra small screens */
    font-size: ${theme.typography.xSmall.fontSize}; /* Use xSmall font size */
  `}
`;
