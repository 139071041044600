import React, { useState, useEffect } from 'react';
import { Message, useToaster } from 'rsuite';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getPublicAgentProfile,
  getAgentReviewSummary,
  getReviewCategories,
  submitReview,
  AgentProfile,
  AgentReviewSummary,
  ReviewCategory,
} from '../../services/agentService';
import {
  ProgressBar,
  AboutSection,
  ProfileCard,
  CategoryGrid,
  ReviewCardGrid,
  ReviewWrapper,
  ReviewCard,
} from '../../styles/AgentProfileStyles';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import UserTopBar from '../../components/common/UserTopBar';
import {
  ProfileWrapper,
  WriteReviewContainer,
  ReviewForm,
  ReviewCategoryWrapper,
  RatingButton,
  SubmitButton,
  ErrorMessage,
} from '../../styles/AgentProfilePageStyles';

const AgentProfilePage: React.FC = () => {
  const { agentId } = useParams<{ agentId: string }>(); // Get agent ID from URL
  const navigate = useNavigate();
  const [agentProfile, setAgentProfile] = useState<AgentProfile | null>(null); // State to hold agent profile
  const [reviewSummary, setReviewSummary] = useState<AgentReviewSummary | null>(null); // State to hold review summary
  const [reviewCategories, setReviewCategories] = useState<ReviewCategory[]>([]); // State to hold review categories
  const [reviewText, setReviewText] = useState<string>(''); // State to hold the text review input
  const [ratings, setRatings] = useState<{ [categoryId: number]: number }>({}); // State to hold ratings for each category
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null); // State to handle error messages
  const [formError, setFormError] = useState<string | null>(null); // State for form validation errors
  const toaster = useToaster();

  // Use a key specific to the agent for saving drafts in localStorage
  const draftKey = `reviewDraft-agent-${agentId}`;  

  // Save draft review data to localStorage
  const saveDraftReview = () => {
    const draftData = {
      reviewText,
      ratings,
    };
    localStorage.setItem(draftKey, JSON.stringify(draftData));
  };

  // Retrieve draft review data from localStorage
  const loadDraftReview = () => {
    const savedDraft = localStorage.getItem(draftKey);
    if (savedDraft) {
      const parsedDraft = JSON.parse(savedDraft);
      setReviewText(parsedDraft.reviewText || '');
      setRatings(parsedDraft.ratings || {});
    }
  };

  // Remove draft review data from localStorage
  const clearDraftReview = () => {
    localStorage.removeItem(draftKey);
  };

  // Fetch agent details, review summary, and categories on component mount or when agentId changes
  useEffect(() => {
    async function fetchAgentProfile() {
      if (!agentId) return;

      setIsLoading(true);
      try {
        // Fetch agent profile data, review summary, and review categories
        const profile = await getPublicAgentProfile(parseInt(agentId));
        const summary = await getAgentReviewSummary(parseInt(agentId));
        const categories = await getReviewCategories();

        setAgentProfile(profile);
        setReviewSummary(summary);
        setReviewCategories(categories);
      } catch (err) {
        setError('Failed to load agent details.');
      } finally {
        setIsLoading(false);
      }
    }

    fetchAgentProfile();
  }, [agentId]);

  // Load draft review when component mounts or agentId changes
  useEffect(() => {
    loadDraftReview();
  }, [agentId]);

  // Handle review submission
  const handleReviewSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate that all categories are rated before submission
    if (!validateRatings()) {
      toaster.push(
        <Message showIcon type="error">
          Please provide a rating for each category.
        </Message>,
        { placement: 'topCenter' }
      );
      return;
    }

    const token = localStorage.getItem('access_token');
    if (!token) {
      // Save draft and redirect to login
      saveDraftReview();
      navigate('/login', { state: { from: window.location.pathname } });
      return;
    }

    if (!agentProfile) return;

    const reviewData = {
      agent: agentProfile.id,
      text_review: reviewText,
      ratings: reviewCategories.map((category) => ({
        category: { id: category.id, name: category.name },
        score: ratings[category.id], // Default to 0 if not rated
      })),
    };

    try {
      await submitReview(reviewData);
      toaster.push(
        <Message showIcon type="success">
          Review submitted successfully!
        </Message>,
        { placement: 'topCenter' }
      );

      // Re-fetch updated review summary after submission
      const updatedSummary = await getAgentReviewSummary(agentProfile.id);
      setReviewSummary(updatedSummary);
      setReviewText(''); // Clear text review input
      setRatings({}); // Clear rating inputs
      setFormError(null); // Clear form error
      clearDraftReview(); // Clear draft after successful submission
    } catch (err: any) {
      // Handle backend validation error
      if (err.response && err.response.data && err.response.data.detail) {
        toaster.push(
          <Message showIcon type="error">
            {err.response.data.detail}
          </Message>,
          { placement: 'topCenter' }
        );
      } else {
        toaster.push(
          <Message showIcon type="error">
            Failed to submit review. Please try again.
          </Message>,
          { placement: 'topCenter' }
        );
      }
    }
  };

  // Validate that all categories are rated
  const validateRatings = (): boolean => {
    return reviewCategories.every((category) => ratings[category.id] !== undefined);
  };

  // Handle rating change for each review category
  const handleRatingChange = (categoryId: number, score: number, e: React.MouseEvent) => {
    e.preventDefault(); // Prevent the default behavior of form submission
    setRatings({ ...ratings, [categoryId]: score });
    setFormError(null); // Clear form error when user selects a rating
  };

  const formatTimeOnPlatform = (years: number, months: number) => {
    if (years === 0 && months === 0) {
      return 'New on TimeAZ';
    } else if (years > 0 && months > 0) {
      return `${years} year${years > 1 ? 's' : ''} and ${months} month${months > 1 ? 's' : ''} on TimeAZ`;
    } else if (years > 0) {
      return `${years} year${years > 1 ? 's' : ''} on TimeAZ`;
    } else if (months > 0) {
      return `${months} month${months > 1 ? 's' : ''} on TimeAZ`;
    }
    return '';
  };  

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <UserTopBar />
      <ProfileWrapper>
        {agentProfile && (
          <ProfileCard>
            <div className="left-section">
              <img src={agentProfile.photo} alt={agentProfile.preferred_name} className="agent-photo" />
              <div className="agent-info">
                <h2>{agentProfile.preferred_name}</h2>
              </div>
            </div>
            <div className="right-section">
              <div className="rating-review">
                <span className="rating">{reviewSummary?.overall_average_rating?.toFixed(1)}</span>
                <span className="divider">|</span>
                <span className="review-count">{reviewSummary?.review_count} reviews</span>
              </div>
              <p className="time-on-platform">
                {formatTimeOnPlatform(agentProfile.years_on_platform || 0, agentProfile.months_on_platform || 0)}
              </p>
            </div>
          </ProfileCard>
        )}

        {agentProfile && (
          <AboutSection>
            <h3>About {agentProfile.preferred_name}</h3>
            <p>🌐 Speaks {agentProfile.spoken_language}</p>
            <p>{agentProfile.about}</p>
          </AboutSection>
        )}

        {reviewSummary && (
          <ReviewWrapper>
            <h3>What clients are saying about {agentProfile?.preferred_name}</h3>
            <CategoryGrid>
              {Object.entries(reviewSummary.average_ratings).map(([category, rating]) => (
                <ProgressBar key={category}>
                  <div className="bar-wrapper">
                    <span className="category-label">{category}</span>
                    <span className="score">{rating.toFixed(1)}</span>
                  </div>
                  <div className="bar">
                    <div
                      className="fill"
                      style={{ width: `${(rating / 10) * 100}%` }} // Convert rating to percentage
                    ></div>
                  </div>
                </ProgressBar>
              ))}
            </CategoryGrid>
            <ReviewCardGrid>
              {reviewSummary.reviews.map((review) => (
                <ReviewCard key={review.id}>
                  <h5>{review.username}</h5>
                  <p>"{review.text_review}"</p>
                  <p>{new Date(review.created_at).toLocaleDateString()}</p>
                </ReviewCard>
              ))}
            </ReviewCardGrid>
          </ReviewWrapper>
        )}

        {/* Write a Review Section */}
        <WriteReviewContainer>
          <h3>Write a Review for {agentProfile?.preferred_name}</h3>
          <ReviewForm onSubmit={handleReviewSubmit}>
            <ReviewCategoryWrapper>
              {reviewCategories.map((category) => (
                <div key={category.id} className="rating-item">
                  <label className="category-label">{category.name}</label>
                  <div className="rating-buttons">
                    {Array.from(Array(10).keys()).map((_, index) => (
                      <RatingButton
                        key={index + 1}
                        selected={ratings[category.id] === index + 1}
                        onClick={(e) => handleRatingChange(category.id, index + 1, e)}
                      >
                        {index + 1}
                      </RatingButton>
                    ))}
                  </div>
                </div>
              ))}
            </ReviewCategoryWrapper>
            {formError && <ErrorMessage>{formError}</ErrorMessage>}
            <label>Review</label>
            <textarea
              value={reviewText}
              onChange={(e) => setReviewText(e.target.value)}
              required
            />

            {/* Submit Button */}
            <SubmitButton type="submit">Submit Review</SubmitButton>
          </ReviewForm>
        </WriteReviewContainer>
      </ProfileWrapper>
    </>
  );
};

export default AgentProfilePage;
