// components/common/ConfirmationModal.tsx
import React from 'react';
import styled from 'styled-components';

interface ConfirmationModalProps {
  isOpen: boolean;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

// Define the props type for ModalOverlay to accept `isOpen`
interface ModalOverlayProps {
  isOpen: boolean;
}

// Styled components with correctly typed props
const ModalOverlay = styled.div<ModalOverlayProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};  /* Fix typing here */
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => theme.spacing.p4};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  text-align: center;
  width: 300px;
  box-shadow: ${({ theme }) => theme.boxShadow};
`;

const ModalMessage = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.m3};
  color: ${({ theme }) => theme.colors.text};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.m2};
`;

const ConfirmButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.background};
  border: none;
  padding: ${({ theme }) => theme.spacing.p2};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

const CancelButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primaryDark};
  color: ${({ theme }) => theme.colors.background};
  border: none;
  padding: ${({ theme }) => theme.spacing.p2};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  cursor: pointer;
`;

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  message,
  onConfirm,
  onCancel,
}) => {
  return (
    <ModalOverlay isOpen={isOpen}>
      <ModalContent>
        <ModalMessage>{message}</ModalMessage>
        <ButtonContainer>
          <CancelButton onClick={onCancel}>Cancel</CancelButton>
          <ConfirmButton onClick={onConfirm}>Open</ConfirmButton>
        </ButtonContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ConfirmationModal;
