import React, { useState } from 'react';
import { FcGoogle } from 'react-icons/fc'; // Import Google icon from react-icons
import styled from 'styled-components';

interface CustomGoogleButtonProps {
  onClick: () => void; // Callback function for the button click
}

const CustomGoogleButton: React.FC<CustomGoogleButtonProps> = ({ onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Handlers for hover effect
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <GoogleButton
      onClick={onClick}
      isHovered={isHovered}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <FcGoogle style={{ marginRight: '10px' }} size={24} />
      <span>Sign in with Google</span>
    </GoogleButton>
  );
};

// Styled-component for the Google Button
const GoogleButton = styled.button<{ isHovered: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Full width on smaller screens */
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  background-color: ${({ isHovered }) => (isHovered ? '#f7f7f7' : '#ffffff')};
  border: ${({ isHovered }) => (isHovered ? '1px solid #ccc' : '1px solid #ddd')};
  color: #444;
  cursor: pointer;
  box-shadow: ${({ isHovered }) =>
    isHovered ? '0 4px 6px rgba(0, 0, 0, 0.15)' : '0 2px 4px rgba(0, 0, 0, 0.1)'};
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
  outline: none;
  margin-top: 20px;

  // Responsive styles for different screen sizes
  @media (max-width: 768px) {
    padding: 10px 14px;
    font-size: 14px;
  }

  @media (max-width: 576px) {
    padding: 8px 12px;
    font-size: 13px;
  }

  @media (min-width: 1024px) {
    padding: 12px 20px;
    font-size: 18px;
  }
`;

export default CustomGoogleButton;
