import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getAgentProperties,
  ListedProperty,
} from '../../services/propertyService';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import PhotoCarousel from '../../components/property/PhotoCarousel'; // Reuse the photo carousel component
import {
  SoldPropertiesContainer,
  PropertiesGrid,
  PropertyCard,
  PropertyDetails,
  SoldPropertiesHeading,
} from '../../styles/SoldPropertiesStyles'; // Reuse styles from listed properties
import Topbar from '../../components/common/Topbar';
import { Breadcrumb } from '../../styles/PropertyCreationStepOneStyles';

const SoldPropertiesTab: React.FC = () => {
  const [properties, setProperties] = useState<ListedProperty[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const allProperties = await getAgentProperties();
        // Filter only sold properties (assuming availability 2 means sold)
        const soldProperties = allProperties.filter(property => property.availability === 2);
        setProperties(soldProperties);
      } catch (err) {
        // Show error using Toastify
        setError('Failed to load sold properties.');
        toast.error('Failed to load sold properties.', {
          position: 'top-center',
          autoClose: 3000,
        });
      } finally {
        setIsLoading(false);  // End loading when fetching is done
      }
    }

    fetchData();
  }, []);

  const formatPrice = (price: string) => {
    const number = parseFloat(price);
    return new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 2 }).format(number);
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <Topbar />
      <Breadcrumb>
        <a href="/agent-dashboard">Account</a>
        <span>›</span> {/* Unicode arrow symbol */} 
        <span>Sold Properties</span>
      </Breadcrumb>

      <SoldPropertiesContainer>
        <SoldPropertiesHeading>You have sold </SoldPropertiesHeading>

        <PropertiesGrid>
          {properties.map(property => (
            <PropertyCard key={property.id}>
              {/* Reuse the PhotoCarousel component */}
              <PhotoCarousel photos={property.photos} />

              {/* Property Information */}
              <PropertyDetails>
                <h3> Sold it for {`${property.currency}${formatPrice(property.price)}`}</h3> 
                <p><strong>Sold Date:</strong> {property.sold_date ? property.sold_date : 'N/A'}</p>
              </PropertyDetails>
            </PropertyCard>
          ))}
        </PropertiesGrid>
      </SoldPropertiesContainer>
    </>
  );
};

export default SoldPropertiesTab;
