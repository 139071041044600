// src/components/common/CustomDropdown.tsx
import React, { useState, useEffect, useRef } from 'react';
import { FaChevronDown, FaMapMarkerAlt } from 'react-icons/fa';
import {
  DropdownWrapper,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
} from '../../styles/NormalDropdownStyles';

interface Option {
  id: number;
  name: string;
}

interface CustomDropdownProps {
  label: string;
  name: string;
  options: Option[];
  value: number;
  onChange: (event: { target: { name: string; value: number } }) => void;
}

const NormalDropdown: React.FC<CustomDropdownProps> = ({
  label,
  name,
  options,
  value,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const selectedOption = options.find((option) => option.id === value);

  const handleOptionClick = (id: number) => {
    onChange({ target: { name, value: id } });
    setIsOpen(false);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownWrapper ref={dropdownRef}>
      <label>{label}</label>
      <DropdownButton onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
        {selectedOption ? selectedOption.name : 'Select'}
        <FaChevronDown />
      </DropdownButton>
      {isOpen && (
        <DropdownMenu>
        {options.map((option) => (
            <DropdownItem key={option.id} onClick={() => handleOptionClick(option.id)}>
            {option.name}
            </DropdownItem>
        ))}
        </DropdownMenu>
      )}
    </DropdownWrapper>
  );
};

export default NormalDropdown;
