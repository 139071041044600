import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Main Dashboard Container
export const DashboardContainer = styled.div`
  max-width: 900px;
  margin: ${({ theme }) => theme.spacing.m4} auto;
  padding: 0 ${({ theme }) => theme.spacing.p4};

  /* Adjust padding and margin for medium and smaller screens */
  ${({ theme }) => theme.media.medium`
    padding: 1rem; /* Decrease horizontal padding */
    margin: ${theme.spacing.m3} auto;
  `}

  ${({ theme }) => theme.media.small`
    padding: 1rem; /* Further decrease horizontal padding */
    margin: ${theme.spacing.m2} auto;
  `}

  ${({ theme }) => theme.media.extraSmall`
    padding: 1rem; /* Minimal padding for extra small screens */
    margin: ${theme.spacing.m1} auto;
  `}
`;

// Dashboard Heading
export const Heading = styled.h1`
  font-size: ${({ theme }) => theme.typography.h1.fontSize};
  font-weight: ${({ theme }) => theme.typography.h1.fontWeight};
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: ${({ theme }) => theme.spacing.m3};

  /* Adjust font size for different screen sizes */
  ${({ theme }) => theme.media.medium`
    font-size: ${theme.typography.h2.fontSize}; /* Decrease font size */
  `}

  ${({ theme }) => theme.media.small`
    font-size: ${theme.typography.h3.fontSize}; /* Further decrease font size */
  `}

  ${({ theme }) => theme.media.extraSmall`
    font-size: ${theme.typography.body.fontSize}; /* Use body size for smallest screens */
    margin-bottom: ${theme.spacing.m2}; /* Decrease bottom margin */
  `}
`;

// Dashboard Grid for Cards
export const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing.m3};
  margin-top: ${({ theme }) => theme.spacing.m2};

  /* Single column layout for small and extra-small screens */
  ${({ theme }) => theme.media.small`
    grid-template-columns: 1fr; /* Single column layout */
    gap: ${theme.spacing.m2}; /* Reduce gap between cards */
    
  `}

  ${({ theme }) => theme.media.extraSmall`
    grid-template-columns: 1fr; /* Single column layout */
    gap: ${theme.spacing.m1}; /* Minimal gap for extra small screens */
  `}
`;

// Dashboard Card
export const DashboardCard = styled(Link)`
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  padding: ${({ theme }) => theme.spacing.p3};
  text-align: center;
  box-shadow: ${({ theme }) => theme.boxShadow};
  transition: box-shadow ${({ theme }) => theme.transition};
  position: relative; /* Added position relative for badge positioning */

  h3 {
    font-size: ${({ theme }) => theme.typography.h3.fontSize}; /* Use h2 font size */
    color: ${({ theme }) => theme.colors.text};
  }

  p {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.typography.body.fontSize};
  }

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  svg {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: ${({ theme }) => theme.spacing.m2};
  }

  /* Adjust font size and padding for smaller screens */
  ${({ theme }) => theme.media.medium`
    padding: ${theme.spacing.p3}; /* Decrease padding for medium screens */

    h3 {
      font-size: ${theme.typography.h3.fontSize}; /* Decrease h3 font size */
    }

    p {
      font-size: ${theme.typography.small.fontSize}; /* Use small font size for paragraph */
    }

    svg {
      font-size: 1.8rem; /* Decrease icon size */
      margin-bottom: ${theme.spacing.m1}; /* Decrease margin */
    }
  `}

  ${({ theme }) => theme.media.small`
    padding: ${theme.spacing.p2}; /* Further decrease padding for small screens */
    h3 {
      font-size: ${theme.typography.body.fontSize}; /* Use body font size */
    }
    p {
      font-size: ${theme.typography.xSmall.fontSize}; /* Use extra small font size */
    }
    svg {
      font-size: 1.5rem; /* Reduce icon size */
      margin-bottom: ${theme.spacing.m1}; /* Adjust margin */
    }
  `}

  ${({ theme }) => theme.media.extraSmall`
    padding: ${theme.spacing.p1}; /* Further decrease padding for small screens */
    h3 {
      font-size: ${theme.typography.body.fontSize}; /* Use body font size */
    }
    p {
      font-size: ${theme.typography.xxSmall.fontSize}; /* Use extra small font size */
    }
    svg {
      font-size: 1.5rem; /* Reduce icon size */
      margin-bottom: ${theme.spacing.m2}; /* Adjust margin */
    }
  `}
`;

// Badge for unread messages
export const Badge = styled.div`
  position: absolute;
  top: -8px;  /* Adjusted position */
  right: -8px; /* Adjusted position */
  background-color: ${({ theme }) => theme.colors.danger}; /* Use a red color to indicate urgency */
  color: white;
  border-radius: 50%;
  padding: 0.3rem 0.7rem;
  font-size: 0.8rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Styled Button for Logout or Other Actions
export const ButtonStyled = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.background};
  border: none;
  padding: ${({ theme }) => theme.spacing.p2};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.button.fontSize};
  font-weight: ${({ theme }) => theme.typography.button.fontWeight};
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }

  /* Adjust padding and font size for smaller screens */
  ${({ theme }) => theme.media.small`
    font-size: ${theme.typography.small.fontSize};
    padding: ${theme.spacing.p1};
  `}

  ${({ theme }) => theme.media.extraSmall`
    font-size: ${theme.typography.xSmall.fontSize};
    padding: ${theme.spacing.p1};
  `}
`;

// DashboardButton for button-like behavior
export const DashboardButton = styled.button`
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  padding: ${({ theme }) => theme.spacing.p4};
  text-align: center;
  box-shadow: ${({ theme }) => theme.boxShadow};
  transition: ${({ theme }) => theme.transition};
  cursor: pointer;

  h3 {
    font-size: ${({ theme }) => theme.typography.h3.fontSize};
    color: ${({ theme }) => theme.colors.text};
  }

  p {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.typography.body.fontSize};
  }

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  svg {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: ${({ theme }) => theme.spacing.m2};
  }

  /* Adjust font sizes and padding for smaller screens */
  ${({ theme }) => theme.media.medium`
    padding: ${theme.spacing.p3};
    h3 {
      font-size: ${theme.typography.h3.fontSize};
    }
    p {
      font-size: ${theme.typography.small.fontSize};
    }
    svg {
      font-size: 1.8rem;
      margin-bottom: ${theme.spacing.m1};
    }
  `}

  ${({ theme }) => theme.media.small`
    padding: ${theme.spacing.p2};
    h3 {
      font-size: ${theme.typography.body.fontSize};
    }
    p {
      font-size: ${theme.typography.xSmall.fontSize};
    }
    svg {
      font-size: 1.5rem;
      margin-bottom: ${theme.spacing.m1};
    }
  `}
`;
