import React, { useEffect, useState } from 'react';
import { AgentProfile, getAgentProfile, updateAgentProfile, getAgentReviewSummary, AgentReviewSummary } from '../../services/agentService';
import { ProfileCard, ReviewCardGrid, CategoryGrid, ProgressBar, AboutSection, 
        ReviewCard, Breadcrumb, ProfileWrapper, ConfirmedInfoCard, ReviewWrapper, 
        ModalOverlay, ModalContent, CloseButton, ShowAllReviewsLink } from '../../styles/AgentProfileStyles';
import Topbar from '../../components/common/Topbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../../components/common/LoadingSpinner';

const AgentProfileModal: React.FC = () => {
  const [profile, setProfile] = useState<AgentProfile | null>(null);
  const [reviewSummary, setReviewSummary] = useState<AgentReviewSummary | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [showReviewsModal, setShowReviewsModal] = useState(false);

  useEffect(() => {
    const fetchAgentProfileAndReviews = async () => {
      setIsLoading(true);
      try {
        const profileData = await getAgentProfile();
        setProfile(profileData);

        if (profileData?.id) {
          const reviewData = await getAgentReviewSummary(profileData.id);
          setReviewSummary(reviewData);
        }
      } catch (err) {
        console.error('Failed to load agent profile or reviews:', err);
        setError('Failed to load agent profile or reviews.');
      } finally {
        setIsLoading(false);  // End loading when fetching is done
      }
    };

    fetchAgentProfileAndReviews();
  }, []);

  const handleSave = async () => {
    if (profile) {
      const formData = new FormData();

      if (imageFile) {
        formData.append('photo', imageFile);
      }

      formData.append('spoken_language', profile.spoken_language);
      formData.append('about', profile.about);

      try {
        await updateAgentProfile(formData);
        setEditMode(false);
      } catch (err) {
        console.error('Failed to update agent profile:', err);
        setError('Failed to update agent profile.');
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => (prevProfile ? { ...prevProfile, [name]: value } : null));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  const formatTimeOnPlatform = (years: number, months: number) => {
    if (years === 0 && months === 0) {
      return 'New on TimeAZ';
    } else if (years > 0 && months > 0) {
      return `${years} year${years > 1 ? 's' : ''} and ${months} month${months > 1 ? 's' : ''} on TimeAZ`;
    } else if (years > 0) {
      return `${years} year${years > 1 ? 's' : ''} on TimeAZ`;
    } else if (months > 0) {
      return `${months} month${months > 1 ? 's' : ''} on TimeAZ`;
    }
    return '';
  };

  if (error) return <div>{error}</div>;

  return (
    <> 
      {isLoading && <LoadingSpinner />}
      <Topbar />
      <Breadcrumb>
        <a href="/agent-dashboard">Account</a>
        <span>›</span> {/* Unicode arrow symbol */}
        <span>Add a new property</span>
      </Breadcrumb>

      <ProfileWrapper>
        {/* Profile Card */}
        <ProfileCard>
        <div className="left-section">
            <img src={profile?.photo} alt={profile?.preferred_name} className="agent-photo" />
            {editMode && (
              <>
                {/* Camera Edit Icon for Editing the Photo */}
                <label htmlFor="file-input" className="edit-icon">
                  <FontAwesomeIcon icon={faCamera} />
                </label>
                <input
                  id="file-input"
                  type="file"
                  name="photo"
                  onChange={handleFileChange}
                  className="form-control"
                />
              </>
            )}
          <div className="agent-info">
            <h2>{profile?.preferred_name}</h2>
          </div>
        </div>

          <div className="right-section">
            <div className="rating-review">
              <span className="rating">{reviewSummary?.overall_average_rating.toFixed(1)}</span>
              <span className="divider">|</span>
              <span className="review-count">{reviewSummary?.review_count} reviews</span>
            </div>
            <p className="time-on-platform">
                {formatTimeOnPlatform(profile?.years_on_platform || 0, profile?.months_on_platform || 0)}
            </p>
          </div>
        </ProfileCard>

        {/* Confirmed Information Section */}
        <ConfirmedInfoCard>
          <h3>{profile?.preferred_name}'s confirmed information</h3>
          <ul>
            <li>Associated company</li>
            <li>Address</li>
            <li>Email</li>
            <li>Phone</li>
          </ul>
        </ConfirmedInfoCard>

        <AboutSection>
          <h3>About {profile?.preferred_name}</h3>
          {!editMode ? (
            <>
              <button className="edit-button" onClick={() => setEditMode(true)}>
                Edit Profile
              </button>
              <p>🌐 Speaks {profile?.spoken_language}</p>
              <p>{profile?.about}</p>
            </>
          ) : (
            <>
              <label>Spoken Language:</label>
              <input
                type="text"
                name="spoken_language"
                value={profile?.spoken_language || ''}
                onChange={handleChange}
              />
              <label>About:</label>
              <textarea
                name="about"
                value={profile?.about || ''}
                onChange={handleChange}
              />

              {/* Save and Cancel buttons */}
              <div className="action-buttons">
                <button onClick={handleSave} className="save-button">Save</button>
                <button onClick={() => setEditMode(false)} className="cancel-button">Cancel</button>
              </div>
            </>
          )}
        </AboutSection>

        <ReviewWrapper>
          <h3>What clients are saying about {profile?.preferred_name}</h3>
          
          {reviewSummary && (
            <CategoryGrid>
              {Object.entries(reviewSummary.average_ratings).map(([category, rating]) => (
                <ProgressBar key={category}>
                  <div className="bar-wrapper">
                    <span className="category-label">{category}</span>
                    <span className="score">{rating.toFixed(1)}</span>
                  </div>
                  <div className="bar">
                    <div
                      className="fill"
                      style={{ width: `${(rating / 10) * 100}%` }} // Convert rating to percentage
                    ></div>
                  </div>
                </ProgressBar>
              ))}
            </CategoryGrid>
          )}
          {/* Review Cards in Grid */}
          <ReviewCardGrid>
            {reviewSummary?.reviews.slice(0, 2).map((review) => (
              <ReviewCard key={review.id}>
                <h5>{review.username}</h5>
                <p>"{review.text_review}"</p>
                <p>{new Date(review.created_at).toLocaleDateString()}</p>
              </ReviewCard>
            ))}
          </ReviewCardGrid>

          {/* Styled Link for Show All Reviews */}
          <ShowAllReviewsLink onClick={() => setShowReviewsModal(true)}>
            Show all {reviewSummary?.review_count} reviews
          </ShowAllReviewsLink>
        </ReviewWrapper>
      </ProfileWrapper>

      {/* Modal for showing all reviews */}
      {showReviewsModal && (
        <ModalOverlay>
          <ModalContent>
            <CloseButton onClick={() => setShowReviewsModal(false)}>×</CloseButton>
            <h2>All Reviews</h2>
            <div className="review-cards-container">
              {reviewSummary?.reviews.map((review) => (
                <ReviewCard key={review.id} inModal={true}> {/* Pass the inModal prop */}
                  <h5>{review.username}</h5>
                  <p>"{review.text_review}"</p>
                  <p>{new Date(review.created_at).toLocaleDateString()}</p>
                </ReviewCard>
              ))}
            </div>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};
export default AgentProfileModal;
