import api from './api';
import { BlockBlobClient } from '@azure/storage-blob';
import { v4 as uuidv4 } from 'uuid';

// Define interfaces for each entity

export interface Amenity {
  id: number;
  name: string;
}

export interface City {
  id: number;
  name: string;
}

export interface Country {
  id: number;
  name: string;
}

export interface HomeType {
  id: number;
  name: string;
}

export interface HomeView {
  id: number;
  name: string;
}

export interface PropertyStatus {
  id: number;
  name: string;
  requires_delivery_date: boolean;
  requires_built_in_date: boolean;
}

export interface CreateProperty {
  property_type: number;
  bedrooms: number;
  bathrooms: number;
  area: number;
  floor: number;
  view: number;
  city: number;
  country: number;
  amenities: number[];
  photo_data: Photo[];
  project_name: string;
  status: number;
  availability: number;
  delivery_date?: string;
  built_in?: string;
  description: string;
  price: string;
  currency: string;
  latitude: number;
  longitude: number;
}

export interface ListedProperty {
  id: number;
  property_type: number;
  project_name: string;
  description: string;
  price: string;
  currency: string,
  bedrooms: number;
  bathrooms: number;
  area: number;
  floor: number;
  view: number;
  city: number;
  country: number;
  amenities: number[];
  status: number;
  availability: number;
  delivery_date?: string;
  built_in?: string;
  photos: Photo[];
  sold_to: number | null;
  unverified_sold: boolean;
  sold_date: string | null;
  latitude: number;
  longitude: number;
}

export interface Photo {
  id: number;
  image_url: string; // Updated to use image_url
  rank: number;
}

export interface PublicProperty {
  id: number;
  property_type: number;
  project_name: string;
  description: string;
  price: string;
  currency: string,
  bedrooms: number;
  bathrooms: number;
  area: number;
  floor: number;
  view: number;
  city: number;
  country: number;
  amenities: number[];
  status: number;
  availability: number;
  delivery_date?: string;
  built_in?: string;
  photos: Photo[];
}

export interface PropertyDetail {
  id: number;
  property_type: number;
  project_name: string;
  description: string;
  price: string;
  currency: string,
  bedrooms: number;
  bathrooms: number;
  area: number;
  floor: number;
  view: number;
  city: number;
  country: number;
  amenities: number[];
  status: number;
  availability: number;
  delivery_date: string | null;
  built_in: string | null;
  photos: Photo[];
  sold_to: number | null;
  unverified_sold: boolean;
  sold_date: string | null;
  agent_id: number;
  rank: number;
  latitude: number;
  longitude: number;
}

export interface Filters {
  country: number;
  city: number;
  min_price?: number;
  max_price?: number;
  min_bedrooms?: number;
  min_bathrooms?: number;
  home_types?: number[];  // Multiple allowed
  home_views?: number[];  // Multiple allowed
  availability?: number;
}

export interface AgentProfile {
  preferred_name: string;
  photo: string;
  years_on_platform: number;
  months_on_platform: number;
  spoken_language: string;
  about: string;
}

export interface MessageRequest {
  full_name: string;
  contact_info: string;
  property: number;
  agent: number;
  message: string;
}

// Fetch list of amenities
export const getAmenities = async (): Promise<Amenity[]> => {
  const response = await api.get('/properties/amenities/');
  return response.data;
};

// Fetch list of cities
export const getCities = async (): Promise<City[]> => {
  const response = await api.get('/properties/cities/');
  return response.data;
};

// Fetch list of countries
export const getCountries = async (): Promise<Country[]> => {
  const response = await api.get('/properties/countries/');
  return response.data;
};

// Fetch list of home types
export const getHomeTypes = async (): Promise<HomeType[]> => {
  const response = await api.get('/properties/hometypes/');
  return response.data;
};

// Fetch list of home views
export const getHomeViews = async (): Promise<HomeView[]> => {
  const response = await api.get('/properties/homeviews/');
  return response.data;
};

// Fetch property statuses
export const getPropertyStatuses = async (): Promise<PropertyStatus[]> => {
  const response = await api.get('/properties/property-status/');
  return response.data;
};

// Submit a new property
export const submitProperty = async (propertyData: CreateProperty): Promise<CreateProperty> => {
  const token = localStorage.getItem('access_token');

  const response = await api.post('/properties/agent-properties/', propertyData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

// Fetch the list of properties listed by the agent
export const getAgentProperties = async (): Promise<ListedProperty[]> => {
  const token = localStorage.getItem('access_token');

  const response = await api.get('/properties/agent-properties/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const deleteAgentProperties = async (propertyId: number) => {
  const token = localStorage.getItem('access_token');

  const response = await api.delete(`/properties/agent-properties/${propertyId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Update a property listed by the agent
export const updateAgentProperty = async (id: number, data: CreateProperty): Promise<ListedProperty> => {
  const token = localStorage.getItem('access_token');

  const response = await api.patch(`/properties/agent-properties/${id}/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Change the availability status of a property
export const changePropertyAvailability = async (
    id: number,
    availability: number,
    saleDetails?: { sold_to?: string; sold_date?: string; unverified_sold?: boolean }
  ): Promise<ListedProperty> => {
    const token = localStorage.getItem('access_token');
    const data = { availability, ...saleDetails };
  
    const response = await api.patch(`/properties/agent-properties/${id}/update-availability/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };


export const getPublicProperties = async (filters: any): Promise<PropertyDetail[]> => {
  const params = new URLSearchParams(filters);
  const response = await api.get(`/properties/results/?${params.toString()}`);
  return response.data;
};

export const getPropertyById = async (id: number): Promise<PropertyDetail> => {
  const response = await api.get(`/properties/${id}/`);
  return response.data;
};

export const getAgentPublicProfile = async (agentId: number): Promise<AgentProfile> => {
  const response = await api.get(`/agents/public-profile/${agentId}/`);
  return response.data;
};

export const sendMessageToAgent = async (messageData: MessageRequest): Promise<MessageRequest> => {
  const response = await api.post(`/messaging/send/`, messageData);
  return response.data;
};

export const getRankedProperties = async (): Promise<PropertyDetail[]> => {
  const response = await api.get('/properties/ranked-properties/');
  return response.data;
};

export const uploadPhotos = async (
  files: File[],
  onProgress?: (progress: number) => void
): Promise<string[]> => {
  const token = localStorage.getItem('access_token');

  const totalBytes = files.reduce((acc, file) => acc + file.size, 0);
  let bytesUploaded = 0;
  const bytesUploadedPerFile: { [key: string]: number } = {};

  const uploadedUrls: string[] = [];

  await Promise.all(
    files.map(async (file) => {
      // Generate unique blob name
      const fileExtension = file.name.split('.').pop();
      const uniqueFilename = `property_photos/${uuidv4()}.${fileExtension}`;

      // Call backend API to get SAS URL
      const response = await api.get('/properties/generate-sas-token/', {
        params: {
          blob_name: uniqueFilename,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const sasUrl = response.data.sas_url;

      // Use BlockBlobClient to upload the file
      const blockBlobClient = new BlockBlobClient(sasUrl);

      // Upload the file using uploadBrowserData with progress callback
      await blockBlobClient.uploadBrowserData(file, {
        blobHTTPHeaders: { blobContentType: file.type },
        onProgress: (progressEvent) => {
          // Update the bytes uploaded for this file
          bytesUploadedPerFile[file.name] = progressEvent.loadedBytes;
          // Calculate the total bytes uploaded across all files
          bytesUploaded = Object.values(bytesUploadedPerFile).reduce((a, b) => a + b, 0);
          // Calculate the overall progress percentage
          const progressPercent = Math.round((bytesUploaded / totalBytes) * 100);
          if (onProgress) {
            onProgress(progressPercent);
          }
        },
      });

      // The blob URL can be constructed
      const blobUrl = sasUrl.split('?')[0]; // Remove SAS token from URL
      uploadedUrls.push(blobUrl);
    })
  );

  return uploadedUrls;
};
