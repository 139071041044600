import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/user/HomePage';
import AgentDashboardPage from './pages/agent/AgentDashboardPage';
import SignupPage from './pages/auth/SignUpPage';
import LoginPage from './pages/auth/LoginPage';
import LogoutButton from './pages/auth/LogoutButton';
import AgentLoginPage from './pages/auth/AgentLoginPage';
import AgentLogoutButton from './pages/auth/AgentLogoutButton';
import AgentSignupPage from './pages/auth/AgentSignupPage';
import AuthHOC from './components/common/AuthHOC'; // Adjust this path based on your structure
import PropertyResultsPage from './pages/user/PropertyResultsPage';
import PropertyDetailsPage from './pages/user/PropertyDetailsPage';
import AgentListPage from './pages/agent/AgentListPage';
import AgentProfileModal from './pages/agent/AgentProfileModal';
import ListedPropertiesTab from './pages/agent/ListedPropertiesTab';
import SoldPropertiesTab from './pages/agent/SoldPropertiesTab';
import AddPropertyTab from './pages/agent/AddPropertyTab';
import AgentMessagesPage from './pages/agent/AgentMessagesPage';
import AgentProfilePage from './pages/user/AgentProfilePage';
import PrivacyPolicyPage from './pages/auth/PrivacyPolicyPage';
import GoogleLoginCallback from './components/common/GoogleLoginCallback';
import GoogleLoginInitiate from './components/common/GoogleLoginInitiate';
import BlogListPage from './pages/user/BlogListPage';
import BlogPostPage from './pages/user/BlogPostPage';
import AuthSelectionPage from './pages/auth/AuthSelectionPage';

const App: React.FC = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/auth" element={<AuthSelectionPage />} />
        <Route path="/agents" element={<AgentListPage />} />
        <Route path="/property-results" element={<PropertyResultsPage />} />
        <Route path="/property-details/:id" element={<PropertyDetailsPage />} />
        <Route path="/agent-profile/:agentId" element={<AgentProfilePage />} />
        {/* Wrapping HOC inside JSX */}
        <Route path="/agent-dashboard/*" element={<AuthHOC WrappedComponent={AgentDashboardPage} role="agent" />} />
        <Route path="/agent-login" element={<AgentLoginPage />} />
        <Route path="/agent-logout" element={<AgentLogoutButton />} />
        <Route path="/agent-signup" element={<AgentSignupPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/logout" element={<LogoutButton />} />
        <Route path="/agent/profile" element={<AgentProfileModal />} />
        <Route path="/agent/add-property" element={<AddPropertyTab />} />
        <Route path="/agent/sold-properties" element={<SoldPropertiesTab />} />
        <Route path="/agent/listed-properties" element={<ListedPropertiesTab />} />
        <Route path="/agent/messages" element={<AgentMessagesPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        {/* Route to initiate Google login */}
        <Route path="/login/google" element={<GoogleLoginInitiate />} />
        {/* Route to handle Google callback */}
        <Route path="/login/google/callback" element={<GoogleLoginCallback />} />
        <Route path="/blogs" element={<BlogListPage />} />
        <Route path="/blogs/:slug" element={<BlogPostPage />} />
      </Routes>
    </div>
  );
};

export default App;
