import styled from 'styled-components';

// Wrapper for the dropdown
export const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 1rem;
  max-width: 250px; /* Adjust width of the dropdown */

  label {
    display: block;
    margin-bottom: 1rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text};
  }
`;

// Styled button for the dropdown
export const DropdownButton = styled.button<{ isOpen: boolean }>`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  padding: 0.75rem 1.25rem;
  width: 100%;
  text-align: left;
  font-size: 1.2rem; /* Increased font size */
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  ${({ isOpen, theme }) =>
    isOpen &&
    `
    border-color: ${theme.colors.primary};
  `}
`;

// Dropdown menu (hidden when not open)
export const DropdownMenu = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

// Styled dropdown item with an icon
export const DropdownItem = styled.li`
  padding: 0.75rem 1.25rem;
  cursor: pointer;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  align-items: center; /* Align icon and text */
  gap: 0.75rem; /* Add space between icon and text */
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: black;
    color: white;
  }
`;

// Flex container to align dropdowns in one line
export const DropdownFlexContainer = styled.div`
  display: flex;
  gap: 1rem; /* Add some space between the dropdowns */
  justify-content: center; /* Center the dropdowns */
  align-items: center; /* Vertically center the dropdowns */
  margin-bottom: 2rem; /* Add some margin at the bottom */
  margin-top: 15rem;
`;
