import React, { useEffect, useState } from 'react';
import { AgentPersonalInfo, getPersonalInfo, updatePersonalInfo } from '../../services/agentService';
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  CloseButton,
  InfoRow,
  InfoLabel,
  InfoValue,
  SaveButton,
  CancelButton,
  EditButton,
} from '../../styles/PersonalInfoModalStyles'; // Import styles
import { createPortal } from 'react-dom';

interface PersonalInfoModalProps {
  onClose: () => void; // Callback for closing the modal
}

const PersonalInfoModal: React.FC<PersonalInfoModalProps> = ({ onClose }) => {
  const [personalInfo, setPersonalInfo] = useState<AgentPersonalInfo | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPersonalInfo = async () => {
      try {
        const data = await getPersonalInfo();
        setPersonalInfo(data);
      } catch (err) {
        console.error('Failed to load personal information:', err);
        setError('Failed to load personal information.');
      } finally {
        setLoading(false);
      }
    };

    fetchPersonalInfo();
  }, []);

  const handleSave = async () => {
    if (personalInfo) {
      try {
        await updatePersonalInfo(personalInfo);
        setEditMode(false);
      } catch (err) {
        console.error('Failed to update personal information:', err);
        setError('Failed to update personal information.');
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPersonalInfo((prevInfo) => (prevInfo ? { ...prevInfo, [name]: value } : null));
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  // Render modal using Portal
  return createPortal(
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <h2>Personal Information</h2>
          <CloseButton onClick={onClose}>×</CloseButton> {/* Close button */}
        </ModalHeader>

        <div className="modal-body">
          {/* Form fields for Personal Info */}
          <InfoRow>
            <InfoLabel>Legal Name:</InfoLabel>
            <InfoValue>
              <input
                type="text"
                name="legal_name"
                value={personalInfo?.legal_name || ''}
                onChange={handleChange}
                disabled={!editMode}
              />
            </InfoValue>
          </InfoRow>

          <InfoRow>
            <InfoLabel>Preferred Name:</InfoLabel>
            <InfoValue>
              <input
                type="text"
                name="preferred_name"
                value={personalInfo?.preferred_name || ''}
                onChange={handleChange}
                disabled={!editMode}
              />
            </InfoValue>
          </InfoRow>

          <InfoRow>
            <InfoLabel>Email:</InfoLabel>
            <InfoValue>
              <input
                type="email"
                name="email"
                value={personalInfo?.email || ''}
                onChange={handleChange}
                disabled={!editMode}
              />
            </InfoValue>
          </InfoRow>

          <InfoRow>
            <InfoLabel>Phone Number:</InfoLabel>
            <InfoValue>
              <input
                type="text"
                name="phone_number"
                value={personalInfo?.phone_number || ''}
                onChange={handleChange}
                disabled={!editMode}
              />
            </InfoValue>
          </InfoRow>

          <InfoRow>
            <InfoLabel>Company Name:</InfoLabel>
            <InfoValue>
              <input
                type="text"
                name="company_name"
                value={personalInfo?.company_name || ''}
                disabled
              />
            </InfoValue>
          </InfoRow>

          <InfoRow>
            <InfoLabel>Company Address:</InfoLabel>
            <InfoValue>
              <input
                type="text"
                name="company_address"
                value={personalInfo?.company_address || ''}
                disabled
              />
            </InfoValue>
          </InfoRow>
        </div>

        {/* Modal Footer with Save and Cancel buttons */}
        <ModalFooter>
          {editMode ? (
            <>
              <SaveButton onClick={handleSave}>Save</SaveButton>
              <CancelButton onClick={() => setEditMode(false)}>Cancel</CancelButton>
            </>
          ) : (
            <EditButton onClick={() => setEditMode(true)}>Edit</EditButton>
          )}
        </ModalFooter>
      </ModalContent>
    </ModalOverlay>,
    document.body
  );
};

export default PersonalInfoModal;
