import styled from 'styled-components';
import { Link } from 'react-router-dom';  // For LinkStyled component

// Centralized button style
export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: 12px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

// Centralized input style
export const Input = styled.input`
  padding: 12px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  width: 100%;
  margin-bottom: 15px;
  font-size: 1rem;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
  }
`;

// Centralized form style
export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

// Centralized link style
export const LinkStyled = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  margin: 0 10px;
  
  &:hover {
    text-decoration: underline;
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.p2};
  margin: ${({ theme }) => theme.spacing.m1} 0;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  background-color: #fff;
  appearance: none;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text};

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;
