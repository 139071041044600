import React from 'react';
import { useNavigate } from 'react-router-dom';
import UserTopBar from '../../components/common/UserTopBar';
import { AuthContainer, Button, HomeWallPaper } from '../../styles/AuthSelectionPageStyles';


const AuthSelectionPage: React.FC = () => {
  const navigate = useNavigate();

  const handleAgentLogin = () => navigate('/agent-login');
  const handleUserLogin = () => navigate('/login');

  return (
    <>
    <UserTopBar />

    <HomeWallPaper>
      <img src={"https://timeaz.blob.core.windows.net/media/traf-sL7w1eUfHJQ-unsplash copy.jpg"} alt="HomePageImage" />
    </HomeWallPaper>

    <AuthContainer>
        <div className="button-group">
            <Button onClick={handleAgentLogin}><h3>Login as Agent</h3></Button>
            <Button onClick={handleUserLogin}><h3>Login as User</h3></Button>
        </div>
    </AuthContainer>
    </>
  );
};

export default AuthSelectionPage;
